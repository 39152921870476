@charset "UTF-8";
/**************************************
 CSS Index  
****************************

/--- Default Styles -----/

01.Variables
02.Mixins
03.Reset
04.Typography
05.Extend
06.Animations
07.Shortcode
08.Spacing
09.Common
10.Forms
11.Sal
12.Text Animation
13.Themebg

/--- Header Styles -----/

01.Headerdefault
02.Nav
03.Header Top
04.HeaderTopBar
05.Mobilemenu

/--- Elements -----/

01.Breadcrumb
02.Sectiontitle
03.Button
04.Service
05.Card
06.Counter
07.Progressbar
08.Accordion
09.Social Share
10.Team
11.Portfolio
12.Testimonial
13.Slickslide
14.Timeline
15.Call to Action
16.Tab
17.Pricing
18.Split
19.Vedio
20.Gallery
21.Contact
22.Brand
23.404
24.Advance Tab
25.Advancepricing
26.Contact Form
27.Swicher
28.Backto Top
29.About
30.Newsletter

/--- Blog -----/
01.Blog
02.Sidebar
03.Blog Details

/--- Footer -----/
01.Footer
02.Copyright

/--- Template -----/
01.Banner
02.Portfoliodetails
03.Splash
04.Lightmode

***************************************/

/**************************************
    Default Styles
***************************************/

/*-- Variables --*/

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap");

:root {
    --color-primary: #059DFF;
    --color-primary-rgba: rgb(5, 157, 255);
    --color-primary-rgba-2: rgba(5, 157, 255, 0.15);
    --color-primary-alt: #059DFF;
    --color-primary-light: #059DFF;
    --color-primary-lightest: #059DFF;
    --color-secondary: #FB5343;
    --color-tertiary: #6549D5;
    --color-tertiary-alt: #6549D5;
    --color-pink: #E33FA1;
    --color-light-green: #36C6F0;
    --color-primary-darker: #011827;
    --color-tertiary-darker: #150b40;
    --color-secondary-darker: #190301;
    --color-light-green-darker: #001f27;
    --color-pink-darker: #2f011d;
    --color-heading: #ffffff;
    --color-body: #acacac;
    --color-dark: #060606;
    --color-darker: rgba(6, 6, 6, 0.7);
    --color-darkest: rgba(0, 0, 0, 0.3);
    --color-black: #000000;
    --color-black-off: rgba(0, 0, 0, 0.2);
    --color-blacker: rgba(15, 15, 17, 0);
    --color-blackest: #0f0f11;
    --color-border: hsla(0, 0%, 100%, 0.1);
    --color-lessdark: #1e1e1e;
    --color-gray: #65676B;
    --color-midgray: #878787;
    --color-light: #E4E6EA;
    --color-lighter: #CED0D4;
    --color-lightest: #F0F2F5;
    --color-white: #ffffff;
    --color-white-off: rgba(255, 255, 255, 0.05);
    --color-success: #3EB75E;
    --color-danger: #FF0003;
    --color-warning: #FF8F3C;
    --color-info: #1BA2DB;
    --color-facebook: #3B5997;
    --color-twitter: #1BA1F2;
    --color-youtube: #ED4141;
    --color-linkedin: #0077B5;
    --color-pinterest: #E60022;
    --color-instagram: #C231A1;
    --color-vimeo: #00ADEF;
    --color-twitch: #6441A3;
    --color-discord: #7289da;
    --color-extra01: #666666;
    --color-extra02: #606770;
    --color-extra03: #FBFBFD;
    --color-extra04: #1A1A1A;
    --color-extra05: #242424;
    --color-extra06: #111424;
    --border-width: 2px;
    --radius-small: 6px;
    --radius: 10px;
    --radius-big: 16px;
    --p-light: 300;
    --p-regular: 400;
    --p-medium: 500;
    --p-semi-bold: 600;
    --p-bold: 700;
    --p-extra-bold: 800;
    --p-black: 900;
    --shadow-primary: 0 0 20px 5px rgba(251, 83, 67, 0.2);
    --shadow-light: 1px 1px 6px rgba(0, 0, 0, 0.25);
    --shadow-lighter: 4px 4px 20px 20px rgba(0, 0, 0, 0.01);
    --transition: 0.3s;
    --transition-transform: transform .65s cubic-bezier(.23, 1, .32, 1);
    --font-primary: "Inter", sans-serif;
    --secondary-font: "Inter", sans-serif;
    --font-awesome: "Font Awesome 5 Pro";
    --font-size-b1: 18px;
    --font-size-b2: 16px;
    --font-size-b3: 14px;
    --font-size-b4: 12px;
    --line-height-b1: 1.67;
    --line-height-b2: 1.7;
    --line-height-b3: 1.6;
    --line-height-b4: 1.3;
    --h1: 56px;
    --h2: 44px;
    --h3: 36px;
    --h4: 24px;
    --h5: 18px;
    --h6: 16px;
}

/**
 * Reset Styels
 */

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
nav,
section,
summary {
    display: block;
}

audio,
canvas,
video {
    display: inline-block;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

[hidden] {
    display: none;
}

a {
    color: var(--color-heading);
    text-decoration: none;
    outline: none;
}

a:hover,
a:focus,
a:active {
    text-decoration: none;
    outline: none;
    color: var(--color-primary);
}

a:focus {
    outline: none;
}

address {
    margin: 0 0 24px;
}

abbr[title] {
    border-bottom: 1px dotted;
}

b,
strong {
    font-weight: bold;
}

mark {
    background: var(--color-primary);
    color: #ffffff;
}

code,
kbd,
pre,
samp {
    font-size: var(--font-size-b3);
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
    color: var(--color-primary);
}

kbd,
ins {
    color: #ffffff;
}

pre,
pre.wp-block-code {
    font-family: "Courier 10 Pitch", Courier, monospace !important;
    font-size: 15px !important;
    margin: 40px 0 !important;
    overflow: auto;
    padding: 20px !important;
    white-space: pre !important;
    white-space: pre-wrap !important;
    word-wrap: break-word !important;
    color: var(--color-body) !important;
    background: var(--color-light);
    border-radius: 4px;
}

small {
    font-size: smaller;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

dl {
    margin-top: 0;
    margin-bottom: 10px;
}

dd {
    margin: 0 15px 15px;
}

dt {
    font-weight: bold;
    color: var(--color-heading);
}

menu,
ol,
ul {
    margin: 16px 0;
    padding: 0 0 0 40px;
}

nav ul,
nav ol {
    list-style: none;
    list-style-image: none;
}

li>ul,
li>ol {
    margin: 0;
}

ol ul {
    margin-bottom: 0;
}

img {
    -ms-interpolation-mode: bicubic;
    border: 0;
    vertical-align: middle;
    max-width: 100%;
    height: auto;
}

svg:not(:root) {
    overflow: hidden;
}

figure {
    margin: 0;
}

form {
    margin: 0;
}

fieldset {
    border: 1px solid var(--color-border);
    margin: 0 2px;
    min-width: inherit;
    padding: 0.35em 0.625em 0.75em;
}

legend {
    border: 0;
    padding: 0;
    white-space: normal;
}

button,
input,
select,
textarea {
    font-size: 100%;
    margin: 0;
    max-width: 100%;
    vertical-align: baseline;
}

button,
input {
    line-height: normal;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;
    cursor: pointer;
}

button[disabled],
input[disabled] {
    cursor: default;
}

input[type=checkbox],
input[type=radio] {
    padding: 0;
}

input[type=search] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
    appearance: textfield;
    padding-right: 2px;
    width: 270px;
}

input[type=search]::-webkit-search-decoration {
    -webkit-appearance: none;
    appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

textarea {
    overflow: auto;
    vertical-align: top;
}

caption,
th,
td {
    font-weight: normal;
}

table thead th {
    font-weight: 700;
}

td,
.wp-block-calendar tfoot td {
    border: 1px solid var(--color-border);
    padding: 7px 10px;
}

del {
    color: #333;
}

ins {
    background: rgba(56, 88, 246, 0.6);
    text-decoration: none;
    padding: 0 5px;
}

hr {
    background-size: 4px 4px;
    border: 0;
    height: 1px;
    margin: 0 0 24px;
    opacity: 0.6;
}

table a,
table a:link,
table a:visited {
    text-decoration: underline;
}

dt {
    font-weight: bold;
    margin-bottom: 10px;
}

dd {
    margin: 0 15px 15px;
}

caption {
    caption-side: top;
}

kbd {
    background: var(--heading-color);
}

dfn,
cite,
em {
    font-style: italic;
}

/* BlockQuote  */

blockquote,
q {
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

blockquote {
    font-size: var(--font-size-b1);
    font-style: italic;
    font-weight: var(--p-light);
    margin: 24px 40px;
}

blockquote blockquote {
    margin-right: 0;
}

blockquote cite,
blockquote small {
    font-size: var(--font-size-b3);
    font-weight: normal;
}

blockquote strong,
blockquote b {
    font-weight: 700;
}

/**
 * Typography
 */

* {
    box-sizing: border-box;
}

html {
    overflow: hidden;
    overflow-y: auto;
    margin: 0;
    padding: 0;
    font-size: 10px;
}

body {
    overflow: hidden;
    font-size: var(--font-size-b2);
    line-height: var(--line-height-b2);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: var(--font-primary);
    color: var(--color-body);
    font-weight: var(--p-regular);
    background-color: var(--color-dark);
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
address,
p,
pre,
blockquote,
menu,
ol,
ul,
table,
hr {
    margin: 0;
    margin-bottom: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    word-break: break-word;
    font-family: var(--secondary-font);
    line-height: 1.4074;
    color: var(--color-heading);
}

h1,
.h1 {
    font-size: var(--h1);
    line-height: 1.19;
}

h2,
.h2 {
    font-size: var(--h2);
    line-height: 1.23;
}

h3,
.h3 {
    font-size: var(--h3);
    line-height: 1.4;
}

h4,
.h4 {
    font-size: var(--h4);
    line-height: 1.25;
}

h5,
.h5 {
    font-size: var(--h5);
    line-height: 1.24;
}

h6,
.h6 {
    font-size: var(--h6);
    line-height: 1.25;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
.h1 a,
.h2 a,
.h3 a,
.h4 a,
.h5 a,
.h6 a {
    color: inherit;
    transition: 0.5s;
}

a {
    color: var(--color-body);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

    h1,
    .h1 {
        font-size: 38px;
    }

    h2,
    .h2 {
        font-size: 32px;
    }

    h3,
    .h3 {
        font-size: 28px;
    }

    h4,
    .h4 {
        font-size: 24px;
    }

    h5,
    .h5 {
        font-size: 18px;
    }
}

@media only screen and (max-width: 767px) {

    h1,
    .h1 {
        font-size: 34px;
    }

    h2,
    .h2 {
        font-size: 28px;
    }

    h3,
    .h3 {
        font-size: 24px;
    }

    h4,
    .h4 {
        font-size: 20px;
    }

    h5,
    .h5 {
        font-size: 16px;
    }
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
    font-weight: var(--p-bold);
}

h4,
.h4,
h5,
.h5 {
    font-weight: var(--p-bold);
}

h6,
.h6 {
    font-weight: var(--p-medium);
}

p {
    font-size: var(--font-size-b2);
    line-height: var(--line-height-b2);
    font-weight: var(--p-regular);
    color: var(--color-body);
    margin: 0 0 30px;
}

@media only screen and (max-width: 767px) {
    p {
        margin: 0 0 20px;
        font-size: var(--font-size-b2);
        line-height: var(--line-height-b2);
    }
}

p.has-large-font-size {
    line-height: 1.5;
    font-size: 36px;
}

p.has-medium-font-size {
    font-size: 24px;
    line-height: 36px;
}

p.has-small-font-size {
    font-size: 13px;
}

p.has-very-light-gray-color {
    color: var(--color-white);
}

p.has-background {
    padding: 20px 30px;
}

p.b1 {
    font-size: var(--font-size-b1);
    line-height: var(--line-height-b1);
}

p.b2 {
    font-size: var(--font-size-b2);
    line-height: var(--line-height-b2);
}

p.b3 {
    font-size: var(--font-size-b3);
    line-height: var(--line-height-b3);
}

p.b4 {
    font-size: var(--font-size-b4);
    line-height: var(--line-height-b4);
}

.b1 {
    font-size: var(--font-size-b1);
    line-height: var(--line-height-b1);
}

.b2 {
    font-size: var(--font-size-b2);
    line-height: var(--line-height-b2);
}

.b3 {
    font-size: var(--font-size-b3);
    line-height: var(--line-height-b3);
}

.b4 {
    font-size: var(--font-size-b4);
    line-height: var(--line-height-b4);
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0 0 40px;
    width: 100%;
}

table a,
table a:link,
table a:visited {
    text-decoration: none;
}

cite,
.wp-block-pullquote cite,
.wp-block-pullquote.is-style-solid-color blockquote cite,
.wp-block-quote cite {
    color: var(--color-heading);
}

var {
    font-family: "Courier 10 Pitch", Courier, monospace;
}

/*---------------------------
	List Style 
---------------------------*/

ul,
ol {
    padding-left: 18px;
}

ul {
    list-style: square;
    margin-bottom: 30px;
    padding-left: 20px;
}

ul.liststyle.bullet li {
    font-size: 18px;
    line-height: 30px;
    color: var(--color-body);
    position: relative;
    padding-left: 30px;
}

@media only screen and (max-width: 767px) {
    ul.liststyle.bullet li {
        padding-left: 19px;
    }
}

ul.liststyle.bullet li::before {
    position: absolute;
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background: var(--color-body);
    left: 0;
    top: 10px;
}

ul.liststyle.bullet li+li {
    margin-top: 8px;
}

ul li {
    font-size: var(--font-size-b1);
    line-height: var(--line-height-b1);
    margin-top: 10px;
    margin-bottom: 10px;
    color: var(--color-body);
    font-weight: 400;
}

ul li a {
    text-decoration: none;
    color: var(--color-body);
}

ul li a:hover {
    color: var(--color-primary);
}

ul ul {
    margin-bottom: 0;
}

ol {
    margin-bottom: 30px;
}

ol li {
    font-size: var(--font-size-b1);
    line-height: var(--line-height-b1);
    color: var(--color-gray);
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 400;
}

ol li a {
    color: var(--heading-color);
    text-decoration: none;
}

ol li a:hover {
    color: var(--color-primary);
}

ol ul {
    padding-left: 30px;
}

hr {
    display: block;
    height: 0;
    margin: 15px 0;
    border-top: 1px solid var(--color-border);
    background: transparent;
}

kbd {
    background: var(--color-primary);
}

/*=====================
All Extend Here
=======================*/

.color-primary {
    color: var(--color-primary) !important;
}

.color-primary-light {
    color: var(--color-primary-light) !important;
}

.color-primary-alt {
    color: var(--color-primary-alt) !important;
}

.color-secondary {
    color: var(--color-secondary) !important;
}

.color-tertiary {
    color: var(--color-tertiary) !important;
}

.color-tertiary-alt {
    color: var(--color-tertiary-alt) !important;
}

.color-light-green {
    color: var(--color-light-green) !important;
}

.color-pink {
    color: var(--color-pink) !important;
}

.color-primary-darker {
    color: var(--color-primary-darker) !important;
}

.color-secondary-darker {
    color: var(--color-secondary-darker) !important;
}

.color-light-green-darker {
    color: var(--color-light-green-darker) !important;
}

.color-pink-darker {
    color: var(--color-pink-darker) !important;
}

.color-heading {
    color: var(--color-heading) !important;
}

.color-body {
    color: var(--color-body) !important;
}

.color-dark {
    color: var(--color-dark) !important;
}

.color-darker {
    color: var(--color-darker) !important;
}

.color-darkest {
    color: var(--color-darkest) !important;
}

.color-black {
    color: var(--color-black) !important;
}

.color-blacker {
    color: var(--color-blacker) !important;
}

.color-blackest {
    color: var(--color-blackest) !important;
}

.color-border {
    color: var(--color-border) !important;
}

.color-gray {
    color: var(--color-gray) !important;
}

.color-midgray {
    color: var(--color-midgray) !important;
}

.color-light {
    color: var(--color-light) !important;
}

.color-lighter {
    color: var(--color-lighter) !important;
}

.color-lightest {
    color: var(--color-lightest) !important;
}

.color-white {
    color: var(--color-white) !important;
}

[data-overlay]:before {
    background: var(--color-primary);
}

/* Radius */

.filter-button-default button,
.rainbow-card .inner .thumbnail .card-image img,
.rainbow-card .inner .thumbnail .card-image a,
.radius-small {
    border-radius: var(--radius-small);
}

.radius-small {
    border-radius: var(--radius-small);
}

.radius,
.post-scale {
    border-radius: var(--radius);
}

.radius-big {
    border-radius: var(--radius-big);
}

/* Font Weight */

.w-300 {
    font-weight: 300 !important;
}

.w-400 {
    font-weight: 400 !important;
}

.w-500 {
    font-weight: 500 !important;
}

.w-600 {
    font-weight: 600 !important;
}

.w-700 {
    font-weight: 700 !important;
}

.w-800 {
    font-weight: 800 !important;
}

.w-900 {
    font-weight: 900 !important;
}

/* Shadows */

.shadow-primary {
    box-shadow: var(--shadow-primary);
}

.shadow-light {
    box-shadow: var(--shadow-light);
}

.shadow-lighter {
    box-shadow: var(--shadow-lighter);
}

/*=============== Style Css =============*/

.rainbow-meta-list,
.advance-pricing .pricing-right .plan-offer-list,
.advance-brand,
.breadcrumb-inner ul.page-list {
    padding: 0;
    margin: 0;
    list-style: none;
}

.tagcloud a,
.wp-block-tag-cloud a,
.rbt-single-widget.widget_recent_comments ul li span,
.rbt-single-widget.widget_recent_entries ul li span,
.rbt-single-widget.widget_archive ul li span,
.rbt-single-widget.widget_categories ul li span,
.rbt-single-widget.widget_meta ul li span,
.rbt-single-widget.widget_pages ul li span,
.rbt-single-widget.widget_nav_menu ul li span,
.rbt-single-widget.widget_recent_comments ul li a,
.rbt-single-widget.widget_recent_entries ul li a,
.rbt-single-widget.widget_archive ul li a,
.rbt-single-widget.widget_categories ul li a,
.rbt-single-widget.widget_meta ul li a,
.rbt-single-widget.widget_pages ul li a,
.rbt-single-widget.widget_nav_menu ul li a,
.rainbow-address .inner p a,
.rainbow-slick-dot .slick-dots li button::after,
.filter-button-default button,
.rainbow-card .inner .content .title a,
.rainbow-card .inner .thumbnail .card-image img,
.rainbow-card,
.card-box .inner .content .title a,
.service.service__style--2 .content p,
.service.service__style--2 .content .title a,
.service.service__style--2 .content .title,
.service.gallery-style .content p,
.service.gallery-style .content .title a,
.service.gallery-style .content .title,
.service.service__style--1 .content p,
.service.service__style--1 .content .title a,
.service.service__style--1 .content .title,
.service.service__style--1 .icon,
.mainmenu-nav .mainmenu li.has-droupdown .submenu li a,
.form-group textarea,
.form-group input,
#scrollUp,
ul li a,
ol li a {
    transition: var(--transition);
}

.bg_image,
.bgImagePosition {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

/*----------------------
    Animation Css  
-----------------------*/

.post-scale {
    overflow: hidden;
}

.post-scale img {
    transition: 0.5s;
}

.post-scale:hover img {
    transform: scale(1.1);
}

@keyframes customOne {
    from {
        transform: scale(1);
    }

    50% {
        transform: scale(0.9);
    }

    to {
        transform: scale(1);
    }
}

@keyframes customTwo {
    0% {
        transform: translate(0px, 0px);
    }

    50% {
        transform: translate(100px, 0px);
    }

    100% {
        transform: translate(50px, 50px);
    }
}

.customOne {
    animation: customOne 2s infinite;
}

/* ------------------------
    Custom Animation 01 
----------------------------*/

@-webkit-keyframes headerSlideDown {
    0% {
        margin-top: -100px;
    }

    to {
        margin-top: 0;
    }
}

@keyframes headerSlideDown {
    0% {
        margin-top: -100px;
    }

    to {
        margin-top: 0;
    }
}

/*------------------------
	slidefadeinup
--------------------------*/

@-webkit-keyframes slideFadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 20%, 0);
        transform: translate3d(0, 20%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes slideFadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 20%, 0);
        transform: translate3d(0, 20%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.slideFadeInUp {
    -webkit-animation-name: slideFadeInUp;
    animation-name: slideFadeInUp;
}

/* -----------------------------------
    Custom Animation For All Page
---------------------------------------*/

@-webkit-keyframes moveVertical {
    to {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

@keyframes moveVertical {
    to {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

/*--------------------------------
Scroll Down Button Animation  
----------------------------------*/

@keyframes scrollDown {
    0% {
        opacity: 0;
    }

    10% {
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        transform: translateY(10px);
        opacity: 0;
    }
}

/*==============================
 *  Utilities
=================================*/

/* 
font-family: 'Inter', sans-serif;
*/

@media only screen and (max-width: 767px) {

    .container,
    .container-fluid,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        padding-right: 15px;
        padding-left: 15px;
    }
}

iframe {
    width: 100%;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

.fix {
    overflow: hidden;
}

/*===============================
    Background Color 
=================================*/

.bg-color-primary {
    background: var(--color-primary);
}

.bg-color-secondary {
    background: var(--color-secondary);
}

.bg-color-tertiary {
    background: var(--color-tertiary);
}

.bg-color-grey {
    background: #F0F2F5;
}

.bg-color-white {
    background: #FFFFFF;
}

.bg-color-black {
    background: #1A1A1A;
}

.bg-color-extra03 {
    background: var(--color-extra03);
}

.bg-color-lessdark {
    background: var(--color-lessdark);
}

.bg-color-2 {
    backdrop-filter: saturate(180%) blur(8px);
    background-color: var(--color-darker);
    background-image: linear-gradient(180deg, var(--color-black), transparent);
}

/*===========================
    Background Image 
=============================*/

.bg_image_fixed {
    background-attachment: fixed !important;
}

.bg_image--1 {
    /* background-image: url(../images/bg/bg-image-1.jpg); */
}

.bg_image--2 {
    /* background-image: url(../images/bg/bg-image-2.jpg); */
}

.bg_image--3 {
    /* background-image: url(../images/bg/bg-image-3.jpg); */
}

.bg_image--4 {
    /* background-image: url(../images/bg/bg-image-4.jpg); */
}

.bg_image--5 {
    /* background-image: url(../images/bg/bg-image-5.jpg); */
}

.bg_image--6 {
    /* background-image: url(../images/bg/bg-image-6.jpg); */
}

.bg_image--7 {
    /* background-image: url(../images/bg/bg-image-7.jpg); */
}

.bg_image--8 {
    /* background-image: url(../images/bg/bg-image-8.jpg); */
}

/* .bg_image--9 {
    background-image: url(../images/bg/bg-image-9.jpg);
}

.bg_image--10 {
    background-image: url(../images/bg/bg-image-10.jpg);
}

.bg_image--11 {
    background-image: url(../images/bg/bg-image-11.jpg);
}

.bg_image--12 {
    background-image: url(../images/bg/bg-image-12.jpg);
}

.bg_image--13 {
    background-image: url(../images/bg/bg-image-13.jpg);
}

.bg_image--14 {
    background-image: url(../images/bg/bg-image-14.jpg);
}

.bg_image--15 {
    background-image: url(../images/bg/bg-image-15.jpg);
}

.bg_image--16 {
    background-image: url(../images/bg/bg-image-16.jpg);
}

.bg_image--17 {
    background-image: url(../images/bg/bg-image-17.jpg);
}

.bg_image--18 {
    background-image: url(../images/bg/bg-image-18.jpg);
}

.bg_image--19 {
    background-image: url(../images/bg/bg-image-19.jpg);
}

.bg_image--20 {
    background-image: url(../images/bg/bg-image-20.jpg);
}

.bg_image--21 {
    background-image: url(../images/bg/bg-image-21.jpg);
}

.bg_image--22 {
    background-image: url(../images/bg/bg-image-22.jpg);
} */

.bg_image--23 {
    background-image: url("https://www.verdict.co.uk/wp-content/uploads/2021/04/shutterstock_1583248045.jpg");
    background-size: cover;
    width: 100%;
    width: auto;
    height: fit-content;
}

/* .bg_image--24 {
    background-image: url(../images/bg/bg-image-24.jpg);
}

.bg_image--25 {
    background-image: url(../images/bg/bg-image-25.jpg);
}

.bg_image--26 {
    background-image: url(../images/bg/bg-image-26.jpg);
}

.bg_image--27 {
    background-image: url(../images/bg/bg-image-27.jpg);
}

.bg_image--28 {
    background-image: url(../images/bg/bg-image-28.jpg);
}

.bg_image--29 {
    background-image: url(../images/bg/bg-image-29.jpg);
}

.bg_image--30 {
    background-image: url(../images/bg/bg-image-30.jpg);
} */

/* Height and width */

.fullscreen {
    min-height: 100vh;
    width: 100%;
}

/*===================
Custom Row
======================*/

.row--0 {
    margin-left: 0px;
    margin-right: 0px;
}

.row--0>[class*=col] {
    padding-left: 0px;
    padding-right: 0px;
}

.row--5 {
    margin-left: -5px;
    margin-right: -5px;
}

.row--5>[class*=col] {
    padding-left: 5px;
    padding-right: 5px;
}

.row--10 {
    margin-left: -10px;
    margin-right: -10px;
}

.row--10>[class*=col] {
    padding-left: 10px;
    padding-right: 10px;
}

.row--15 {
    margin-left: -15px;
    margin-right: -15px;
}

.row--15>[class*=col] {
    padding-left: 15px;
    padding-right: 15px;
}

.row--20 {
    margin-left: -20px;
    margin-right: -20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .row--20 {
        margin-left: -15px;
        margin-right: -15px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row--20 {
        margin-left: -15px;
        margin-right: -15px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .row--20 {
        margin-left: -15px;
        margin-right: -15px;
    }
}

@media only screen and (max-width: 767px) {
    .row--20 {
        margin-left: -15px !important;
        margin-right: -15px !important;
    }
}

.row--20>[class*=col],
.row--20>[class*=col-] {
    padding-left: 20px;
    padding-right: 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {

    .row--20>[class*=col],
    .row--20>[class*=col-] {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {

    .row--20>[class*=col],
    .row--20>[class*=col-] {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

    .row--20>[class*=col],
    .row--20>[class*=col-] {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

@media only screen and (max-width: 767px) {

    .row--20>[class*=col],
    .row--20>[class*=col-] {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

.row--25 {
    margin-left: -25px;
    margin-right: -25px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .row--25 {
        margin-left: -15px;
        margin-right: -15px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row--25 {
        margin-left: -15px;
        margin-right: -15px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .row--25 {
        margin-left: -15px;
        margin-right: -15px;
    }
}

@media only screen and (max-width: 767px) {
    .row--25 {
        margin-left: -15px !important;
        margin-right: -15px !important;
    }
}

.row--25>[class*=col],
.row--25>[class*=col-] {
    padding-left: 25px;
    padding-right: 25px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {

    .row--25>[class*=col],
    .row--25>[class*=col-] {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {

    .row--25>[class*=col],
    .row--25>[class*=col-] {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

    .row--25>[class*=col],
    .row--25>[class*=col-] {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

@media only screen and (max-width: 767px) {

    .row--25>[class*=col],
    .row--25>[class*=col-] {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

.row--30 {
    margin-left: -30px;
    margin-right: -30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .row--30 {
        margin-left: -15px;
        margin-right: -15px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row--30 {
        margin-left: -15px;
        margin-right: -15px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .row--30 {
        margin-left: -15px;
        margin-right: -15px;
    }
}

@media only screen and (max-width: 767px) {
    .row--30 {
        margin-left: -15px !important;
        margin-right: -15px !important;
    }
}

.row--30>[class*=col],
.row--30>[class*=col-] {
    padding-left: 30px;
    padding-right: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {

    .row--30>[class*=col],
    .row--30>[class*=col-] {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {

    .row--30>[class*=col],
    .row--30>[class*=col-] {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

    .row--30>[class*=col],
    .row--30>[class*=col-] {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

@media only screen and (max-width: 767px) {

    .row--30>[class*=col],
    .row--30>[class*=col-] {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

.row--45 {
    margin-left: -45px;
    margin-right: -45px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .row--45 {
        margin-left: -15px;
        margin-right: -15px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row--45 {
        margin-left: -15px;
        margin-right: -15px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .row--45 {
        margin-left: -15px;
        margin-right: -15px;
    }
}

@media only screen and (max-width: 767px) {
    .row--45 {
        margin-left: -15px !important;
        margin-right: -15px !important;
    }
}

.row--45>[class*=col],
.row--45>[class*=col-] {
    padding-left: 45px;
    padding-right: 45px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {

    .row--45>[class*=col],
    .row--45>[class*=col-] {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {

    .row--45>[class*=col],
    .row--45>[class*=col-] {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

    .row--45>[class*=col],
    .row--45>[class*=col-] {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

@media only screen and (max-width: 767px) {

    .row--45>[class*=col],
    .row--45>[class*=col-] {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

.row--40 {
    margin-left: -40px;
    margin-right: -40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .row--40 {
        margin-left: -15px;
        margin-right: -15px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row--40 {
        margin-left: -15px;
        margin-right: -15px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .row--40 {
        margin-left: -15px;
        margin-right: -15px;
    }
}

@media only screen and (max-width: 767px) {
    .row--40 {
        margin-left: -15px !important;
        margin-right: -15px !important;
    }
}

.row--40>[class*=col],
.row--40>[class*=col-] {
    padding-left: 40px;
    padding-right: 40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {

    .row--40>[class*=col],
    .row--40>[class*=col-] {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {

    .row--40>[class*=col],
    .row--40>[class*=col-] {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

    .row--40>[class*=col],
    .row--40>[class*=col-] {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

@media only screen and (max-width: 767px) {

    .row--40>[class*=col],
    .row--40>[class*=col-] {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

.row--60 {
    margin-left: -60px;
    margin-right: -60px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .row--60 {
        margin-left: -15px;
        margin-right: -15px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row--60 {
        margin-left: -15px;
        margin-right: -15px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .row--60 {
        margin-left: -15px;
        margin-right: -15px;
    }
}

@media only screen and (max-width: 767px) {
    .row--60 {
        margin-left: -15px !important;
        margin-right: -15px !important;
    }
}

.row--60>[class*=col],
.row--60>[class*=col-] {
    padding-left: 60px;
    padding-right: 60px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {

    .row--60>[class*=col],
    .row--60>[class*=col-] {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {

    .row--60>[class*=col],
    .row--60>[class*=col-] {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

    .row--60>[class*=col],
    .row--60>[class*=col-] {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

@media only screen and (max-width: 767px) {

    .row--60>[class*=col],
    .row--60>[class*=col-] {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

/*===========================
    Input Placeholder
=============================*/

input:-moz-placeholder,
textarea:-moz-placeholder {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

/*=============================
	Overlay styles 
==============================*/

[data-overlay],
[data-black-overlay],
[data-white-overlay] {
    position: relative;
}

[data-overlay]>div,
[data-overlay]>*,
[data-black-overlay]>div,
[data-black-overlay]>*,
[data-white-overlay]>div,
[data-white-overlay]>* {
    position: relative;
    z-index: 2;
}

[data-overlay]:before,
[data-black-overlay]:before,
[data-white-overlay]:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
}

[data-black-overlay]:before {
    background-color: #000000;
}

[data-white-overlay]:before {
    background-color: #ffffff;
}

[data-overlay="1"]:before,
[data-black-overlay="1"]:before,
[data-white-overlay="1"]:before {
    opacity: 0.1;
}

[data-overlay="2"]:before,
[data-black-overlay="2"]:before,
[data-white-overlay="2"]:before {
    opacity: 0.2;
}

[data-overlay="3"]:before,
[data-black-overlay="3"]:before,
[data-white-overlay="3"]:before {
    opacity: 0.3;
}

[data-overlay="4"]:before,
[data-black-overlay="4"]:before,
[data-white-overlay="4"]:before {
    opacity: 0.4;
}

[data-overlay="5"]:before,
[data-black-overlay="5"]:before,
[data-white-overlay="5"]:before {
    opacity: 0.5;
}

[data-overlay="6"]:before,
[data-black-overlay="6"]:before,
[data-white-overlay="6"]:before {
    opacity: 0.6;
}

[data-overlay="7"]:before,
[data-black-overlay="7"]:before,
[data-white-overlay="7"]:before {
    opacity: 0.7;
}

[data-overlay="8"]:before,
[data-black-overlay="8"]:before,
[data-white-overlay="8"]:before {
    opacity: 0.8;
}

[data-overlay="9"]:before,
[data-black-overlay="9"]:before,
[data-white-overlay="9"]:before {
    opacity: 0.9;
}

[data-overlay="10"]:before,
[data-black-overlay="10"]:before,
[data-white-overlay="10"]:before {
    opacity: 1;
}

/*------------------------------
    Scroll Up 
--------------------------------*/

#scrollUp {
    width: 70px;
    height: 80px;
    right: 100px;
    bottom: 60px;
    text-align: center;
    z-index: 9811 !important;
    text-decoration: none;
    background: #fff;
    line-height: 80px;
    color: #757589;
    font-size: 15px;
    font-weight: 400;
    display: inline-block;
    background: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    #scrollUp {
        right: 20px;
        bottom: 40px;
    }
}

@media only screen and (max-width: 767px) {
    #scrollUp {
        right: 20px;
        bottom: 40px;
    }
}

#scrollUp::before {
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    background: rgba(0, 2, 72, 0.1);
    content: "";
    position: absolute;
    z-index: -1;
    transform-style: preserve-3d;
    transform: rotateY(-10deg);
    filter: blur(50px);
}

#scrollUp::after {
    background: #ffffff;
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transform-style: preserve-3d;
    transform: rotateY(-10deg);
}

@media only screen and (max-width: 767px) {
    #scrollUp {
        right: 20px;
        bottom: 30px;
        width: 50px;
        height: 60px;
        line-height: 60px;
    }
}

#scrollUp span.text {
    position: relative;
    display: inline-block;
    margin-top: 7px;
}

@media only screen and (max-width: 767px) {
    #scrollUp span.text {
        margin-top: 3px;
    }
}

#scrollUp span.text::after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 7px 5px;
    border-color: transparent transparent var(--color-primary) transparent;
    position: absolute;
    content: "";
    left: 50%;
    top: 21%;
    transform: translateX(-50%);
}

#scrollUp:hover span.text {
    color: var(--color-primary);
}

/*--------------------
    Contact Form 
----------------------*/

.form-message {
    margin-bottom: 0;
    text-align: center;
}

.form-message.error {
    margin-top: 20px;
    color: #f80707;
}

.form-message.success {
    margin-top: 20px;
    color: #0d8d2d;
}

.slick-gutter-15 {
    margin: 0 -15px;
}

.slick-gutter-15 .slick-slide {
    padding: 0 15px;
}

.rbt-alignwide {
    margin-left: -144px;
    margin-right: -144px;
    max-width: 1600px;
    width: auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .rbt-alignwide {
        margin-left: -110px;
        margin-right: -110px;
        max-width: 1400px;
        width: auto;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .rbt-alignwide {
        margin-left: -50px;
        margin-right: -50px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .rbt-alignwide {
        margin-left: -50px;
        margin-right: -50px;
    }
}

@media only screen and (max-width: 767px) {
    .rbt-alignwide {
        margin-left: 0;
        margin-right: 0;
    }
}

.rbt-inner-alignwide {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
}

@media only screen and (min-width: 1400px) {
    .rbt-inner-alignwide {
        max-width: 1290px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .rbt-inner-alignwide {
        max-width: 985px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .rbt-inner-alignwide {
        max-width: 948px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .rbt-inner-alignwide {
        max-width: 708px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .rbt-inner-alignwide {
        max-width: 708px;
    }
}

@media only screen and (max-width: 767px) {
    .rbt-inner-alignwide {
        max-width: 100%;
        padding-right: 15px;
        padding-left: 15px;
    }
}

.padding-top-left-50 {
    padding-top: 50px;
    padding-left: 50px;
}

/*=========================
    Section Separation 
==========================*/

.mt_dec--30 {
    margin-top: -30px;
}

.mt_dec--10 {
    margin-top: -10px;
}

.mb---120 {
    margin-bottom: -120px;
}

.mb---70 {
    margin-bottom: -70px;
}

.rainbow-section-gap {
    padding: 120px 0 !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .rainbow-section-gap {
        padding: 80px 0 !important;
    }
}

@media only screen and (max-width: 767px) {
    .rainbow-section-gap {
        padding: 60px 0 !important;
    }
}

.rainbow-section-gapBottom {
    padding-bottom: 120px !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .rainbow-section-gapBottom {
        padding-bottom: 80px !important;
    }
}

@media only screen and (max-width: 767px) {
    .rainbow-section-gapBottom {
        padding-bottom: 60px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

    .pb--165,
    .pb--85 {
        padding-bottom: 80px !important;
    }
}

@media only screen and (max-width: 767px) {

    .pb--165,
    .pb--85 {
        padding-bottom: 60px !important;
    }
}

.rainbow-section-gapTop {
    padding-top: 120px !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .rainbow-section-gapTop {
        padding-top: 80px !important;
    }
}

@media only screen and (max-width: 767px) {
    .rainbow-section-gapTop {
        padding-top: 60px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .ptb--120 {
        padding: 80px 0 !important;
    }
}

@media only screen and (max-width: 767px) {
    .ptb--120 {
        padding: 60px 0 !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

    .pb--165,
    .pb--120,
    .pb--85,
    .pb--165 {
        padding-bottom: 80px !important;
    }
}

@media only screen and (max-width: 767px) {

    .pb--165,
    .pb--120,
    .pb--85,
    .pb--165 {
        padding-bottom: 60px !important;
    }
}

@media only screen and (max-width: 767px) {
    .pb--70 {
        padding-bottom: 60px !important;
    }
}

.pl--0 {
    padding-left: 0 !important;
}

.pr--0 {
    padding-right: 0 !important;
}

.pt--0 {
    padding-top: 0 !important;
}

.pb--0 {
    padding-bottom: 0 !important;
}

.mr--0 {
    margin-right: 0 !important;
}

.ml--0 {
    margin-left: 0 !important;
}

.mt--0 {
    margin-top: 0 !important;
}

.mb--0 {
    margin-bottom: 0 !important;
}

.pt--260 {
    padding-top: 260px !important;
}

.pt--250 {
    padding-top: 250px !important;
}

.pb--240 {
    padding-bottom: 240px !important;
}

.pb--220 {
    padding-bottom: 220px !important;
}

.plr--270 {
    padding: 0 270px !important;
}

.plr--300 {
    padding: 0 300px !important;
}

.plr--340 {
    padding: 0 340px !important;
}

.ptb--300 {
    padding: 300px 0 !important;
}

.ptb--340 {
    padding: 340px 0 !important;
}

.ptb--450 {
    padding: 450px 0 !important;
}

.plr_dec--15 {
    margin: 0 -15px !important;
}

.pt--300 {
    padding-top: 300px !important;
}

.ptb--5 {
    padding: 5px 0 !important;
}

.plr--5 {
    padding: 0 5px !important;
}

.pt--5 {
    padding-top: 5px !important;
}

.pb--5 {
    padding-bottom: 5px !important;
}

.pl--5 {
    padding-left: 5px !important;
}

.pr--5 {
    padding-right: 5px !important;
}

.mt--5 {
    margin-top: 5px !important;
}

.mb--5 {
    margin-bottom: 5px !important;
}

.mr--5 {
    margin-right: 5px !important;
}

.ml--5 {
    margin-left: 5px !important;
}

.ptb--10 {
    padding: 10px 0 !important;
}

.plr--10 {
    padding: 0 10px !important;
}

.pt--10 {
    padding-top: 10px !important;
}

.pb--10 {
    padding-bottom: 10px !important;
}

.pl--10 {
    padding-left: 10px !important;
}

.pr--10 {
    padding-right: 10px !important;
}

.mt--10 {
    margin-top: 10px !important;
}

.mb--10 {
    margin-bottom: 10px !important;
}

.mr--10 {
    margin-right: 10px !important;
}

.ml--10 {
    margin-left: 10px !important;
}

.ptb--15 {
    padding: 15px 0 !important;
}

.plr--15 {
    padding: 0 15px !important;
}

.pt--15 {
    padding-top: 15px !important;
}

.pb--15 {
    padding-bottom: 15px !important;
}

.pl--15 {
    padding-left: 15px !important;
}

.pr--15 {
    padding-right: 15px !important;
}

.mt--15 {
    margin-top: 15px !important;
}

.mb--15 {
    margin-bottom: 15px !important;
}

.mr--15 {
    margin-right: 15px !important;
}

.ml--15 {
    margin-left: 15px !important;
}

.ptb--20 {
    padding: 20px 0 !important;
}

.plr--20 {
    padding: 0 20px !important;
}

.pt--20 {
    padding-top: 20px !important;
}

.pb--20 {
    padding-bottom: 20px !important;
}

.pl--20 {
    padding-left: 20px !important;
}

.pr--20 {
    padding-right: 20px !important;
}

.mt--20 {
    margin-top: 20px !important;
}

.mb--20 {
    margin-bottom: 20px !important;
}

.mr--20 {
    margin-right: 20px !important;
}

.ml--20 {
    margin-left: 20px !important;
}

.ptb--25 {
    padding: 25px 0 !important;
}

.plr--25 {
    padding: 0 25px !important;
}

.pt--25 {
    padding-top: 25px !important;
}

.pb--25 {
    padding-bottom: 25px !important;
}

.pl--25 {
    padding-left: 25px !important;
}

.pr--25 {
    padding-right: 25px !important;
}

.mt--25 {
    margin-top: 25px !important;
}

.mb--25 {
    margin-bottom: 25px !important;
}

.mr--25 {
    margin-right: 25px !important;
}

.ml--25 {
    margin-left: 25px !important;
}

.ptb--30 {
    padding: 30px 0 !important;
}

.plr--30 {
    padding: 0 30px !important;
}

.pt--30 {
    padding-top: 30px !important;
}

.pb--30 {
    padding-bottom: 30px !important;
}

.pl--30 {
    padding-left: 30px !important;
}

.pr--30 {
    padding-right: 30px !important;
}

.mt--30 {
    margin-top: 30px !important;
}

.mb--30 {
    margin-bottom: 30px !important;
}

.mr--30 {
    margin-right: 30px !important;
}

.ml--30 {
    margin-left: 30px !important;
}

.ptb--35 {
    padding: 35px 0 !important;
}

.plr--35 {
    padding: 0 35px !important;
}

.pt--35 {
    padding-top: 35px !important;
}

.pb--35 {
    padding-bottom: 35px !important;
}

.pl--35 {
    padding-left: 35px !important;
}

.pr--35 {
    padding-right: 35px !important;
}

.mt--35 {
    margin-top: 35px !important;
}

.mb--35 {
    margin-bottom: 35px !important;
}

.mr--35 {
    margin-right: 35px !important;
}

.ml--35 {
    margin-left: 35px !important;
}

.ptb--40 {
    padding: 40px 0 !important;
}

.plr--40 {
    padding: 0 40px !important;
}

.pt--40 {
    padding-top: 40px !important;
}

.pb--40 {
    padding-bottom: 40px !important;
}

.pl--40 {
    padding-left: 40px !important;
}

.pr--40 {
    padding-right: 40px !important;
}

.mt--40 {
    margin-top: 40px !important;
}

.mb--40 {
    margin-bottom: 40px !important;
}

.mr--40 {
    margin-right: 40px !important;
}

.ml--40 {
    margin-left: 40px !important;
}

.ptb--45 {
    padding: 45px 0 !important;
}

.plr--45 {
    padding: 0 45px !important;
}

.pt--45 {
    padding-top: 45px !important;
}

.pb--45 {
    padding-bottom: 45px !important;
}

.pl--45 {
    padding-left: 45px !important;
}

.pr--45 {
    padding-right: 45px !important;
}

.mt--45 {
    margin-top: 45px !important;
}

.mb--45 {
    margin-bottom: 45px !important;
}

.mr--45 {
    margin-right: 45px !important;
}

.ml--45 {
    margin-left: 45px !important;
}

.ptb--50 {
    padding: 50px 0 !important;
}

.plr--50 {
    padding: 0 50px !important;
}

.pt--50 {
    padding-top: 50px !important;
}

.pb--50 {
    padding-bottom: 50px !important;
}

.pl--50 {
    padding-left: 50px !important;
}

.pr--50 {
    padding-right: 50px !important;
}

.mt--50 {
    margin-top: 50px !important;
}

.mb--50 {
    margin-bottom: 50px !important;
}

.mr--50 {
    margin-right: 50px !important;
}

.ml--50 {
    margin-left: 50px !important;
}

.ptb--55 {
    padding: 55px 0 !important;
}

.plr--55 {
    padding: 0 55px !important;
}

.pt--55 {
    padding-top: 55px !important;
}

.pb--55 {
    padding-bottom: 55px !important;
}

.pl--55 {
    padding-left: 55px !important;
}

.pr--55 {
    padding-right: 55px !important;
}

.mt--55 {
    margin-top: 55px !important;
}

.mb--55 {
    margin-bottom: 55px !important;
}

.mr--55 {
    margin-right: 55px !important;
}

.ml--55 {
    margin-left: 55px !important;
}

.ptb--60 {
    padding: 60px 0 !important;
}

.plr--60 {
    padding: 0 60px !important;
}

.pt--60 {
    padding-top: 60px !important;
}

.pb--60 {
    padding-bottom: 60px !important;
}

.pl--60 {
    padding-left: 60px !important;
}

.pr--60 {
    padding-right: 60px !important;
}

.mt--60 {
    margin-top: 60px !important;
}

.mb--60 {
    margin-bottom: 60px !important;
}

.mr--60 {
    margin-right: 60px !important;
}

.ml--60 {
    margin-left: 60px !important;
}

.ptb--65 {
    padding: 65px 0 !important;
}

.plr--65 {
    padding: 0 65px !important;
}

.pt--65 {
    padding-top: 65px !important;
}

.pb--65 {
    padding-bottom: 65px !important;
}

.pl--65 {
    padding-left: 65px !important;
}

.pr--65 {
    padding-right: 65px !important;
}

.mt--65 {
    margin-top: 65px !important;
}

.mb--65 {
    margin-bottom: 65px !important;
}

.mr--65 {
    margin-right: 65px !important;
}

.ml--65 {
    margin-left: 65px !important;
}

.ptb--70 {
    padding: 70px 0 !important;
}

.plr--70 {
    padding: 0 70px !important;
}

.pt--70 {
    padding-top: 70px !important;
}

.pb--70 {
    padding-bottom: 70px !important;
}

.pl--70 {
    padding-left: 70px !important;
}

.pr--70 {
    padding-right: 70px !important;
}

.mt--70 {
    margin-top: 70px !important;
}

.mb--70 {
    margin-bottom: 70px !important;
}

.mr--70 {
    margin-right: 70px !important;
}

.ml--70 {
    margin-left: 70px !important;
}

.ptb--75 {
    padding: 75px 0 !important;
}

.plr--75 {
    padding: 0 75px !important;
}

.pt--75 {
    padding-top: 75px !important;
}

.pb--75 {
    padding-bottom: 75px !important;
}

.pl--75 {
    padding-left: 75px !important;
}

.pr--75 {
    padding-right: 75px !important;
}

.mt--75 {
    margin-top: 75px !important;
}

.mb--75 {
    margin-bottom: 75px !important;
}

.mr--75 {
    margin-right: 75px !important;
}

.ml--75 {
    margin-left: 75px !important;
}

.ptb--80 {
    padding: 80px 0 !important;
}

.plr--80 {
    padding: 0 80px !important;
}

.pt--80 {
    padding-top: 80px !important;
}

.pb--80 {
    padding-bottom: 80px !important;
}

.pl--80 {
    padding-left: 80px !important;
}

.pr--80 {
    padding-right: 80px !important;
}

.mt--80 {
    margin-top: 80px !important;
}

.mb--80 {
    margin-bottom: 80px !important;
}

.mr--80 {
    margin-right: 80px !important;
}

.ml--80 {
    margin-left: 80px !important;
}

.ptb--85 {
    padding: 85px 0 !important;
}

.plr--85 {
    padding: 0 85px !important;
}

.pt--85 {
    padding-top: 85px !important;
}

.pb--85 {
    padding-bottom: 85px !important;
}

.pl--85 {
    padding-left: 85px !important;
}

.pr--85 {
    padding-right: 85px !important;
}

.mt--85 {
    margin-top: 85px !important;
}

.mb--85 {
    margin-bottom: 85px !important;
}

.mr--85 {
    margin-right: 85px !important;
}

.ml--85 {
    margin-left: 85px !important;
}

.ptb--90 {
    padding: 90px 0 !important;
}

.plr--90 {
    padding: 0 90px !important;
}

.pt--90 {
    padding-top: 90px !important;
}

.pb--90 {
    padding-bottom: 90px !important;
}

.pl--90 {
    padding-left: 90px !important;
}

.pr--90 {
    padding-right: 90px !important;
}

.mt--90 {
    margin-top: 90px !important;
}

.mb--90 {
    margin-bottom: 90px !important;
}

.mr--90 {
    margin-right: 90px !important;
}

.ml--90 {
    margin-left: 90px !important;
}

.ptb--95 {
    padding: 95px 0 !important;
}

.plr--95 {
    padding: 0 95px !important;
}

.pt--95 {
    padding-top: 95px !important;
}

.pb--95 {
    padding-bottom: 95px !important;
}

.pl--95 {
    padding-left: 95px !important;
}

.pr--95 {
    padding-right: 95px !important;
}

.mt--95 {
    margin-top: 95px !important;
}

.mb--95 {
    margin-bottom: 95px !important;
}

.mr--95 {
    margin-right: 95px !important;
}

.ml--95 {
    margin-left: 95px !important;
}

.ptb--100 {
    padding: 100px 0 !important;
}

.plr--100 {
    padding: 0 100px !important;
}

.pt--100 {
    padding-top: 100px !important;
}

.pb--100 {
    padding-bottom: 100px !important;
}

.pl--100 {
    padding-left: 100px !important;
}

.pr--100 {
    padding-right: 100px !important;
}

.mt--100 {
    margin-top: 100px !important;
}

.mb--100 {
    margin-bottom: 100px !important;
}

.mr--100 {
    margin-right: 100px !important;
}

.ml--100 {
    margin-left: 100px !important;
}

.ptb--105 {
    padding: 105px 0 !important;
}

.plr--105 {
    padding: 0 105px !important;
}

.pt--105 {
    padding-top: 105px !important;
}

.pb--105 {
    padding-bottom: 105px !important;
}

.pl--105 {
    padding-left: 105px !important;
}

.pr--105 {
    padding-right: 105px !important;
}

.mt--105 {
    margin-top: 105px !important;
}

.mb--105 {
    margin-bottom: 105px !important;
}

.mr--105 {
    margin-right: 105px !important;
}

.ml--105 {
    margin-left: 105px !important;
}

.ptb--110 {
    padding: 110px 0 !important;
}

.plr--110 {
    padding: 0 110px !important;
}

.pt--110 {
    padding-top: 110px !important;
}

.pb--110 {
    padding-bottom: 110px !important;
}

.pl--110 {
    padding-left: 110px !important;
}

.pr--110 {
    padding-right: 110px !important;
}

.mt--110 {
    margin-top: 110px !important;
}

.mb--110 {
    margin-bottom: 110px !important;
}

.mr--110 {
    margin-right: 110px !important;
}

.ml--110 {
    margin-left: 110px !important;
}

.ptb--115 {
    padding: 115px 0 !important;
}

.plr--115 {
    padding: 0 115px !important;
}

.pt--115 {
    padding-top: 115px !important;
}

.pb--115 {
    padding-bottom: 115px !important;
}

.pl--115 {
    padding-left: 115px !important;
}

.pr--115 {
    padding-right: 115px !important;
}

.mt--115 {
    margin-top: 115px !important;
}

.mb--115 {
    margin-bottom: 115px !important;
}

.mr--115 {
    margin-right: 115px !important;
}

.ml--115 {
    margin-left: 115px !important;
}

.ptb--120 {
    padding: 120px 0 !important;
}

.plr--120 {
    padding: 0 120px !important;
}

.pt--120 {
    padding-top: 120px !important;
}

.pb--120 {
    padding-bottom: 120px !important;
}

.pl--120 {
    padding-left: 120px !important;
}

.pr--120 {
    padding-right: 120px !important;
}

.mt--120 {
    margin-top: 120px !important;
}

.mb--120 {
    margin-bottom: 120px !important;
}

.mr--120 {
    margin-right: 120px !important;
}

.ml--120 {
    margin-left: 120px !important;
}

.ptb--125 {
    padding: 125px 0 !important;
}

.plr--125 {
    padding: 0 125px !important;
}

.pt--125 {
    padding-top: 125px !important;
}

.pb--125 {
    padding-bottom: 125px !important;
}

.pl--125 {
    padding-left: 125px !important;
}

.pr--125 {
    padding-right: 125px !important;
}

.mt--125 {
    margin-top: 125px !important;
}

.mb--125 {
    margin-bottom: 125px !important;
}

.mr--125 {
    margin-right: 125px !important;
}

.ml--125 {
    margin-left: 125px !important;
}

.ptb--130 {
    padding: 130px 0 !important;
}

.plr--130 {
    padding: 0 130px !important;
}

.pt--130 {
    padding-top: 130px !important;
}

.pb--130 {
    padding-bottom: 130px !important;
}

.pl--130 {
    padding-left: 130px !important;
}

.pr--130 {
    padding-right: 130px !important;
}

.mt--130 {
    margin-top: 130px !important;
}

.mb--130 {
    margin-bottom: 130px !important;
}

.mr--130 {
    margin-right: 130px !important;
}

.ml--130 {
    margin-left: 130px !important;
}

.ptb--135 {
    padding: 135px 0 !important;
}

.plr--135 {
    padding: 0 135px !important;
}

.pt--135 {
    padding-top: 135px !important;
}

.pb--135 {
    padding-bottom: 135px !important;
}

.pl--135 {
    padding-left: 135px !important;
}

.pr--135 {
    padding-right: 135px !important;
}

.mt--135 {
    margin-top: 135px !important;
}

.mb--135 {
    margin-bottom: 135px !important;
}

.mr--135 {
    margin-right: 135px !important;
}

.ml--135 {
    margin-left: 135px !important;
}

.ptb--140 {
    padding: 140px 0 !important;
}

.plr--140 {
    padding: 0 140px !important;
}

.pt--140 {
    padding-top: 140px !important;
}

.pb--140 {
    padding-bottom: 140px !important;
}

.pl--140 {
    padding-left: 140px !important;
}

.pr--140 {
    padding-right: 140px !important;
}

.mt--140 {
    margin-top: 140px !important;
}

.mb--140 {
    margin-bottom: 140px !important;
}

.mr--140 {
    margin-right: 140px !important;
}

.ml--140 {
    margin-left: 140px !important;
}

.ptb--145 {
    padding: 145px 0 !important;
}

.plr--145 {
    padding: 0 145px !important;
}

.pt--145 {
    padding-top: 145px !important;
}

.pb--145 {
    padding-bottom: 145px !important;
}

.pl--145 {
    padding-left: 145px !important;
}

.pr--145 {
    padding-right: 145px !important;
}

.mt--145 {
    margin-top: 145px !important;
}

.mb--145 {
    margin-bottom: 145px !important;
}

.mr--145 {
    margin-right: 145px !important;
}

.ml--145 {
    margin-left: 145px !important;
}

.ptb--150 {
    padding: 150px 0 !important;
}

.plr--150 {
    padding: 0 150px !important;
}

.pt--150 {
    padding-top: 150px !important;
}

.pb--150 {
    padding-bottom: 150px !important;
}

.pl--150 {
    padding-left: 150px !important;
}

.pr--150 {
    padding-right: 150px !important;
}

.mt--150 {
    margin-top: 150px !important;
}

.mb--150 {
    margin-bottom: 150px !important;
}

.mr--150 {
    margin-right: 150px !important;
}

.ml--150 {
    margin-left: 150px !important;
}

.ptb--155 {
    padding: 155px 0 !important;
}

.plr--155 {
    padding: 0 155px !important;
}

.pt--155 {
    padding-top: 155px !important;
}

.pb--155 {
    padding-bottom: 155px !important;
}

.pl--155 {
    padding-left: 155px !important;
}

.pr--155 {
    padding-right: 155px !important;
}

.mt--155 {
    margin-top: 155px !important;
}

.mb--155 {
    margin-bottom: 155px !important;
}

.mr--155 {
    margin-right: 155px !important;
}

.ml--155 {
    margin-left: 155px !important;
}

.ptb--160 {
    padding: 160px 0 !important;
}

.plr--160 {
    padding: 0 160px !important;
}

.pt--160 {
    padding-top: 160px !important;
}

.pb--160 {
    padding-bottom: 160px !important;
}

.pl--160 {
    padding-left: 160px !important;
}

.pr--160 {
    padding-right: 160px !important;
}

.mt--160 {
    margin-top: 160px !important;
}

.mb--160 {
    margin-bottom: 160px !important;
}

.mr--160 {
    margin-right: 160px !important;
}

.ml--160 {
    margin-left: 160px !important;
}

.ptb--165 {
    padding: 165px 0 !important;
}

.plr--165 {
    padding: 0 165px !important;
}

.pt--165 {
    padding-top: 165px !important;
}

.pb--165 {
    padding-bottom: 165px !important;
}

.pl--165 {
    padding-left: 165px !important;
}

.pr--165 {
    padding-right: 165px !important;
}

.mt--165 {
    margin-top: 165px !important;
}

.mb--165 {
    margin-bottom: 165px !important;
}

.mr--165 {
    margin-right: 165px !important;
}

.ml--165 {
    margin-left: 165px !important;
}

.ptb--170 {
    padding: 170px 0 !important;
}

.plr--170 {
    padding: 0 170px !important;
}

.pt--170 {
    padding-top: 170px !important;
}

.pb--170 {
    padding-bottom: 170px !important;
}

.pl--170 {
    padding-left: 170px !important;
}

.pr--170 {
    padding-right: 170px !important;
}

.mt--170 {
    margin-top: 170px !important;
}

.mb--170 {
    margin-bottom: 170px !important;
}

.mr--170 {
    margin-right: 170px !important;
}

.ml--170 {
    margin-left: 170px !important;
}

.ptb--175 {
    padding: 175px 0 !important;
}

.plr--175 {
    padding: 0 175px !important;
}

.pt--175 {
    padding-top: 175px !important;
}

.pb--175 {
    padding-bottom: 175px !important;
}

.pl--175 {
    padding-left: 175px !important;
}

.pr--175 {
    padding-right: 175px !important;
}

.mt--175 {
    margin-top: 175px !important;
}

.mb--175 {
    margin-bottom: 175px !important;
}

.mr--175 {
    margin-right: 175px !important;
}

.ml--175 {
    margin-left: 175px !important;
}

.ptb--180 {
    padding: 180px 0 !important;
}

.plr--180 {
    padding: 0 180px !important;
}

.pt--180 {
    padding-top: 180px !important;
}

.pb--180 {
    padding-bottom: 180px !important;
}

.pl--180 {
    padding-left: 180px !important;
}

.pr--180 {
    padding-right: 180px !important;
}

.mt--180 {
    margin-top: 180px !important;
}

.mb--180 {
    margin-bottom: 180px !important;
}

.mr--180 {
    margin-right: 180px !important;
}

.ml--180 {
    margin-left: 180px !important;
}

.ptb--185 {
    padding: 185px 0 !important;
}

.plr--185 {
    padding: 0 185px !important;
}

.pt--185 {
    padding-top: 185px !important;
}

.pb--185 {
    padding-bottom: 185px !important;
}

.pl--185 {
    padding-left: 185px !important;
}

.pr--185 {
    padding-right: 185px !important;
}

.mt--185 {
    margin-top: 185px !important;
}

.mb--185 {
    margin-bottom: 185px !important;
}

.mr--185 {
    margin-right: 185px !important;
}

.ml--185 {
    margin-left: 185px !important;
}

.ptb--190 {
    padding: 190px 0 !important;
}

.plr--190 {
    padding: 0 190px !important;
}

.pt--190 {
    padding-top: 190px !important;
}

.pb--190 {
    padding-bottom: 190px !important;
}

.pl--190 {
    padding-left: 190px !important;
}

.pr--190 {
    padding-right: 190px !important;
}

.mt--190 {
    margin-top: 190px !important;
}

.mb--190 {
    margin-bottom: 190px !important;
}

.mr--190 {
    margin-right: 190px !important;
}

.ml--190 {
    margin-left: 190px !important;
}

.ptb--195 {
    padding: 195px 0 !important;
}

.plr--195 {
    padding: 0 195px !important;
}

.pt--195 {
    padding-top: 195px !important;
}

.pb--195 {
    padding-bottom: 195px !important;
}

.pl--195 {
    padding-left: 195px !important;
}

.pr--195 {
    padding-right: 195px !important;
}

.mt--195 {
    margin-top: 195px !important;
}

.mb--195 {
    margin-bottom: 195px !important;
}

.mr--195 {
    margin-right: 195px !important;
}

.ml--195 {
    margin-left: 195px !important;
}

.ptb--200 {
    padding: 200px 0 !important;
}

.plr--200 {
    padding: 0 200px !important;
}

.pt--200 {
    padding-top: 200px !important;
}

.pb--200 {
    padding-bottom: 200px !important;
}

.pl--200 {
    padding-left: 200px !important;
}

.pr--200 {
    padding-right: 200px !important;
}

.mt--200 {
    margin-top: 200px !important;
}

.mb--200 {
    margin-bottom: 200px !important;
}

.mr--200 {
    margin-right: 200px !important;
}

.ml--200 {
    margin-left: 200px !important;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .ptb_lp--5 {
        padding: 5px 0 !important;
    }

    .plr_lp--5 {
        padding: 0 5px !important;
    }

    .pt_lp--5 {
        padding-top: 5px !important;
    }

    .pb_lp--5 {
        padding-bottom: 5px !important;
    }

    .pl_lp--5 {
        padding-left: 5px !important;
    }

    .pr_lp--5 {
        padding-right: 5px !important;
    }

    .mt_lp--5 {
        margin-top: 5px !important;
    }

    .mb_lp--5 {
        margin-bottom: 5px !important;
    }

    .ptb_lp--10 {
        padding: 10px 0 !important;
    }

    .plr_lp--10 {
        padding: 0 10px !important;
    }

    .pt_lp--10 {
        padding-top: 10px !important;
    }

    .pb_lp--10 {
        padding-bottom: 10px !important;
    }

    .pl_lp--10 {
        padding-left: 10px !important;
    }

    .pr_lp--10 {
        padding-right: 10px !important;
    }

    .mt_lp--10 {
        margin-top: 10px !important;
    }

    .mb_lp--10 {
        margin-bottom: 10px !important;
    }

    .ptb_lp--15 {
        padding: 15px 0 !important;
    }

    .plr_lp--15 {
        padding: 0 15px !important;
    }

    .pt_lp--15 {
        padding-top: 15px !important;
    }

    .pb_lp--15 {
        padding-bottom: 15px !important;
    }

    .pl_lp--15 {
        padding-left: 15px !important;
    }

    .pr_lp--15 {
        padding-right: 15px !important;
    }

    .mt_lp--15 {
        margin-top: 15px !important;
    }

    .mb_lp--15 {
        margin-bottom: 15px !important;
    }

    .ptb_lp--20 {
        padding: 20px 0 !important;
    }

    .plr_lp--20 {
        padding: 0 20px !important;
    }

    .pt_lp--20 {
        padding-top: 20px !important;
    }

    .pb_lp--20 {
        padding-bottom: 20px !important;
    }

    .pl_lp--20 {
        padding-left: 20px !important;
    }

    .pr_lp--20 {
        padding-right: 20px !important;
    }

    .mt_lp--20 {
        margin-top: 20px !important;
    }

    .mb_lp--20 {
        margin-bottom: 20px !important;
    }

    .ptb_lp--25 {
        padding: 25px 0 !important;
    }

    .plr_lp--25 {
        padding: 0 25px !important;
    }

    .pt_lp--25 {
        padding-top: 25px !important;
    }

    .pb_lp--25 {
        padding-bottom: 25px !important;
    }

    .pl_lp--25 {
        padding-left: 25px !important;
    }

    .pr_lp--25 {
        padding-right: 25px !important;
    }

    .mt_lp--25 {
        margin-top: 25px !important;
    }

    .mb_lp--25 {
        margin-bottom: 25px !important;
    }

    .ptb_lp--30 {
        padding: 30px 0 !important;
    }

    .plr_lp--30 {
        padding: 0 30px !important;
    }

    .pt_lp--30 {
        padding-top: 30px !important;
    }

    .pb_lp--30 {
        padding-bottom: 30px !important;
    }

    .pl_lp--30 {
        padding-left: 30px !important;
    }

    .pr_lp--30 {
        padding-right: 30px !important;
    }

    .mt_lp--30 {
        margin-top: 30px !important;
    }

    .mb_lp--30 {
        margin-bottom: 30px !important;
    }

    .ptb_lp--35 {
        padding: 35px 0 !important;
    }

    .plr_lp--35 {
        padding: 0 35px !important;
    }

    .pt_lp--35 {
        padding-top: 35px !important;
    }

    .pb_lp--35 {
        padding-bottom: 35px !important;
    }

    .pl_lp--35 {
        padding-left: 35px !important;
    }

    .pr_lp--35 {
        padding-right: 35px !important;
    }

    .mt_lp--35 {
        margin-top: 35px !important;
    }

    .mb_lp--35 {
        margin-bottom: 35px !important;
    }

    .ptb_lp--40 {
        padding: 40px 0 !important;
    }

    .plr_lp--40 {
        padding: 0 40px !important;
    }

    .pt_lp--40 {
        padding-top: 40px !important;
    }

    .pb_lp--40 {
        padding-bottom: 40px !important;
    }

    .pl_lp--40 {
        padding-left: 40px !important;
    }

    .pr_lp--40 {
        padding-right: 40px !important;
    }

    .mt_lp--40 {
        margin-top: 40px !important;
    }

    .mb_lp--40 {
        margin-bottom: 40px !important;
    }

    .ptb_lp--45 {
        padding: 45px 0 !important;
    }

    .plr_lp--45 {
        padding: 0 45px !important;
    }

    .pt_lp--45 {
        padding-top: 45px !important;
    }

    .pb_lp--45 {
        padding-bottom: 45px !important;
    }

    .pl_lp--45 {
        padding-left: 45px !important;
    }

    .pr_lp--45 {
        padding-right: 45px !important;
    }

    .mt_lp--45 {
        margin-top: 45px !important;
    }

    .mb_lp--45 {
        margin-bottom: 45px !important;
    }

    .ptb_lp--50 {
        padding: 50px 0 !important;
    }

    .plr_lp--50 {
        padding: 0 50px !important;
    }

    .pt_lp--50 {
        padding-top: 50px !important;
    }

    .pb_lp--50 {
        padding-bottom: 50px !important;
    }

    .pl_lp--50 {
        padding-left: 50px !important;
    }

    .pr_lp--50 {
        padding-right: 50px !important;
    }

    .mt_lp--50 {
        margin-top: 50px !important;
    }

    .mb_lp--50 {
        margin-bottom: 50px !important;
    }

    .ptb_lp--55 {
        padding: 55px 0 !important;
    }

    .plr_lp--55 {
        padding: 0 55px !important;
    }

    .pt_lp--55 {
        padding-top: 55px !important;
    }

    .pb_lp--55 {
        padding-bottom: 55px !important;
    }

    .pl_lp--55 {
        padding-left: 55px !important;
    }

    .pr_lp--55 {
        padding-right: 55px !important;
    }

    .mt_lp--55 {
        margin-top: 55px !important;
    }

    .mb_lp--55 {
        margin-bottom: 55px !important;
    }

    .ptb_lp--60 {
        padding: 60px 0 !important;
    }

    .plr_lp--60 {
        padding: 0 60px !important;
    }

    .pt_lp--60 {
        padding-top: 60px !important;
    }

    .pb_lp--60 {
        padding-bottom: 60px !important;
    }

    .pl_lp--60 {
        padding-left: 60px !important;
    }

    .pr_lp--60 {
        padding-right: 60px !important;
    }

    .mt_lp--60 {
        margin-top: 60px !important;
    }

    .mb_lp--60 {
        margin-bottom: 60px !important;
    }

    .ptb_lp--65 {
        padding: 65px 0 !important;
    }

    .plr_lp--65 {
        padding: 0 65px !important;
    }

    .pt_lp--65 {
        padding-top: 65px !important;
    }

    .pb_lp--65 {
        padding-bottom: 65px !important;
    }

    .pl_lp--65 {
        padding-left: 65px !important;
    }

    .pr_lp--65 {
        padding-right: 65px !important;
    }

    .mt_lp--65 {
        margin-top: 65px !important;
    }

    .mb_lp--65 {
        margin-bottom: 65px !important;
    }

    .ptb_lp--70 {
        padding: 70px 0 !important;
    }

    .plr_lp--70 {
        padding: 0 70px !important;
    }

    .pt_lp--70 {
        padding-top: 70px !important;
    }

    .pb_lp--70 {
        padding-bottom: 70px !important;
    }

    .pl_lp--70 {
        padding-left: 70px !important;
    }

    .pr_lp--70 {
        padding-right: 70px !important;
    }

    .mt_lp--70 {
        margin-top: 70px !important;
    }

    .mb_lp--70 {
        margin-bottom: 70px !important;
    }

    .ptb_lp--75 {
        padding: 75px 0 !important;
    }

    .plr_lp--75 {
        padding: 0 75px !important;
    }

    .pt_lp--75 {
        padding-top: 75px !important;
    }

    .pb_lp--75 {
        padding-bottom: 75px !important;
    }

    .pl_lp--75 {
        padding-left: 75px !important;
    }

    .pr_lp--75 {
        padding-right: 75px !important;
    }

    .mt_lp--75 {
        margin-top: 75px !important;
    }

    .mb_lp--75 {
        margin-bottom: 75px !important;
    }

    .ptb_lp--80 {
        padding: 80px 0 !important;
    }

    .plr_lp--80 {
        padding: 0 80px !important;
    }

    .pt_lp--80 {
        padding-top: 80px !important;
    }

    .pb_lp--80 {
        padding-bottom: 80px !important;
    }

    .pl_lp--80 {
        padding-left: 80px !important;
    }

    .pr_lp--80 {
        padding-right: 80px !important;
    }

    .mt_lp--80 {
        margin-top: 80px !important;
    }

    .mb_lp--80 {
        margin-bottom: 80px !important;
    }

    .ptb_lp--85 {
        padding: 85px 0 !important;
    }

    .plr_lp--85 {
        padding: 0 85px !important;
    }

    .pt_lp--85 {
        padding-top: 85px !important;
    }

    .pb_lp--85 {
        padding-bottom: 85px !important;
    }

    .pl_lp--85 {
        padding-left: 85px !important;
    }

    .pr_lp--85 {
        padding-right: 85px !important;
    }

    .mt_lp--85 {
        margin-top: 85px !important;
    }

    .mb_lp--85 {
        margin-bottom: 85px !important;
    }

    .ptb_lp--90 {
        padding: 90px 0 !important;
    }

    .plr_lp--90 {
        padding: 0 90px !important;
    }

    .pt_lp--90 {
        padding-top: 90px !important;
    }

    .pb_lp--90 {
        padding-bottom: 90px !important;
    }

    .pl_lp--90 {
        padding-left: 90px !important;
    }

    .pr_lp--90 {
        padding-right: 90px !important;
    }

    .mt_lp--90 {
        margin-top: 90px !important;
    }

    .mb_lp--90 {
        margin-bottom: 90px !important;
    }

    .ptb_lp--95 {
        padding: 95px 0 !important;
    }

    .plr_lp--95 {
        padding: 0 95px !important;
    }

    .pt_lp--95 {
        padding-top: 95px !important;
    }

    .pb_lp--95 {
        padding-bottom: 95px !important;
    }

    .pl_lp--95 {
        padding-left: 95px !important;
    }

    .pr_lp--95 {
        padding-right: 95px !important;
    }

    .mt_lp--95 {
        margin-top: 95px !important;
    }

    .mb_lp--95 {
        margin-bottom: 95px !important;
    }

    .ptb_lp--100 {
        padding: 100px 0 !important;
    }

    .plr_lp--100 {
        padding: 0 100px !important;
    }

    .pt_lp--100 {
        padding-top: 100px !important;
    }

    .pb_lp--100 {
        padding-bottom: 100px !important;
    }

    .pl_lp--100 {
        padding-left: 100px !important;
    }

    .pr_lp--100 {
        padding-right: 100px !important;
    }

    .mt_lp--100 {
        margin-top: 100px !important;
    }

    .mb_lp--100 {
        margin-bottom: 100px !important;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .ptb_lg--5 {
        padding: 5px 0 !important;
    }

    .plr_lg--5 {
        padding: 0 5px !important;
    }

    .pt_lg--5 {
        padding-top: 5px !important;
    }

    .pb_lg--5 {
        padding-bottom: 5px !important;
    }

    .pl_lg--5 {
        padding-left: 5px !important;
    }

    .pr_lg--5 {
        padding-right: 5px !important;
    }

    .mt_lg--5 {
        margin-top: 5px !important;
    }

    .mb_lg--5 {
        margin-bottom: 5px !important;
    }

    .ml_lg--5 {
        margin-left: 5px !important;
    }

    .ptb_lg--10 {
        padding: 10px 0 !important;
    }

    .plr_lg--10 {
        padding: 0 10px !important;
    }

    .pt_lg--10 {
        padding-top: 10px !important;
    }

    .pb_lg--10 {
        padding-bottom: 10px !important;
    }

    .pl_lg--10 {
        padding-left: 10px !important;
    }

    .pr_lg--10 {
        padding-right: 10px !important;
    }

    .mt_lg--10 {
        margin-top: 10px !important;
    }

    .mb_lg--10 {
        margin-bottom: 10px !important;
    }

    .ml_lg--10 {
        margin-left: 10px !important;
    }

    .ptb_lg--15 {
        padding: 15px 0 !important;
    }

    .plr_lg--15 {
        padding: 0 15px !important;
    }

    .pt_lg--15 {
        padding-top: 15px !important;
    }

    .pb_lg--15 {
        padding-bottom: 15px !important;
    }

    .pl_lg--15 {
        padding-left: 15px !important;
    }

    .pr_lg--15 {
        padding-right: 15px !important;
    }

    .mt_lg--15 {
        margin-top: 15px !important;
    }

    .mb_lg--15 {
        margin-bottom: 15px !important;
    }

    .ml_lg--15 {
        margin-left: 15px !important;
    }

    .ptb_lg--20 {
        padding: 20px 0 !important;
    }

    .plr_lg--20 {
        padding: 0 20px !important;
    }

    .pt_lg--20 {
        padding-top: 20px !important;
    }

    .pb_lg--20 {
        padding-bottom: 20px !important;
    }

    .pl_lg--20 {
        padding-left: 20px !important;
    }

    .pr_lg--20 {
        padding-right: 20px !important;
    }

    .mt_lg--20 {
        margin-top: 20px !important;
    }

    .mb_lg--20 {
        margin-bottom: 20px !important;
    }

    .ml_lg--20 {
        margin-left: 20px !important;
    }

    .ptb_lg--25 {
        padding: 25px 0 !important;
    }

    .plr_lg--25 {
        padding: 0 25px !important;
    }

    .pt_lg--25 {
        padding-top: 25px !important;
    }

    .pb_lg--25 {
        padding-bottom: 25px !important;
    }

    .pl_lg--25 {
        padding-left: 25px !important;
    }

    .pr_lg--25 {
        padding-right: 25px !important;
    }

    .mt_lg--25 {
        margin-top: 25px !important;
    }

    .mb_lg--25 {
        margin-bottom: 25px !important;
    }

    .ml_lg--25 {
        margin-left: 25px !important;
    }

    .ptb_lg--30 {
        padding: 30px 0 !important;
    }

    .plr_lg--30 {
        padding: 0 30px !important;
    }

    .pt_lg--30 {
        padding-top: 30px !important;
    }

    .pb_lg--30 {
        padding-bottom: 30px !important;
    }

    .pl_lg--30 {
        padding-left: 30px !important;
    }

    .pr_lg--30 {
        padding-right: 30px !important;
    }

    .mt_lg--30 {
        margin-top: 30px !important;
    }

    .mb_lg--30 {
        margin-bottom: 30px !important;
    }

    .ml_lg--30 {
        margin-left: 30px !important;
    }

    .ptb_lg--35 {
        padding: 35px 0 !important;
    }

    .plr_lg--35 {
        padding: 0 35px !important;
    }

    .pt_lg--35 {
        padding-top: 35px !important;
    }

    .pb_lg--35 {
        padding-bottom: 35px !important;
    }

    .pl_lg--35 {
        padding-left: 35px !important;
    }

    .pr_lg--35 {
        padding-right: 35px !important;
    }

    .mt_lg--35 {
        margin-top: 35px !important;
    }

    .mb_lg--35 {
        margin-bottom: 35px !important;
    }

    .ml_lg--35 {
        margin-left: 35px !important;
    }

    .ptb_lg--40 {
        padding: 40px 0 !important;
    }

    .plr_lg--40 {
        padding: 0 40px !important;
    }

    .pt_lg--40 {
        padding-top: 40px !important;
    }

    .pb_lg--40 {
        padding-bottom: 40px !important;
    }

    .pl_lg--40 {
        padding-left: 40px !important;
    }

    .pr_lg--40 {
        padding-right: 40px !important;
    }

    .mt_lg--40 {
        margin-top: 40px !important;
    }

    .mb_lg--40 {
        margin-bottom: 40px !important;
    }

    .ml_lg--40 {
        margin-left: 40px !important;
    }

    .ptb_lg--45 {
        padding: 45px 0 !important;
    }

    .plr_lg--45 {
        padding: 0 45px !important;
    }

    .pt_lg--45 {
        padding-top: 45px !important;
    }

    .pb_lg--45 {
        padding-bottom: 45px !important;
    }

    .pl_lg--45 {
        padding-left: 45px !important;
    }

    .pr_lg--45 {
        padding-right: 45px !important;
    }

    .mt_lg--45 {
        margin-top: 45px !important;
    }

    .mb_lg--45 {
        margin-bottom: 45px !important;
    }

    .ml_lg--45 {
        margin-left: 45px !important;
    }

    .ptb_lg--50 {
        padding: 50px 0 !important;
    }

    .plr_lg--50 {
        padding: 0 50px !important;
    }

    .pt_lg--50 {
        padding-top: 50px !important;
    }

    .pb_lg--50 {
        padding-bottom: 50px !important;
    }

    .pl_lg--50 {
        padding-left: 50px !important;
    }

    .pr_lg--50 {
        padding-right: 50px !important;
    }

    .mt_lg--50 {
        margin-top: 50px !important;
    }

    .mb_lg--50 {
        margin-bottom: 50px !important;
    }

    .ml_lg--50 {
        margin-left: 50px !important;
    }

    .ptb_lg--55 {
        padding: 55px 0 !important;
    }

    .plr_lg--55 {
        padding: 0 55px !important;
    }

    .pt_lg--55 {
        padding-top: 55px !important;
    }

    .pb_lg--55 {
        padding-bottom: 55px !important;
    }

    .pl_lg--55 {
        padding-left: 55px !important;
    }

    .pr_lg--55 {
        padding-right: 55px !important;
    }

    .mt_lg--55 {
        margin-top: 55px !important;
    }

    .mb_lg--55 {
        margin-bottom: 55px !important;
    }

    .ml_lg--55 {
        margin-left: 55px !important;
    }

    .ptb_lg--60 {
        padding: 60px 0 !important;
    }

    .plr_lg--60 {
        padding: 0 60px !important;
    }

    .pt_lg--60 {
        padding-top: 60px !important;
    }

    .pb_lg--60 {
        padding-bottom: 60px !important;
    }

    .pl_lg--60 {
        padding-left: 60px !important;
    }

    .pr_lg--60 {
        padding-right: 60px !important;
    }

    .mt_lg--60 {
        margin-top: 60px !important;
    }

    .mb_lg--60 {
        margin-bottom: 60px !important;
    }

    .ml_lg--60 {
        margin-left: 60px !important;
    }

    .ptb_lg--65 {
        padding: 65px 0 !important;
    }

    .plr_lg--65 {
        padding: 0 65px !important;
    }

    .pt_lg--65 {
        padding-top: 65px !important;
    }

    .pb_lg--65 {
        padding-bottom: 65px !important;
    }

    .pl_lg--65 {
        padding-left: 65px !important;
    }

    .pr_lg--65 {
        padding-right: 65px !important;
    }

    .mt_lg--65 {
        margin-top: 65px !important;
    }

    .mb_lg--65 {
        margin-bottom: 65px !important;
    }

    .ml_lg--65 {
        margin-left: 65px !important;
    }

    .ptb_lg--70 {
        padding: 70px 0 !important;
    }

    .plr_lg--70 {
        padding: 0 70px !important;
    }

    .pt_lg--70 {
        padding-top: 70px !important;
    }

    .pb_lg--70 {
        padding-bottom: 70px !important;
    }

    .pl_lg--70 {
        padding-left: 70px !important;
    }

    .pr_lg--70 {
        padding-right: 70px !important;
    }

    .mt_lg--70 {
        margin-top: 70px !important;
    }

    .mb_lg--70 {
        margin-bottom: 70px !important;
    }

    .ml_lg--70 {
        margin-left: 70px !important;
    }

    .ptb_lg--75 {
        padding: 75px 0 !important;
    }

    .plr_lg--75 {
        padding: 0 75px !important;
    }

    .pt_lg--75 {
        padding-top: 75px !important;
    }

    .pb_lg--75 {
        padding-bottom: 75px !important;
    }

    .pl_lg--75 {
        padding-left: 75px !important;
    }

    .pr_lg--75 {
        padding-right: 75px !important;
    }

    .mt_lg--75 {
        margin-top: 75px !important;
    }

    .mb_lg--75 {
        margin-bottom: 75px !important;
    }

    .ml_lg--75 {
        margin-left: 75px !important;
    }

    .ptb_lg--80 {
        padding: 80px 0 !important;
    }

    .plr_lg--80 {
        padding: 0 80px !important;
    }

    .pt_lg--80 {
        padding-top: 80px !important;
    }

    .pb_lg--80 {
        padding-bottom: 80px !important;
    }

    .pl_lg--80 {
        padding-left: 80px !important;
    }

    .pr_lg--80 {
        padding-right: 80px !important;
    }

    .mt_lg--80 {
        margin-top: 80px !important;
    }

    .mb_lg--80 {
        margin-bottom: 80px !important;
    }

    .ml_lg--80 {
        margin-left: 80px !important;
    }

    .ptb_lg--85 {
        padding: 85px 0 !important;
    }

    .plr_lg--85 {
        padding: 0 85px !important;
    }

    .pt_lg--85 {
        padding-top: 85px !important;
    }

    .pb_lg--85 {
        padding-bottom: 85px !important;
    }

    .pl_lg--85 {
        padding-left: 85px !important;
    }

    .pr_lg--85 {
        padding-right: 85px !important;
    }

    .mt_lg--85 {
        margin-top: 85px !important;
    }

    .mb_lg--85 {
        margin-bottom: 85px !important;
    }

    .ml_lg--85 {
        margin-left: 85px !important;
    }

    .ptb_lg--90 {
        padding: 90px 0 !important;
    }

    .plr_lg--90 {
        padding: 0 90px !important;
    }

    .pt_lg--90 {
        padding-top: 90px !important;
    }

    .pb_lg--90 {
        padding-bottom: 90px !important;
    }

    .pl_lg--90 {
        padding-left: 90px !important;
    }

    .pr_lg--90 {
        padding-right: 90px !important;
    }

    .mt_lg--90 {
        margin-top: 90px !important;
    }

    .mb_lg--90 {
        margin-bottom: 90px !important;
    }

    .ml_lg--90 {
        margin-left: 90px !important;
    }

    .ptb_lg--95 {
        padding: 95px 0 !important;
    }

    .plr_lg--95 {
        padding: 0 95px !important;
    }

    .pt_lg--95 {
        padding-top: 95px !important;
    }

    .pb_lg--95 {
        padding-bottom: 95px !important;
    }

    .pl_lg--95 {
        padding-left: 95px !important;
    }

    .pr_lg--95 {
        padding-right: 95px !important;
    }

    .mt_lg--95 {
        margin-top: 95px !important;
    }

    .mb_lg--95 {
        margin-bottom: 95px !important;
    }

    .ml_lg--95 {
        margin-left: 95px !important;
    }

    .ptb_lg--100 {
        padding: 100px 0 !important;
    }

    .plr_lg--100 {
        padding: 0 100px !important;
    }

    .pt_lg--100 {
        padding-top: 100px !important;
    }

    .pb_lg--100 {
        padding-bottom: 100px !important;
    }

    .pl_lg--100 {
        padding-left: 100px !important;
    }

    .pr_lg--100 {
        padding-right: 100px !important;
    }

    .mt_lg--100 {
        margin-top: 100px !important;
    }

    .mb_lg--100 {
        margin-bottom: 100px !important;
    }

    .ml_lg--100 {
        margin-left: 100px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .ptb_md--0 {
        padding: 0 !important;
    }

    .pl_md--0 {
        padding-left: 0 !important;
    }

    .pr_md--0 {
        padding-right: 0 !important;
    }

    .pt_md--0 {
        padding-top: 0 !important;
    }

    .pb_md--0 {
        padding-bottom: 0 !important;
    }

    .mr_md--0 {
        margin-right: 0 !important;
    }

    .ml_md--0 {
        margin-left: 0 !important;
    }

    .mt_md--0 {
        margin-top: 0 !important;
    }

    .mb_md--0 {
        margin-bottom: 0 !important;
    }

    .ptb_md--250 {
        padding: 250px 0 !important;
    }

    .ptb_md--5 {
        padding: 5px 0 !important;
    }

    .plr_md--5 {
        padding: 0 5px !important;
    }

    .pt_md--5 {
        padding-top: 5px !important;
    }

    .pb_md--5 {
        padding-bottom: 5px !important;
    }

    .pl_md--5 {
        padding-left: 5px !important;
    }

    .pr_md--5 {
        padding-right: 5px !important;
    }

    .mt_md--5 {
        margin-top: 5px !important;
    }

    .mb_md--5 {
        margin-bottom: 5px !important;
    }

    .ptb_md--10 {
        padding: 10px 0 !important;
    }

    .plr_md--10 {
        padding: 0 10px !important;
    }

    .pt_md--10 {
        padding-top: 10px !important;
    }

    .pb_md--10 {
        padding-bottom: 10px !important;
    }

    .pl_md--10 {
        padding-left: 10px !important;
    }

    .pr_md--10 {
        padding-right: 10px !important;
    }

    .mt_md--10 {
        margin-top: 10px !important;
    }

    .mb_md--10 {
        margin-bottom: 10px !important;
    }

    .ptb_md--15 {
        padding: 15px 0 !important;
    }

    .plr_md--15 {
        padding: 0 15px !important;
    }

    .pt_md--15 {
        padding-top: 15px !important;
    }

    .pb_md--15 {
        padding-bottom: 15px !important;
    }

    .pl_md--15 {
        padding-left: 15px !important;
    }

    .pr_md--15 {
        padding-right: 15px !important;
    }

    .mt_md--15 {
        margin-top: 15px !important;
    }

    .mb_md--15 {
        margin-bottom: 15px !important;
    }

    .ptb_md--20 {
        padding: 20px 0 !important;
    }

    .plr_md--20 {
        padding: 0 20px !important;
    }

    .pt_md--20 {
        padding-top: 20px !important;
    }

    .pb_md--20 {
        padding-bottom: 20px !important;
    }

    .pl_md--20 {
        padding-left: 20px !important;
    }

    .pr_md--20 {
        padding-right: 20px !important;
    }

    .mt_md--20 {
        margin-top: 20px !important;
    }

    .mb_md--20 {
        margin-bottom: 20px !important;
    }

    .ptb_md--25 {
        padding: 25px 0 !important;
    }

    .plr_md--25 {
        padding: 0 25px !important;
    }

    .pt_md--25 {
        padding-top: 25px !important;
    }

    .pb_md--25 {
        padding-bottom: 25px !important;
    }

    .pl_md--25 {
        padding-left: 25px !important;
    }

    .pr_md--25 {
        padding-right: 25px !important;
    }

    .mt_md--25 {
        margin-top: 25px !important;
    }

    .mb_md--25 {
        margin-bottom: 25px !important;
    }

    .ptb_md--30 {
        padding: 30px 0 !important;
    }

    .plr_md--30 {
        padding: 0 30px !important;
    }

    .pt_md--30 {
        padding-top: 30px !important;
    }

    .pb_md--30 {
        padding-bottom: 30px !important;
    }

    .pl_md--30 {
        padding-left: 30px !important;
    }

    .pr_md--30 {
        padding-right: 30px !important;
    }

    .mt_md--30 {
        margin-top: 30px !important;
    }

    .mb_md--30 {
        margin-bottom: 30px !important;
    }

    .ptb_md--35 {
        padding: 35px 0 !important;
    }

    .plr_md--35 {
        padding: 0 35px !important;
    }

    .pt_md--35 {
        padding-top: 35px !important;
    }

    .pb_md--35 {
        padding-bottom: 35px !important;
    }

    .pl_md--35 {
        padding-left: 35px !important;
    }

    .pr_md--35 {
        padding-right: 35px !important;
    }

    .mt_md--35 {
        margin-top: 35px !important;
    }

    .mb_md--35 {
        margin-bottom: 35px !important;
    }

    .ptb_md--40 {
        padding: 40px 0 !important;
    }

    .plr_md--40 {
        padding: 0 40px !important;
    }

    .pt_md--40 {
        padding-top: 40px !important;
    }

    .pb_md--40 {
        padding-bottom: 40px !important;
    }

    .pl_md--40 {
        padding-left: 40px !important;
    }

    .pr_md--40 {
        padding-right: 40px !important;
    }

    .mt_md--40 {
        margin-top: 40px !important;
    }

    .mb_md--40 {
        margin-bottom: 40px !important;
    }

    .ptb_md--45 {
        padding: 45px 0 !important;
    }

    .plr_md--45 {
        padding: 0 45px !important;
    }

    .pt_md--45 {
        padding-top: 45px !important;
    }

    .pb_md--45 {
        padding-bottom: 45px !important;
    }

    .pl_md--45 {
        padding-left: 45px !important;
    }

    .pr_md--45 {
        padding-right: 45px !important;
    }

    .mt_md--45 {
        margin-top: 45px !important;
    }

    .mb_md--45 {
        margin-bottom: 45px !important;
    }

    .ptb_md--50 {
        padding: 50px 0 !important;
    }

    .plr_md--50 {
        padding: 0 50px !important;
    }

    .pt_md--50 {
        padding-top: 50px !important;
    }

    .pb_md--50 {
        padding-bottom: 50px !important;
    }

    .pl_md--50 {
        padding-left: 50px !important;
    }

    .pr_md--50 {
        padding-right: 50px !important;
    }

    .mt_md--50 {
        margin-top: 50px !important;
    }

    .mb_md--50 {
        margin-bottom: 50px !important;
    }

    .ptb_md--55 {
        padding: 55px 0 !important;
    }

    .plr_md--55 {
        padding: 0 55px !important;
    }

    .pt_md--55 {
        padding-top: 55px !important;
    }

    .pb_md--55 {
        padding-bottom: 55px !important;
    }

    .pl_md--55 {
        padding-left: 55px !important;
    }

    .pr_md--55 {
        padding-right: 55px !important;
    }

    .mt_md--55 {
        margin-top: 55px !important;
    }

    .mb_md--55 {
        margin-bottom: 55px !important;
    }

    .ptb_md--60 {
        padding: 60px 0 !important;
    }

    .plr_md--60 {
        padding: 0 60px !important;
    }

    .pt_md--60 {
        padding-top: 60px !important;
    }

    .pb_md--60 {
        padding-bottom: 60px !important;
    }

    .pl_md--60 {
        padding-left: 60px !important;
    }

    .pr_md--60 {
        padding-right: 60px !important;
    }

    .mt_md--60 {
        margin-top: 60px !important;
    }

    .mb_md--60 {
        margin-bottom: 60px !important;
    }

    .ptb_md--65 {
        padding: 65px 0 !important;
    }

    .plr_md--65 {
        padding: 0 65px !important;
    }

    .pt_md--65 {
        padding-top: 65px !important;
    }

    .pb_md--65 {
        padding-bottom: 65px !important;
    }

    .pl_md--65 {
        padding-left: 65px !important;
    }

    .pr_md--65 {
        padding-right: 65px !important;
    }

    .mt_md--65 {
        margin-top: 65px !important;
    }

    .mb_md--65 {
        margin-bottom: 65px !important;
    }

    .ptb_md--70 {
        padding: 70px 0 !important;
    }

    .plr_md--70 {
        padding: 0 70px !important;
    }

    .pt_md--70 {
        padding-top: 70px !important;
    }

    .pb_md--70 {
        padding-bottom: 70px !important;
    }

    .pl_md--70 {
        padding-left: 70px !important;
    }

    .pr_md--70 {
        padding-right: 70px !important;
    }

    .mt_md--70 {
        margin-top: 70px !important;
    }

    .mb_md--70 {
        margin-bottom: 70px !important;
    }

    .ptb_md--75 {
        padding: 75px 0 !important;
    }

    .plr_md--75 {
        padding: 0 75px !important;
    }

    .pt_md--75 {
        padding-top: 75px !important;
    }

    .pb_md--75 {
        padding-bottom: 75px !important;
    }

    .pl_md--75 {
        padding-left: 75px !important;
    }

    .pr_md--75 {
        padding-right: 75px !important;
    }

    .mt_md--75 {
        margin-top: 75px !important;
    }

    .mb_md--75 {
        margin-bottom: 75px !important;
    }

    .ptb_md--80 {
        padding: 80px 0 !important;
    }

    .plr_md--80 {
        padding: 0 80px !important;
    }

    .pt_md--80 {
        padding-top: 80px !important;
    }

    .pb_md--80 {
        padding-bottom: 80px !important;
    }

    .pl_md--80 {
        padding-left: 80px !important;
    }

    .pr_md--80 {
        padding-right: 80px !important;
    }

    .mt_md--80 {
        margin-top: 80px !important;
    }

    .mb_md--80 {
        margin-bottom: 80px !important;
    }

    .ptb_md--85 {
        padding: 85px 0 !important;
    }

    .plr_md--85 {
        padding: 0 85px !important;
    }

    .pt_md--85 {
        padding-top: 85px !important;
    }

    .pb_md--85 {
        padding-bottom: 85px !important;
    }

    .pl_md--85 {
        padding-left: 85px !important;
    }

    .pr_md--85 {
        padding-right: 85px !important;
    }

    .mt_md--85 {
        margin-top: 85px !important;
    }

    .mb_md--85 {
        margin-bottom: 85px !important;
    }

    .ptb_md--90 {
        padding: 90px 0 !important;
    }

    .plr_md--90 {
        padding: 0 90px !important;
    }

    .pt_md--90 {
        padding-top: 90px !important;
    }

    .pb_md--90 {
        padding-bottom: 90px !important;
    }

    .pl_md--90 {
        padding-left: 90px !important;
    }

    .pr_md--90 {
        padding-right: 90px !important;
    }

    .mt_md--90 {
        margin-top: 90px !important;
    }

    .mb_md--90 {
        margin-bottom: 90px !important;
    }

    .ptb_md--95 {
        padding: 95px 0 !important;
    }

    .plr_md--95 {
        padding: 0 95px !important;
    }

    .pt_md--95 {
        padding-top: 95px !important;
    }

    .pb_md--95 {
        padding-bottom: 95px !important;
    }

    .pl_md--95 {
        padding-left: 95px !important;
    }

    .pr_md--95 {
        padding-right: 95px !important;
    }

    .mt_md--95 {
        margin-top: 95px !important;
    }

    .mb_md--95 {
        margin-bottom: 95px !important;
    }

    .ptb_md--100 {
        padding: 100px 0 !important;
    }

    .plr_md--100 {
        padding: 0 100px !important;
    }

    .pt_md--100 {
        padding-top: 100px !important;
    }

    .pb_md--100 {
        padding-bottom: 100px !important;
    }

    .pl_md--100 {
        padding-left: 100px !important;
    }

    .pr_md--100 {
        padding-right: 100px !important;
    }

    .mt_md--100 {
        margin-top: 100px !important;
    }

    .mb_md--100 {
        margin-bottom: 100px !important;
    }
}

@media only screen and (max-width: 767px) {
    .ptb_sm--250 {
        padding: 250px 0 !important;
    }

    .ptb_sm--0 {
        padding: 0 !important;
    }

    .pl_sm--0 {
        padding-left: 0 !important;
    }

    .pr_sm--0 {
        padding-right: 0 !important;
    }

    .pt_sm--0 {
        padding-top: 0 !important;
    }

    .pb_sm--0 {
        padding-bottom: 0 !important;
    }

    .mr_sm--0 {
        margin-right: 0 !important;
    }

    .ml_sm--0 {
        margin-left: 0 !important;
    }

    .mt_sm--0 {
        margin-top: 0 !important;
    }

    .mb_sm--0 {
        margin-bottom: 0 !important;
    }

    .pt_sm--150 {
        padding-top: 150px !important;
    }

    .pb_sm--110 {
        padding-bottom: 110px !important;
    }

    .ptb_sm--5 {
        padding: 5px 0 !important;
    }

    .plr_sm--5 {
        padding: 0 5px !important;
    }

    .pt_sm--5 {
        padding-top: 5px !important;
    }

    .pb_sm--5 {
        padding-bottom: 5px !important;
    }

    .pl_sm--5 {
        padding-left: 5px !important;
    }

    .pr_sm--5 {
        padding-right: 5px !important;
    }

    .mt_sm--5 {
        margin-top: 5px !important;
    }

    .ml_sm--5 {
        margin-left: 5px !important;
    }

    .mr_sm--5 {
        margin-right: 5px !important;
    }

    .mb_sm--5 {
        margin-bottom: 5px !important;
    }

    .ptb_sm--10 {
        padding: 10px 0 !important;
    }

    .plr_sm--10 {
        padding: 0 10px !important;
    }

    .pt_sm--10 {
        padding-top: 10px !important;
    }

    .pb_sm--10 {
        padding-bottom: 10px !important;
    }

    .pl_sm--10 {
        padding-left: 10px !important;
    }

    .pr_sm--10 {
        padding-right: 10px !important;
    }

    .mt_sm--10 {
        margin-top: 10px !important;
    }

    .ml_sm--10 {
        margin-left: 10px !important;
    }

    .mr_sm--10 {
        margin-right: 10px !important;
    }

    .mb_sm--10 {
        margin-bottom: 10px !important;
    }

    .ptb_sm--15 {
        padding: 15px 0 !important;
    }

    .plr_sm--15 {
        padding: 0 15px !important;
    }

    .pt_sm--15 {
        padding-top: 15px !important;
    }

    .pb_sm--15 {
        padding-bottom: 15px !important;
    }

    .pl_sm--15 {
        padding-left: 15px !important;
    }

    .pr_sm--15 {
        padding-right: 15px !important;
    }

    .mt_sm--15 {
        margin-top: 15px !important;
    }

    .ml_sm--15 {
        margin-left: 15px !important;
    }

    .mr_sm--15 {
        margin-right: 15px !important;
    }

    .mb_sm--15 {
        margin-bottom: 15px !important;
    }

    .ptb_sm--20 {
        padding: 20px 0 !important;
    }

    .plr_sm--20 {
        padding: 0 20px !important;
    }

    .pt_sm--20 {
        padding-top: 20px !important;
    }

    .pb_sm--20 {
        padding-bottom: 20px !important;
    }

    .pl_sm--20 {
        padding-left: 20px !important;
    }

    .pr_sm--20 {
        padding-right: 20px !important;
    }

    .mt_sm--20 {
        margin-top: 20px !important;
    }

    .ml_sm--20 {
        margin-left: 20px !important;
    }

    .mr_sm--20 {
        margin-right: 20px !important;
    }

    .mb_sm--20 {
        margin-bottom: 20px !important;
    }

    .ptb_sm--25 {
        padding: 25px 0 !important;
    }

    .plr_sm--25 {
        padding: 0 25px !important;
    }

    .pt_sm--25 {
        padding-top: 25px !important;
    }

    .pb_sm--25 {
        padding-bottom: 25px !important;
    }

    .pl_sm--25 {
        padding-left: 25px !important;
    }

    .pr_sm--25 {
        padding-right: 25px !important;
    }

    .mt_sm--25 {
        margin-top: 25px !important;
    }

    .ml_sm--25 {
        margin-left: 25px !important;
    }

    .mr_sm--25 {
        margin-right: 25px !important;
    }

    .mb_sm--25 {
        margin-bottom: 25px !important;
    }

    .ptb_sm--30 {
        padding: 30px 0 !important;
    }

    .plr_sm--30 {
        padding: 0 30px !important;
    }

    .pt_sm--30 {
        padding-top: 30px !important;
    }

    .pb_sm--30 {
        padding-bottom: 30px !important;
    }

    .pl_sm--30 {
        padding-left: 30px !important;
    }

    .pr_sm--30 {
        padding-right: 30px !important;
    }

    .mt_sm--30 {
        margin-top: 30px !important;
    }

    .ml_sm--30 {
        margin-left: 30px !important;
    }

    .mr_sm--30 {
        margin-right: 30px !important;
    }

    .mb_sm--30 {
        margin-bottom: 30px !important;
    }

    .ptb_sm--35 {
        padding: 35px 0 !important;
    }

    .plr_sm--35 {
        padding: 0 35px !important;
    }

    .pt_sm--35 {
        padding-top: 35px !important;
    }

    .pb_sm--35 {
        padding-bottom: 35px !important;
    }

    .pl_sm--35 {
        padding-left: 35px !important;
    }

    .pr_sm--35 {
        padding-right: 35px !important;
    }

    .mt_sm--35 {
        margin-top: 35px !important;
    }

    .ml_sm--35 {
        margin-left: 35px !important;
    }

    .mr_sm--35 {
        margin-right: 35px !important;
    }

    .mb_sm--35 {
        margin-bottom: 35px !important;
    }

    .ptb_sm--40 {
        padding: 40px 0 !important;
    }

    .plr_sm--40 {
        padding: 0 40px !important;
    }

    .pt_sm--40 {
        padding-top: 40px !important;
    }

    .pb_sm--40 {
        padding-bottom: 40px !important;
    }

    .pl_sm--40 {
        padding-left: 40px !important;
    }

    .pr_sm--40 {
        padding-right: 40px !important;
    }

    .mt_sm--40 {
        margin-top: 40px !important;
    }

    .ml_sm--40 {
        margin-left: 40px !important;
    }

    .mr_sm--40 {
        margin-right: 40px !important;
    }

    .mb_sm--40 {
        margin-bottom: 40px !important;
    }

    .ptb_sm--45 {
        padding: 45px 0 !important;
    }

    .plr_sm--45 {
        padding: 0 45px !important;
    }

    .pt_sm--45 {
        padding-top: 45px !important;
    }

    .pb_sm--45 {
        padding-bottom: 45px !important;
    }

    .pl_sm--45 {
        padding-left: 45px !important;
    }

    .pr_sm--45 {
        padding-right: 45px !important;
    }

    .mt_sm--45 {
        margin-top: 45px !important;
    }

    .ml_sm--45 {
        margin-left: 45px !important;
    }

    .mr_sm--45 {
        margin-right: 45px !important;
    }

    .mb_sm--45 {
        margin-bottom: 45px !important;
    }

    .ptb_sm--50 {
        padding: 50px 0 !important;
    }

    .plr_sm--50 {
        padding: 0 50px !important;
    }

    .pt_sm--50 {
        padding-top: 50px !important;
    }

    .pb_sm--50 {
        padding-bottom: 50px !important;
    }

    .pl_sm--50 {
        padding-left: 50px !important;
    }

    .pr_sm--50 {
        padding-right: 50px !important;
    }

    .mt_sm--50 {
        margin-top: 50px !important;
    }

    .ml_sm--50 {
        margin-left: 50px !important;
    }

    .mr_sm--50 {
        margin-right: 50px !important;
    }

    .mb_sm--50 {
        margin-bottom: 50px !important;
    }

    .ptb_sm--55 {
        padding: 55px 0 !important;
    }

    .plr_sm--55 {
        padding: 0 55px !important;
    }

    .pt_sm--55 {
        padding-top: 55px !important;
    }

    .pb_sm--55 {
        padding-bottom: 55px !important;
    }

    .pl_sm--55 {
        padding-left: 55px !important;
    }

    .pr_sm--55 {
        padding-right: 55px !important;
    }

    .mt_sm--55 {
        margin-top: 55px !important;
    }

    .ml_sm--55 {
        margin-left: 55px !important;
    }

    .mr_sm--55 {
        margin-right: 55px !important;
    }

    .mb_sm--55 {
        margin-bottom: 55px !important;
    }

    .ptb_sm--60 {
        padding: 60px 0 !important;
    }

    .plr_sm--60 {
        padding: 0 60px !important;
    }

    .pt_sm--60 {
        padding-top: 60px !important;
    }

    .pb_sm--60 {
        padding-bottom: 60px !important;
    }

    .pl_sm--60 {
        padding-left: 60px !important;
    }

    .pr_sm--60 {
        padding-right: 60px !important;
    }

    .mt_sm--60 {
        margin-top: 60px !important;
    }

    .ml_sm--60 {
        margin-left: 60px !important;
    }

    .mr_sm--60 {
        margin-right: 60px !important;
    }

    .mb_sm--60 {
        margin-bottom: 60px !important;
    }

    .ptb_sm--65 {
        padding: 65px 0 !important;
    }

    .plr_sm--65 {
        padding: 0 65px !important;
    }

    .pt_sm--65 {
        padding-top: 65px !important;
    }

    .pb_sm--65 {
        padding-bottom: 65px !important;
    }

    .pl_sm--65 {
        padding-left: 65px !important;
    }

    .pr_sm--65 {
        padding-right: 65px !important;
    }

    .mt_sm--65 {
        margin-top: 65px !important;
    }

    .ml_sm--65 {
        margin-left: 65px !important;
    }

    .mr_sm--65 {
        margin-right: 65px !important;
    }

    .mb_sm--65 {
        margin-bottom: 65px !important;
    }

    .ptb_sm--70 {
        padding: 70px 0 !important;
    }

    .plr_sm--70 {
        padding: 0 70px !important;
    }

    .pt_sm--70 {
        padding-top: 70px !important;
    }

    .pb_sm--70 {
        padding-bottom: 70px !important;
    }

    .pl_sm--70 {
        padding-left: 70px !important;
    }

    .pr_sm--70 {
        padding-right: 70px !important;
    }

    .mt_sm--70 {
        margin-top: 70px !important;
    }

    .ml_sm--70 {
        margin-left: 70px !important;
    }

    .mr_sm--70 {
        margin-right: 70px !important;
    }

    .mb_sm--70 {
        margin-bottom: 70px !important;
    }

    .ptb_sm--75 {
        padding: 75px 0 !important;
    }

    .plr_sm--75 {
        padding: 0 75px !important;
    }

    .pt_sm--75 {
        padding-top: 75px !important;
    }

    .pb_sm--75 {
        padding-bottom: 75px !important;
    }

    .pl_sm--75 {
        padding-left: 75px !important;
    }

    .pr_sm--75 {
        padding-right: 75px !important;
    }

    .mt_sm--75 {
        margin-top: 75px !important;
    }

    .ml_sm--75 {
        margin-left: 75px !important;
    }

    .mr_sm--75 {
        margin-right: 75px !important;
    }

    .mb_sm--75 {
        margin-bottom: 75px !important;
    }

    .ptb_sm--80 {
        padding: 80px 0 !important;
    }

    .plr_sm--80 {
        padding: 0 80px !important;
    }

    .pt_sm--80 {
        padding-top: 80px !important;
    }

    .pb_sm--80 {
        padding-bottom: 80px !important;
    }

    .pl_sm--80 {
        padding-left: 80px !important;
    }

    .pr_sm--80 {
        padding-right: 80px !important;
    }

    .mt_sm--80 {
        margin-top: 80px !important;
    }

    .ml_sm--80 {
        margin-left: 80px !important;
    }

    .mr_sm--80 {
        margin-right: 80px !important;
    }

    .mb_sm--80 {
        margin-bottom: 80px !important;
    }

    .ptb_sm--85 {
        padding: 85px 0 !important;
    }

    .plr_sm--85 {
        padding: 0 85px !important;
    }

    .pt_sm--85 {
        padding-top: 85px !important;
    }

    .pb_sm--85 {
        padding-bottom: 85px !important;
    }

    .pl_sm--85 {
        padding-left: 85px !important;
    }

    .pr_sm--85 {
        padding-right: 85px !important;
    }

    .mt_sm--85 {
        margin-top: 85px !important;
    }

    .ml_sm--85 {
        margin-left: 85px !important;
    }

    .mr_sm--85 {
        margin-right: 85px !important;
    }

    .mb_sm--85 {
        margin-bottom: 85px !important;
    }

    .ptb_sm--90 {
        padding: 90px 0 !important;
    }

    .plr_sm--90 {
        padding: 0 90px !important;
    }

    .pt_sm--90 {
        padding-top: 90px !important;
    }

    .pb_sm--90 {
        padding-bottom: 90px !important;
    }

    .pl_sm--90 {
        padding-left: 90px !important;
    }

    .pr_sm--90 {
        padding-right: 90px !important;
    }

    .mt_sm--90 {
        margin-top: 90px !important;
    }

    .ml_sm--90 {
        margin-left: 90px !important;
    }

    .mr_sm--90 {
        margin-right: 90px !important;
    }

    .mb_sm--90 {
        margin-bottom: 90px !important;
    }

    .ptb_sm--95 {
        padding: 95px 0 !important;
    }

    .plr_sm--95 {
        padding: 0 95px !important;
    }

    .pt_sm--95 {
        padding-top: 95px !important;
    }

    .pb_sm--95 {
        padding-bottom: 95px !important;
    }

    .pl_sm--95 {
        padding-left: 95px !important;
    }

    .pr_sm--95 {
        padding-right: 95px !important;
    }

    .mt_sm--95 {
        margin-top: 95px !important;
    }

    .ml_sm--95 {
        margin-left: 95px !important;
    }

    .mr_sm--95 {
        margin-right: 95px !important;
    }

    .mb_sm--95 {
        margin-bottom: 95px !important;
    }

    .ptb_sm--100 {
        padding: 100px 0 !important;
    }

    .plr_sm--100 {
        padding: 0 100px !important;
    }

    .pt_sm--100 {
        padding-top: 100px !important;
    }

    .pb_sm--100 {
        padding-bottom: 100px !important;
    }

    .pl_sm--100 {
        padding-left: 100px !important;
    }

    .pr_sm--100 {
        padding-right: 100px !important;
    }

    .mt_sm--100 {
        margin-top: 100px !important;
    }

    .ml_sm--100 {
        margin-left: 100px !important;
    }

    .mr_sm--100 {
        margin-right: 100px !important;
    }

    .mb_sm--100 {
        margin-bottom: 100px !important;
    }

    .pl_sm--0 {
        padding-left: 0 !important;
    }

    .pr_sm--0 {
        padding-right: 0 !important;
    }

    .pt_sm--0 {
        padding-top: 0 !important;
    }

    .pb_sm--0 {
        padding-bottom: 0 !important;
    }

    .mr_sm--0 {
        margin-right: 0 !important;
    }

    .ml_sm--0 {
        margin-left: 0 !important;
    }

    .mt_sm--0 {
        margin-top: 0 !important;
    }

    .mb_sm--0 {
        margin-bottom: 0 !important;
    }
}

@media only screen and (max-width: 575px) {
    .ptb_mobile--5 {
        padding: 5px 0 !important;
    }

    .plr_mobile--5 {
        padding: 0 5px !important;
    }

    .pt_mobile--5 {
        padding-top: 5px !important;
    }

    .pb_mobile--5 {
        padding-bottom: 5px !important;
    }

    .pl_mobile--5 {
        padding-left: 5px !important;
    }

    .pr_mobile--5 {
        padding-right: 5px !important;
    }

    .mt_mobile--5 {
        margin-top: 5px !important;
    }

    .mb_mobile--5 {
        margin-bottom: 5px !important;
    }

    .ptb_mobile--10 {
        padding: 10px 0 !important;
    }

    .plr_mobile--10 {
        padding: 0 10px !important;
    }

    .pt_mobile--10 {
        padding-top: 10px !important;
    }

    .pb_mobile--10 {
        padding-bottom: 10px !important;
    }

    .pl_mobile--10 {
        padding-left: 10px !important;
    }

    .pr_mobile--10 {
        padding-right: 10px !important;
    }

    .mt_mobile--10 {
        margin-top: 10px !important;
    }

    .mb_mobile--10 {
        margin-bottom: 10px !important;
    }

    .ptb_mobile--15 {
        padding: 15px 0 !important;
    }

    .plr_mobile--15 {
        padding: 0 15px !important;
    }

    .pt_mobile--15 {
        padding-top: 15px !important;
    }

    .pb_mobile--15 {
        padding-bottom: 15px !important;
    }

    .pl_mobile--15 {
        padding-left: 15px !important;
    }

    .pr_mobile--15 {
        padding-right: 15px !important;
    }

    .mt_mobile--15 {
        margin-top: 15px !important;
    }

    .mb_mobile--15 {
        margin-bottom: 15px !important;
    }

    .ptb_mobile--20 {
        padding: 20px 0 !important;
    }

    .plr_mobile--20 {
        padding: 0 20px !important;
    }

    .pt_mobile--20 {
        padding-top: 20px !important;
    }

    .pb_mobile--20 {
        padding-bottom: 20px !important;
    }

    .pl_mobile--20 {
        padding-left: 20px !important;
    }

    .pr_mobile--20 {
        padding-right: 20px !important;
    }

    .mt_mobile--20 {
        margin-top: 20px !important;
    }

    .mb_mobile--20 {
        margin-bottom: 20px !important;
    }

    .ptb_mobile--25 {
        padding: 25px 0 !important;
    }

    .plr_mobile--25 {
        padding: 0 25px !important;
    }

    .pt_mobile--25 {
        padding-top: 25px !important;
    }

    .pb_mobile--25 {
        padding-bottom: 25px !important;
    }

    .pl_mobile--25 {
        padding-left: 25px !important;
    }

    .pr_mobile--25 {
        padding-right: 25px !important;
    }

    .mt_mobile--25 {
        margin-top: 25px !important;
    }

    .mb_mobile--25 {
        margin-bottom: 25px !important;
    }

    .ptb_mobile--30 {
        padding: 30px 0 !important;
    }

    .plr_mobile--30 {
        padding: 0 30px !important;
    }

    .pt_mobile--30 {
        padding-top: 30px !important;
    }

    .pb_mobile--30 {
        padding-bottom: 30px !important;
    }

    .pl_mobile--30 {
        padding-left: 30px !important;
    }

    .pr_mobile--30 {
        padding-right: 30px !important;
    }

    .mt_mobile--30 {
        margin-top: 30px !important;
    }

    .mb_mobile--30 {
        margin-bottom: 30px !important;
    }

    .ptb_mobile--35 {
        padding: 35px 0 !important;
    }

    .plr_mobile--35 {
        padding: 0 35px !important;
    }

    .pt_mobile--35 {
        padding-top: 35px !important;
    }

    .pb_mobile--35 {
        padding-bottom: 35px !important;
    }

    .pl_mobile--35 {
        padding-left: 35px !important;
    }

    .pr_mobile--35 {
        padding-right: 35px !important;
    }

    .mt_mobile--35 {
        margin-top: 35px !important;
    }

    .mb_mobile--35 {
        margin-bottom: 35px !important;
    }

    .ptb_mobile--40 {
        padding: 40px 0 !important;
    }

    .plr_mobile--40 {
        padding: 0 40px !important;
    }

    .pt_mobile--40 {
        padding-top: 40px !important;
    }

    .pb_mobile--40 {
        padding-bottom: 40px !important;
    }

    .pl_mobile--40 {
        padding-left: 40px !important;
    }

    .pr_mobile--40 {
        padding-right: 40px !important;
    }

    .mt_mobile--40 {
        margin-top: 40px !important;
    }

    .mb_mobile--40 {
        margin-bottom: 40px !important;
    }

    .ptb_mobile--45 {
        padding: 45px 0 !important;
    }

    .plr_mobile--45 {
        padding: 0 45px !important;
    }

    .pt_mobile--45 {
        padding-top: 45px !important;
    }

    .pb_mobile--45 {
        padding-bottom: 45px !important;
    }

    .pl_mobile--45 {
        padding-left: 45px !important;
    }

    .pr_mobile--45 {
        padding-right: 45px !important;
    }

    .mt_mobile--45 {
        margin-top: 45px !important;
    }

    .mb_mobile--45 {
        margin-bottom: 45px !important;
    }

    .ptb_mobile--50 {
        padding: 50px 0 !important;
    }

    .plr_mobile--50 {
        padding: 0 50px !important;
    }

    .pt_mobile--50 {
        padding-top: 50px !important;
    }

    .pb_mobile--50 {
        padding-bottom: 50px !important;
    }

    .pl_mobile--50 {
        padding-left: 50px !important;
    }

    .pr_mobile--50 {
        padding-right: 50px !important;
    }

    .mt_mobile--50 {
        margin-top: 50px !important;
    }

    .mb_mobile--50 {
        margin-bottom: 50px !important;
    }

    .ptb_mobile--55 {
        padding: 55px 0 !important;
    }

    .plr_mobile--55 {
        padding: 0 55px !important;
    }

    .pt_mobile--55 {
        padding-top: 55px !important;
    }

    .pb_mobile--55 {
        padding-bottom: 55px !important;
    }

    .pl_mobile--55 {
        padding-left: 55px !important;
    }

    .pr_mobile--55 {
        padding-right: 55px !important;
    }

    .mt_mobile--55 {
        margin-top: 55px !important;
    }

    .mb_mobile--55 {
        margin-bottom: 55px !important;
    }

    .ptb_mobile--60 {
        padding: 60px 0 !important;
    }

    .plr_mobile--60 {
        padding: 0 60px !important;
    }

    .pt_mobile--60 {
        padding-top: 60px !important;
    }

    .pb_mobile--60 {
        padding-bottom: 60px !important;
    }

    .pl_mobile--60 {
        padding-left: 60px !important;
    }

    .pr_mobile--60 {
        padding-right: 60px !important;
    }

    .mt_mobile--60 {
        margin-top: 60px !important;
    }

    .mb_mobile--60 {
        margin-bottom: 60px !important;
    }

    .ptb_mobile--65 {
        padding: 65px 0 !important;
    }

    .plr_mobile--65 {
        padding: 0 65px !important;
    }

    .pt_mobile--65 {
        padding-top: 65px !important;
    }

    .pb_mobile--65 {
        padding-bottom: 65px !important;
    }

    .pl_mobile--65 {
        padding-left: 65px !important;
    }

    .pr_mobile--65 {
        padding-right: 65px !important;
    }

    .mt_mobile--65 {
        margin-top: 65px !important;
    }

    .mb_mobile--65 {
        margin-bottom: 65px !important;
    }

    .ptb_mobile--70 {
        padding: 70px 0 !important;
    }

    .plr_mobile--70 {
        padding: 0 70px !important;
    }

    .pt_mobile--70 {
        padding-top: 70px !important;
    }

    .pb_mobile--70 {
        padding-bottom: 70px !important;
    }

    .pl_mobile--70 {
        padding-left: 70px !important;
    }

    .pr_mobile--70 {
        padding-right: 70px !important;
    }

    .mt_mobile--70 {
        margin-top: 70px !important;
    }

    .mb_mobile--70 {
        margin-bottom: 70px !important;
    }

    .ptb_mobile--75 {
        padding: 75px 0 !important;
    }

    .plr_mobile--75 {
        padding: 0 75px !important;
    }

    .pt_mobile--75 {
        padding-top: 75px !important;
    }

    .pb_mobile--75 {
        padding-bottom: 75px !important;
    }

    .pl_mobile--75 {
        padding-left: 75px !important;
    }

    .pr_mobile--75 {
        padding-right: 75px !important;
    }

    .mt_mobile--75 {
        margin-top: 75px !important;
    }

    .mb_mobile--75 {
        margin-bottom: 75px !important;
    }

    .ptb_mobile--80 {
        padding: 80px 0 !important;
    }

    .plr_mobile--80 {
        padding: 0 80px !important;
    }

    .pt_mobile--80 {
        padding-top: 80px !important;
    }

    .pb_mobile--80 {
        padding-bottom: 80px !important;
    }

    .pl_mobile--80 {
        padding-left: 80px !important;
    }

    .pr_mobile--80 {
        padding-right: 80px !important;
    }

    .mt_mobile--80 {
        margin-top: 80px !important;
    }

    .mb_mobile--80 {
        margin-bottom: 80px !important;
    }

    .ptb_mobile--85 {
        padding: 85px 0 !important;
    }

    .plr_mobile--85 {
        padding: 0 85px !important;
    }

    .pt_mobile--85 {
        padding-top: 85px !important;
    }

    .pb_mobile--85 {
        padding-bottom: 85px !important;
    }

    .pl_mobile--85 {
        padding-left: 85px !important;
    }

    .pr_mobile--85 {
        padding-right: 85px !important;
    }

    .mt_mobile--85 {
        margin-top: 85px !important;
    }

    .mb_mobile--85 {
        margin-bottom: 85px !important;
    }

    .ptb_mobile--90 {
        padding: 90px 0 !important;
    }

    .plr_mobile--90 {
        padding: 0 90px !important;
    }

    .pt_mobile--90 {
        padding-top: 90px !important;
    }

    .pb_mobile--90 {
        padding-bottom: 90px !important;
    }

    .pl_mobile--90 {
        padding-left: 90px !important;
    }

    .pr_mobile--90 {
        padding-right: 90px !important;
    }

    .mt_mobile--90 {
        margin-top: 90px !important;
    }

    .mb_mobile--90 {
        margin-bottom: 90px !important;
    }

    .ptb_mobile--95 {
        padding: 95px 0 !important;
    }

    .plr_mobile--95 {
        padding: 0 95px !important;
    }

    .pt_mobile--95 {
        padding-top: 95px !important;
    }

    .pb_mobile--95 {
        padding-bottom: 95px !important;
    }

    .pl_mobile--95 {
        padding-left: 95px !important;
    }

    .pr_mobile--95 {
        padding-right: 95px !important;
    }

    .mt_mobile--95 {
        margin-top: 95px !important;
    }

    .mb_mobile--95 {
        margin-bottom: 95px !important;
    }

    .ptb_mobile--100 {
        padding: 100px 0 !important;
    }

    .plr_mobile--100 {
        padding: 0 100px !important;
    }

    .pt_mobile--100 {
        padding-top: 100px !important;
    }

    .pb_mobile--100 {
        padding-bottom: 100px !important;
    }

    .pl_mobile--100 {
        padding-left: 100px !important;
    }

    .pr_mobile--100 {
        padding-right: 100px !important;
    }

    .mt_mobile--100 {
        margin-top: 100px !important;
    }

    .mb_mobile--100 {
        margin-bottom: 100px !important;
    }
}

.slick-gutter-5 {
    margin-left: -5px !important;
    margin-right: -5px !important;
}

.slick-gutter-5 .slick-slide {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.slick-gutter-10 {
    margin-left: -10px !important;
    margin-right: -10px !important;
}

.slick-gutter-10 .slick-slide {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.slick-gutter-15 {
    margin-left: -15px !important;
    margin-right: -15px !important;
}

.slick-gutter-15 .slick-slide {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.slick-gutter-20 {
    margin-left: -20px !important;
    margin-right: -20px !important;
}

.slick-gutter-20 .slick-slide {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.slick-gutter-25 {
    margin-left: -25px !important;
    margin-right: -25px !important;
}

.slick-gutter-25 .slick-slide {
    padding-left: 25px !important;
    padding-right: 25px !important;
}

.slick-gutter-30 {
    margin-left: -30px !important;
    margin-right: -30px !important;
}

.slick-gutter-30 .slick-slide {
    padding-left: 30px !important;
    padding-right: 30px !important;
}

.slick-gutter-35 {
    margin-left: -35px !important;
    margin-right: -35px !important;
}

.slick-gutter-35 .slick-slide {
    padding-left: 35px !important;
    padding-right: 35px !important;
}

.slick-gutter-40 {
    margin-left: -40px !important;
    margin-right: -40px !important;
}

.slick-gutter-40 .slick-slide {
    padding-left: 40px !important;
    padding-right: 40px !important;
}

.slick-gutter-45 {
    margin-left: -45px !important;
    margin-right: -45px !important;
}

.slick-gutter-45 .slick-slide {
    padding-left: 45px !important;
    padding-right: 45px !important;
}

.slick-gutter-50 {
    margin-left: -50px !important;
    margin-right: -50px !important;
}

.slick-gutter-50 .slick-slide {
    padding-left: 50px !important;
    padding-right: 50px !important;
}

.slick-gutter-55 {
    margin-left: -55px !important;
    margin-right: -55px !important;
}

.slick-gutter-55 .slick-slide {
    padding-left: 55px !important;
    padding-right: 55px !important;
}

.slick-gutter-60 {
    margin-left: -60px !important;
    margin-right: -60px !important;
}

.slick-gutter-60 .slick-slide {
    padding-left: 60px !important;
    padding-right: 60px !important;
}

.slick-gutter-65 {
    margin-left: -65px !important;
    margin-right: -65px !important;
}

.slick-gutter-65 .slick-slide {
    padding-left: 65px !important;
    padding-right: 65px !important;
}

.slick-gutter-70 {
    margin-left: -70px !important;
    margin-right: -70px !important;
}

.slick-gutter-70 .slick-slide {
    padding-left: 70px !important;
    padding-right: 70px !important;
}

.slick-gutter-75 {
    margin-left: -75px !important;
    margin-right: -75px !important;
}

.slick-gutter-75 .slick-slide {
    padding-left: 75px !important;
    padding-right: 75px !important;
}

.slick-gutter-80 {
    margin-left: -80px !important;
    margin-right: -80px !important;
}

.slick-gutter-80 .slick-slide {
    padding-left: 80px !important;
    padding-right: 80px !important;
}

.slick-gutter-85 {
    margin-left: -85px !important;
    margin-right: -85px !important;
}

.slick-gutter-85 .slick-slide {
    padding-left: 85px !important;
    padding-right: 85px !important;
}

.slick-gutter-90 {
    margin-left: -90px !important;
    margin-right: -90px !important;
}

.slick-gutter-90 .slick-slide {
    padding-left: 90px !important;
    padding-right: 90px !important;
}

.slick-gutter-95 {
    margin-left: -95px !important;
    margin-right: -95px !important;
}

.slick-gutter-95 .slick-slide {
    padding-left: 95px !important;
    padding-right: 95px !important;
}

.slick-gutter-100 {
    margin-left: -100px !important;
    margin-right: -100px !important;
}

.slick-gutter-100 .slick-slide {
    padding-left: 100px !important;
    padding-right: 100px !important;
}

.max-width-160 {
    max-width: 160px;
}

.collaborate-plr-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
}

/*======================== 
    Common Style Css
==========================*/

/* Theme Gradient */

.theme-gradient,
.counter-style-1 .count-number {
    background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 98%/200% 100%;
    text-transform: capitalize;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bg-lessdark-gradient {
    background: linear-gradient(180deg, var(--color-lessdark) 20%, rgba(19, 19, 19, 0.4));
}

.bg-theme-gradient {
    background-color: transparent;
    background-image: linear-gradient(90deg, var(--color-primary-lightest) 10%, var(--color-primary-alt) 50%, var(--color-tertiary-alt) 90%);
}

.bg-primary-gradient {
    background-color: var(--color-primary);
    background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95%/200% 100%;
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {

    .theme-gradient,
    .counter-style-1 .count-number {
        color: var(--color-secondary);
        background: none !important;
    }
}

/* Heading Font  */

.h1 {
    font-size: var(--h1) !important;
}

.h2 {
    font-size: var(--h2) !important;
}

.h3 {
    font-size: var(--h3) !important;
}

.h4 {
    font-size: var(--h4) !important;
}

.h5 {
    font-size: var(--h5) !important;
}

.h6 {
    font-size: var(--h6) !important;
}

/* Text Color  */

.bg-primary-color {
    background: var(--color-primary);
}

.bg-secondary-color {
    background: var(--color-secondary);
}

.bg-tertiary-color {
    background-color: var(--color-tertiary);
}

.bg-color-primary {
    background-color: var(--color-primary) !important;
}

.bg-color-primary-light {
    background-color: var(--color-primary-light) !important;
}

.bg-color-primary-alt {
    background-color: var(--color-primary-alt) !important;
}

.bg-color-secondary {
    background-color: var(--color-secondary) !important;
}

.bg-color-tertiary {
    background-color: var(--color-tertiary) !important;
}

.bg-color-tertiary-alt {
    background-color: var(--color-tertiary-alt) !important;
}

.bg-color-light-green {
    background-color: var(--color-light-green) !important;
}

.bg-color-pink {
    background-color: var(--color-pink) !important;
}

.bg-color-primary-darker {
    background-color: var(--color-primary-darker) !important;
}

.bg-color-secondary-darker {
    background-color: var(--color-secondary-darker) !important;
}

.bg-color-light-green-darker {
    background-color: var(--color-light-green-darker) !important;
}

.bg-color-pink-darker {
    background-color: var(--color-pink-darker) !important;
}

.bg-color-heading {
    background-color: var(--color-heading) !important;
}

.bg-color-body {
    background-color: var(--color-body) !important;
}

.bg-color-dark {
    background-color: var(--color-dark) !important;
}

.bg-color-darker {
    background-color: var(--color-darker) !important;
}

.bg-color-darkest {
    background-color: var(--color-darkest) !important;
}

.bg-color-black {
    background-color: var(--color-black) !important;
}

.bg-color-blacker {
    background-color: var(--color-blacker) !important;
}

.bg-color-blackest {
    background-color: var(--color-blackest) !important;
}

.bg-color-border {
    background-color: var(--color-border) !important;
}

.bg-color-gray {
    background-color: var(--color-gray) !important;
}

.bg-color-midgray {
    background-color: var(--color-midgray) !important;
}

.bg-color-light {
    background-color: var(--color-light) !important;
}

.bg-color-lighter {
    background-color: var(--color-lighter) !important;
}

.bg-color-lightest {
    background-color: var(--color-lightest) !important;
}

.bg-color-white {
    background-color: var(--color-white) !important;
}

/* Radius */

/* Font Weight */

/* Shadows */

/* Others  */

.liststyle {
    padding: 0;
    margin: 0;
    list-style: none;
}

.transition-transform {
    transition: var(--transition-transform);
}

.list-icon {
    list-style: none;
    padding: 0;
    margin: 0;
}

.list-icon li {
    display: flex;
    align-items: center;
    margin: 15px 0;
}

@media only screen and (max-width: 767px) {
    .list-icon li {
        font-size: 16px;
    }
}

.list-icon li .icon {
    width: 30px;
    background-color: var(--color-blackest);
    height: 30px;
    border-radius: 100%;
    display: inline-block;
    position: relative;
    margin-right: 9px;
    min-width: 30px;
    min-height: 30px;
}

.list-icon li .icon.background-transparent {
    background-color: transparent;
}

.list-icon li .icon i {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.rainbow-sub-badge {
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 18px;
    font-size: 16px;
    border-radius: 4px;
    margin-bottom: 12px;
    display: inline-block;
    padding: 7px 14px;
    background-color: var(--color-blackest);
    box-shadow: var(--shadow-lighter);
}

.max-width-800 {
    max-width: 800px;
}

/* ========= Forms Styles ========= */

button,
[type=button],
[type=reset],
[type=submit] {
    -webkit-appearance: button;
}

input,
select,
textarea {
    width: 100%;
    transition: 0.3s;
}

input {
    height: 40px;
    padding: 0 15px;
}

select {
    transition: 0.3s;
    height: 60px;
    line-height: 28px;
    padding: 10px 20px;
    outline: none;
    color: var(--color-body);
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: var(--border-width) solid var(--color-border);
    border-radius: var(--radius);
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    padding-right: 30px;
}

input[type=text],
input[type=password],
input[type=email],
input[type=number],
input[type=tel],
textarea {
    font-size: 16px;
    font-weight: 400;
    height: auto;
    line-height: 28px;
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0 15px;
    outline: none;
    border: var(--border-width) solid var(--color-border);
    border-radius: var(--radius);
    color: var(--color-body);
    /* -- Placeholder -- */
}

input[type=text]::placeholder,
input[type=password]::placeholder,
input[type=email]::placeholder,
input[type=number]::placeholder,
input[type=tel]::placeholder,
textarea::placeholder {
    color: var(--body-color);
    /* Firefox */
    opacity: 1;
}

input[type=text]:-ms-input-placeholder,
input[type=password]:-ms-input-placeholder,
input[type=email]:-ms-input-placeholder,
input[type=number]:-ms-input-placeholder,
input[type=tel]:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--body-color);
    opacity: 1;
}

input[type=text]::-ms-input-placeholder,
input[type=password]::-ms-input-placeholder,
input[type=email]::-ms-input-placeholder,
input[type=number]::-ms-input-placeholder,
input[type=tel]::-ms-input-placeholder,
textarea::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--body-color);
    opacity: 1;
}

input[type=text].p-holder__active,
.input-active input[type=text],
input[type=text].input-active,
input[type=password].p-holder__active,
.input-active input[type=password],
input[type=password].input-active,
input[type=email].p-holder__active,
.input-active input[type=email],
input[type=email].input-active,
input[type=number].p-holder__active,
.input-active input[type=number],
input[type=number].input-active,
input[type=tel].p-holder__active,
.input-active input[type=tel],
input[type=tel].input-active,
textarea.p-holder__active,
textarea.input-active {
    border-color: var(--color-primary);
    /* -- Placeholder -- */
}

input[type=text].p-holder__active::placeholder,
.input-active input[type=text]::placeholder,
input[type=text].input-active::placeholder,
input[type=password].p-holder__active::placeholder,
.input-active input[type=password]::placeholder,
input[type=password].input-active::placeholder,
input[type=email].p-holder__active::placeholder,
.input-active input[type=email]::placeholder,
input[type=email].input-active::placeholder,
input[type=number].p-holder__active::placeholder,
.input-active input[type=number]::placeholder,
input[type=number].input-active::placeholder,
input[type=tel].p-holder__active::placeholder,
.input-active input[type=tel]::placeholder,
input[type=tel].input-active::placeholder,
textarea.p-holder__active::placeholder,
textarea.input-active::placeholder {
    color: var(--color-primary);
    /* Firefox */
    opacity: 1;
}

input[type=text].p-holder__active:-ms-input-placeholder,
.input-active input[type=text]:-ms-input-placeholder,
input[type=text].input-active:-ms-input-placeholder,
input[type=password].p-holder__active:-ms-input-placeholder,
.input-active input[type=password]:-ms-input-placeholder,
input[type=password].input-active:-ms-input-placeholder,
input[type=email].p-holder__active:-ms-input-placeholder,
.input-active input[type=email]:-ms-input-placeholder,
input[type=email].input-active:-ms-input-placeholder,
input[type=number].p-holder__active:-ms-input-placeholder,
.input-active input[type=number]:-ms-input-placeholder,
input[type=number].input-active:-ms-input-placeholder,
input[type=tel].p-holder__active:-ms-input-placeholder,
.input-active input[type=tel]:-ms-input-placeholder,
input[type=tel].input-active:-ms-input-placeholder,
textarea.p-holder__active:-ms-input-placeholder,
textarea.input-active:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--color-primary);
}

input[type=text].p-holder__active::-ms-input-placeholder,
.input-active input[type=text]::-ms-input-placeholder,
input[type=text].input-active::-ms-input-placeholder,
input[type=password].p-holder__active::-ms-input-placeholder,
.input-active input[type=password]::-ms-input-placeholder,
input[type=password].input-active::-ms-input-placeholder,
input[type=email].p-holder__active::-ms-input-placeholder,
.input-active input[type=email]::-ms-input-placeholder,
input[type=email].input-active::-ms-input-placeholder,
input[type=number].p-holder__active::-ms-input-placeholder,
.input-active input[type=number]::-ms-input-placeholder,
input[type=number].input-active::-ms-input-placeholder,
input[type=tel].p-holder__active::-ms-input-placeholder,
.input-active input[type=tel]::-ms-input-placeholder,
input[type=tel].input-active::-ms-input-placeholder,
textarea.p-holder__active::-ms-input-placeholder,
textarea.input-active::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--color-primary);
}

input[type=text].p-holder__error,
.input-error input[type=text],
input[type=text].input-error,
input[type=password].p-holder__error,
.input-error input[type=password],
input[type=password].input-error,
input[type=email].p-holder__error,
.input-error input[type=email],
input[type=email].input-error,
input[type=number].p-holder__error,
.input-error input[type=number],
input[type=number].input-error,
input[type=tel].p-holder__error,
.input-error input[type=tel],
input[type=tel].input-error,
textarea.p-holder__error,
textarea.input-error {
    border-color: #f4282d;
    /* -- Placeholder -- */
}

input[type=text].p-holder__error::placeholder,
.input-error input[type=text]::placeholder,
input[type=text].input-error::placeholder,
input[type=password].p-holder__error::placeholder,
.input-error input[type=password]::placeholder,
input[type=password].input-error::placeholder,
input[type=email].p-holder__error::placeholder,
.input-error input[type=email]::placeholder,
input[type=email].input-error::placeholder,
input[type=number].p-holder__error::placeholder,
.input-error input[type=number]::placeholder,
input[type=number].input-error::placeholder,
input[type=tel].p-holder__error::placeholder,
.input-error input[type=tel]::placeholder,
input[type=tel].input-error::placeholder,
textarea.p-holder__error::placeholder,
textarea.input-error::placeholder {
    color: #f4282d;
    /* Firefox */
    opacity: 1;
}

input[type=text].p-holder__error:-ms-input-placeholder,
.input-error input[type=text]:-ms-input-placeholder,
input[type=text].input-error:-ms-input-placeholder,
input[type=password].p-holder__error:-ms-input-placeholder,
.input-error input[type=password]:-ms-input-placeholder,
input[type=password].input-error:-ms-input-placeholder,
input[type=email].p-holder__error:-ms-input-placeholder,
.input-error input[type=email]:-ms-input-placeholder,
input[type=email].input-error:-ms-input-placeholder,
input[type=number].p-holder__error:-ms-input-placeholder,
.input-error input[type=number]:-ms-input-placeholder,
input[type=number].input-error:-ms-input-placeholder,
input[type=tel].p-holder__error:-ms-input-placeholder,
.input-error input[type=tel]:-ms-input-placeholder,
input[type=tel].input-error:-ms-input-placeholder,
textarea.p-holder__error:-ms-input-placeholder,
textarea.input-error:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #f4282d;
}

input[type=text].p-holder__error::-ms-input-placeholder,
.input-error input[type=text]::-ms-input-placeholder,
input[type=text].input-error::-ms-input-placeholder,
input[type=password].p-holder__error::-ms-input-placeholder,
.input-error input[type=password]::-ms-input-placeholder,
input[type=password].input-error::-ms-input-placeholder,
input[type=email].p-holder__error::-ms-input-placeholder,
.input-error input[type=email]::-ms-input-placeholder,
input[type=email].input-error::-ms-input-placeholder,
input[type=number].p-holder__error::-ms-input-placeholder,
.input-error input[type=number]::-ms-input-placeholder,
input[type=number].input-error::-ms-input-placeholder,
input[type=tel].p-holder__error::-ms-input-placeholder,
.input-error input[type=tel]::-ms-input-placeholder,
input[type=tel].input-error::-ms-input-placeholder,
textarea.p-holder__error::-ms-input-placeholder,
textarea.input-error::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #f4282d;
}

input[type=text].p-holder__error:focus,
.input-error input[type=text]:focus,
input[type=text].input-error:focus,
input[type=password].p-holder__error:focus,
.input-error input[type=password]:focus,
input[type=password].input-error:focus,
input[type=email].p-holder__error:focus,
.input-error input[type=email]:focus,
input[type=email].input-error:focus,
input[type=number].p-holder__error:focus,
.input-error input[type=number]:focus,
input[type=number].input-error:focus,
input[type=tel].p-holder__error:focus,
.input-error input[type=tel]:focus,
input[type=tel].input-error:focus,
textarea.p-holder__error:focus,
textarea.input-error:focus {
    border-color: #f4282d;
}

input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
input[type=tel]:focus,
textarea:focus {
    border-color: var(--color-primary);
}

input[type=checkbox],
input[type=radio] {
    opacity: 0;
    position: absolute;
}

input[type=checkbox]~label,
input[type=radio]~label {
    position: relative;
    font-size: 15px;
    line-height: 25px;
    color: var(--body-color);
    font-weight: 400;
    padding-left: 20px;
    cursor: pointer;
    margin-bottom: 0;
}

input[type=checkbox]~label::before,
input[type=radio]~label::before {
    content: " ";
    position: absolute;
    top: 6px;
    left: 0;
    width: 14px;
    height: 14px;
    background-color: #fff;
    border: var(--border-width) solid rgba(24, 152, 165, 0.2);
    border-radius: 2px;
    transition: all 0.3s;
}

input[type=checkbox]~label::after,
input[type=radio]~label::after {
    content: " ";
    position: absolute;
    top: 9px;
    left: 2px;
    width: 10px;
    height: 5px;
    background-color: transparent;
    border-bottom: var(--border-width) solid #fff;
    border-left: var(--border-width) solid #fff;
    border-radius: 2px;
    transform: rotate(-45deg);
    opacity: 0;
    transition: all 0.3s;
}

input[type=checkbox]:checked~label::before,
input[type=radio]:checked~label::before {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
}

input[type=checkbox]:checked~label::after,
input[type=radio]:checked~label::after {
    opacity: 1;
}

input[type=radio]~label::before {
    border-radius: 50%;
}

input[type=radio]~label::after {
    width: 8px;
    height: 8px;
    left: 3px;
    background: #fff;
    border-radius: 50%;
}

.form-group {
    margin-bottom: 20px;
}

.form-group input {
    border: var(--border-width) solid var(--color-border);
    border-radius: var(--radius);
    height: 50px;
    font-size: 16px;
    padding: 0 15px;
}

.form-group input:focus {
    border-color: var(--color-primary);
}

.form-group textarea {
    min-height: 193px;
    border: var(--border-width) solid var(--color-border);
    border-radius: var(--radius);
    resize: none;
    padding: 15px;
    font-size: 16px;
}

.form-group textarea:focus {
    border-color: var(--color-primary);
}

input[type=submit] {
    width: auto;
    font-size: 16px;
    letter-spacing: 2px;
    padding: 15px 20px;
    border-radius: 6px;
    display: block;
    font-weight: 500;
    transition: 0.3s;
    border: var(--border-width) solid var(--color-primary);
    background: var(--color-primary);
    color: #ffffff;
    height: 60px;
    margin-top: 30px;
}

input[type=submit]:hover {
    color: #ffffff;
    -webkit-box-shadow: 0 10px 15px 0 rgba(249, 0, 77, 0.1);
    box-shadow: 0 10px 15px 0 rgba(249, 0, 77, 0.1);
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
}

.contact-form--1 form input::-webkit-input-placeholder,
.contact-form--1 form textarea::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--color-body) !important;
}

.contact-form--1 form input::-moz-placeholder,
.contact-form--1 form textarea::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--color-body) !important;
}

.contact-form--1 form input:-ms-input-placeholder,
.contact-form--1 form textarea:-ms-input-placeholder {
    /* IE 10+ */
    color: var(--color-body) !important;
}

.contact-form--1 form input:-moz-placeholder,
.contact-form--1 form textarea:-moz-placeholder {
    /* Firefox 18- */
    color: var(--color-body) !important;
}

/**
 * Settings
 */

/**
  * Easings
  */

/**
  * Core
  */

[data-sal] {
    transition-duration: 0.2s;
    transition-delay: 0s;
    transition-duration: var(--sal-duration, 0.2s);
    transition-delay: var(--sal-delay, 0s);
    transition-timing-function: var(--sal-easing, ease);
}

[data-sal][data-sal-duration="200"] {
    transition-duration: 0.2s;
}

[data-sal][data-sal-duration="250"] {
    transition-duration: 0.25s;
}

[data-sal][data-sal-duration="300"] {
    transition-duration: 0.3s;
}

[data-sal][data-sal-duration="350"] {
    transition-duration: 0.35s;
}

[data-sal][data-sal-duration="400"] {
    transition-duration: 0.4s;
}

[data-sal][data-sal-duration="450"] {
    transition-duration: 0.45s;
}

[data-sal][data-sal-duration="500"] {
    transition-duration: 0.5s;
}

[data-sal][data-sal-duration="550"] {
    transition-duration: 0.55s;
}

[data-sal][data-sal-duration="600"] {
    transition-duration: 0.6s;
}

[data-sal][data-sal-duration="650"] {
    transition-duration: 0.65s;
}

[data-sal][data-sal-duration="700"] {
    transition-duration: 0.7s;
}

[data-sal][data-sal-duration="750"] {
    transition-duration: 0.75s;
}

[data-sal][data-sal-duration="800"] {
    transition-duration: 0.8s;
}

[data-sal][data-sal-duration="850"] {
    transition-duration: 0.85s;
}

[data-sal][data-sal-duration="900"] {
    transition-duration: 0.9s;
}

[data-sal][data-sal-duration="950"] {
    transition-duration: 0.95s;
}

[data-sal][data-sal-duration="1000"] {
    transition-duration: 1s;
}

[data-sal][data-sal-duration="1050"] {
    transition-duration: 1.05s;
}

[data-sal][data-sal-duration="1100"] {
    transition-duration: 1.1s;
}

[data-sal][data-sal-duration="1150"] {
    transition-duration: 1.15s;
}

[data-sal][data-sal-duration="1200"] {
    transition-duration: 1.2s;
}

[data-sal][data-sal-duration="1250"] {
    transition-duration: 1.25s;
}

[data-sal][data-sal-duration="1300"] {
    transition-duration: 1.3s;
}

[data-sal][data-sal-duration="1350"] {
    transition-duration: 1.35s;
}

[data-sal][data-sal-duration="1400"] {
    transition-duration: 1.4s;
}

[data-sal][data-sal-duration="1450"] {
    transition-duration: 1.45s;
}

[data-sal][data-sal-duration="1500"] {
    transition-duration: 1.5s;
}

[data-sal][data-sal-duration="1550"] {
    transition-duration: 1.55s;
}

[data-sal][data-sal-duration="1600"] {
    transition-duration: 1.6s;
}

[data-sal][data-sal-duration="1650"] {
    transition-duration: 1.65s;
}

[data-sal][data-sal-duration="1700"] {
    transition-duration: 1.7s;
}

[data-sal][data-sal-duration="1750"] {
    transition-duration: 1.75s;
}

[data-sal][data-sal-duration="1800"] {
    transition-duration: 1.8s;
}

[data-sal][data-sal-duration="1850"] {
    transition-duration: 1.85s;
}

[data-sal][data-sal-duration="1900"] {
    transition-duration: 1.9s;
}

[data-sal][data-sal-duration="1950"] {
    transition-duration: 1.95s;
}

[data-sal][data-sal-duration="2000"] {
    transition-duration: 2s;
}

[data-sal][data-sal-delay="50"] {
    transition-delay: 0.05s;
}

[data-sal][data-sal-delay="100"] {
    transition-delay: 0.1s;
}

[data-sal][data-sal-delay="150"] {
    transition-delay: 0.15s;
}

[data-sal][data-sal-delay="200"] {
    transition-delay: 0.2s;
}

[data-sal][data-sal-delay="250"] {
    transition-delay: 0.25s;
}

[data-sal][data-sal-delay="300"] {
    transition-delay: 0.3s;
}

[data-sal][data-sal-delay="350"] {
    transition-delay: 0.35s;
}

[data-sal][data-sal-delay="400"] {
    transition-delay: 0.4s;
}

[data-sal][data-sal-delay="450"] {
    transition-delay: 0.45s;
}

[data-sal][data-sal-delay="500"] {
    transition-delay: 0.5s;
}

[data-sal][data-sal-delay="550"] {
    transition-delay: 0.55s;
}

[data-sal][data-sal-delay="600"] {
    transition-delay: 0.6s;
}

[data-sal][data-sal-delay="650"] {
    transition-delay: 0.65s;
}

[data-sal][data-sal-delay="700"] {
    transition-delay: 0.7s;
}

[data-sal][data-sal-delay="750"] {
    transition-delay: 0.75s;
}

[data-sal][data-sal-delay="800"] {
    transition-delay: 0.8s;
}

[data-sal][data-sal-delay="850"] {
    transition-delay: 0.85s;
}

[data-sal][data-sal-delay="900"] {
    transition-delay: 0.9s;
}

[data-sal][data-sal-delay="950"] {
    transition-delay: 0.95s;
}

[data-sal][data-sal-delay="1000"] {
    transition-delay: 1s;
}

[data-sal][data-sal-easing=linear] {
    transition-timing-function: linear;
}

[data-sal][data-sal-easing=ease] {
    transition-timing-function: ease;
}

[data-sal][data-sal-easing=ease-in] {
    transition-timing-function: ease-in;
}

[data-sal][data-sal-easing=ease-out] {
    transition-timing-function: ease-out;
}

[data-sal][data-sal-easing=ease-in-out] {
    transition-timing-function: ease-in-out;
}

[data-sal][data-sal-easing=ease-in-cubic] {
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

[data-sal][data-sal-easing=ease-out-cubic] {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

[data-sal][data-sal-easing=ease-in-out-cubic] {
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

[data-sal][data-sal-easing=ease-in-circ] {
    transition-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.335);
}

[data-sal][data-sal-easing=ease-out-circ] {
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

[data-sal][data-sal-easing=ease-in-out-circ] {
    transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

[data-sal][data-sal-easing=ease-in-expo] {
    transition-timing-function: cubic-bezier(0.95, 0.05, 0.795, 0.035);
}

[data-sal][data-sal-easing=ease-out-expo] {
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

[data-sal][data-sal-easing=ease-in-out-expo] {
    transition-timing-function: cubic-bezier(1, 0, 0, 1);
}

[data-sal][data-sal-easing=ease-in-quad] {
    transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-sal][data-sal-easing=ease-out-quad] {
    transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-sal][data-sal-easing=ease-in-out-quad] {
    transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-sal][data-sal-easing=ease-in-quart] {
    transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

[data-sal][data-sal-easing=ease-out-quart] {
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

[data-sal][data-sal-easing=ease-in-out-quart] {
    transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}

[data-sal][data-sal-easing=ease-in-quint] {
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

[data-sal][data-sal-easing=ease-out-quint] {
    transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

[data-sal][data-sal-easing=ease-in-out-quint] {
    transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}

[data-sal][data-sal-easing=ease-in-sine] {
    transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}

[data-sal][data-sal-easing=ease-out-sine] {
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

[data-sal][data-sal-easing=ease-in-out-sine] {
    transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

[data-sal][data-sal-easing=ease-in-back] {
    transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

[data-sal][data-sal-easing=ease-out-back] {
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

[data-sal][data-sal-easing=ease-in-out-back] {
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

/**
  * Animations
  */

[data-sal|=fade] {
    opacity: 0;
    transition-property: opacity;
}

[data-sal|=fade].sal-animate,
body.sal-disabled [data-sal|=fade] {
    opacity: 1;
}

[data-sal|=slide] {
    opacity: 0;
    transition-property: opacity, transform;
}

[data-sal=slide-up] {
    transform: translateY(20%);
}

[data-sal=slide-down] {
    transform: translateY(-20%);
}

[data-sal=slide-left] {
    transform: translateX(20%);
}

[data-sal=slide-right] {
    transform: translateX(-20%);
}

[data-sal|=slide].sal-animate,
body.sal-disabled [data-sal|=slide] {
    opacity: 1;
    transform: none;
}

[data-sal|=zoom] {
    opacity: 0;
    transition-property: opacity, transform;
}

[data-sal=zoom-in] {
    transform: scale(0.5);
}

[data-sal=zoom-out] {
    transform: scale(1.1);
}

[data-sal|=zoom].sal-animate,
body.sal-disabled [data-sal|=zoom] {
    opacity: 1;
    transform: none;
}

[data-sal|=flip] {
    backface-visibility: hidden;
    transition-property: transform;
}

[data-sal=flip-left] {
    transform: perspective(2000px) rotateY(-91deg);
}

[data-sal=flip-right] {
    transform: perspective(2000px) rotateY(91deg);
}

[data-sal=flip-up] {
    transform: perspective(2000px) rotateX(-91deg);
}

[data-sal=flip-down] {
    transform: perspective(2000px) rotateX(91deg);
}

[data-sal|=flip].sal-animate,
body.sal-disabled [data-sal|=flip] {
    transform: none;
}

.cd-intro {
    margin: 4em auto;
}

@media only screen and (min-width: 768px) {
    .cd-intro {
        margin: 5em auto;
    }
}

@media only screen and (min-width: 1170px) {
    .cd-intro {
        margin: 6em auto;
    }
}

.cd-headline {
    font-size: 3rem;
    line-height: 1.2;
}

@media only screen and (min-width: 768px) {
    .cd-headline {
        font-size: 4.4rem;
        font-weight: 300;
    }
}

@media only screen and (min-width: 1170px) {
    .cd-headline {
        font-size: 48px;
    }
}

@media only screen and (max-width: 768px) {
    .cd-headline {
        font-size: 40px;
    }
}

@media only screen and (max-width: 479px) {
    .cd-headline {
        font-size: 26px;
    }
}

.cd-words-wrapper {
    display: inline-block;
    position: relative;
    text-align: left;
}

.cd-words-wrapper b {
    display: inline-block;
    position: absolute;
    white-space: nowrap;
    left: 0;
    top: 0;
}

.cd-words-wrapper b.is-visible {
    position: relative;
}

.no-js .cd-words-wrapper b {
    opacity: 0;
}

.no-js .cd-words-wrapper b.is-visible {
    opacity: 1;
}

/* -------------------------------- 

xclip 

-------------------------------- */

.cd-headline.clip span {
    display: inline-block;
    padding: 0;
}

.cd-headline.clip .cd-words-wrapper {
    overflow: hidden;
    vertical-align: middle;
}

.cd-headline.clip .cd-words-wrapper::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    width: 2px;
    height: 80%;
    background-color: var(--color-secondary);
    transform: translateY(-50%);
}

.cd-headline.clip b {
    opacity: 0;
}

.cd-headline.clip b.is-visible {
    opacity: 1;
}

/**************************************
    Theme Styles
***************************************/

/* Theme Bg Color  */

.rainbow-gradient-circle {
    position: fixed;
    left: -250px;
    top: 250px;
    right: auto;
    bottom: auto;
    z-index: -1;
    width: 500px;
    height: 500px;
    border-radius: 1000px;
    background-image: linear-gradient(45deg, var(--color-primary), var(--color-light-green));
    opacity: 0.25;
    -webkit-filter: blur(100px);
    filter: blur(100px);
}

.rainbow-gradient-circle.theme-pink {
    left: auto;
    top: -250px;
    right: -250px;
    bottom: auto;
    background-image: linear-gradient(45deg, var(--color-secondary), var(--color-tertiary));
}

.theme-shape {
    position: relative;
    z-index: 2;
    overflow: hidden;
}

.theme-shape::before {
    position: absolute;
    left: -250px;
    top: 250px;
    right: auto;
    bottom: auto;
    z-index: -1;
    width: 500px;
    height: 500px;
    border-radius: 1000px;
    background-image: linear-gradient(45deg, var(--color-primary), var(--color-light-green));
    opacity: 0.2;
    filter: blur(100px);
    content: "";
}

.theme-shape::after {
    position: absolute;
    z-index: -1;
    width: 500px;
    height: 500px;
    border-radius: 1000px;
    opacity: 0.2;
    filter: blur(100px);
    content: "";
    left: auto;
    top: -250px;
    right: -250px;
    bottom: auto;
    background-image: linear-gradient(45deg, var(--color-secondary), var(--color-tertiary));
}

.link-hover a {
    display: inline-block;
    position: relative;
}

.link-hover a::after {
    position: absolute;
    content: "";
    left: auto;
    bottom: 0;
    background: currentColor;
    width: 0;
    height: 2px;
    transition: 0.3s;
    right: 0;
}

.link-hover a:hover::after {
    width: 100%;
    left: 0;
    right: auto;
}

/**************************************
    Header Styles
***************************************/

/*--------------------------
    Header Styles  
 --------------------------*/

.logo-dark {
    display: none;
}

.header-default {
    max-width: 100%;
    /* border-bottom: 1px solid var(--color-border); */
    background-color: var(--color-darker);
    color: #fff;
    font-size: 12px;
    z-index: 99;
    position: relative;
}

.header-default .logo a {
    display: flex;
    height: 80px;
    line-height: 80px;
    align-items: center;
}

.header-default .logo a img {
    /* max-height: 45px; */
}

.header-default .header-right {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.header-default.header-relative {
    position: relative;
}

.header-default .header-right {
    display: flex;
    justify-content: flex-end;
}

.header-default .header-right .header-btn {
    margin-left: 25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-default .header-right .header-btn {
        margin-left: 0;
    }
}

@media only screen and (max-width: 767px) {
    .header-default .header-right .header-btn {
        margin-left: 0;
    }
}

@media only screen and (max-width: 767px) {

    .header-default a.btn-default,
    .header-default button.btn-default {
        height: 36px;
        line-height: 35px;
        padding: 0 10px;
        font-size: 12px;
    }
}

@media only screen and (max-width: 767px) {

    .header-default a.btn-default.btn-small,
    .header-default button.btn-default.btn-small {
        height: 36px;
        line-height: 35px;
        padding: 0 10px;
        font-size: 12px;
    }
}

.header-default.header-transparent {
    position: absolute;
    left: 0;
    right: 0;
    width: auto;
    background: transparent !important;
    backdrop-filter: inherit;
}

.header-transparent-with-topbar {
    position: absolute;
    left: 0;
    right: 0;
    width: auto;
    z-index: 99;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-transparent-with-topbar {
        position: relative;
    }
}

@media only screen and (max-width: 767px) {
    .header-transparent-with-topbar {
        position: relative;
    }
}

.header-transparent-with-topbar .header-top-bar {
    background-color: transparent;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-transparent-with-topbar .header-top-bar {
        background-color: var(--color-darker);
    }
}

@media only screen and (max-width: 767px) {
    .header-transparent-with-topbar .header-top-bar {
        background-color: var(--color-darker);
    }
}

.header-transparent-with-topbar .header-default {
    background-color: transparent;
    background-image: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-transparent-with-topbar .header-default {
        background-color: var(--color-darker);
    }
}

@media only screen and (max-width: 767px) {
    .header-transparent-with-topbar .header-default {
        background-color: var(--color-darker);
    }
}

.hamberger-button {
    background: var(--color-blackest);
    border: 0 none;
    color: var(--color-white);
    width: 40px;
    height: 40px;
    font-size: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
}

.header-left-align .mainmenu-nav {
    margin-left: 30px;
}

.rainbow-header.sticky {
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--color-dark) !important;
    width: 100%;
    height: 80px;
    animation: headerSticky 0.95s ease forwards;
    z-index: 9;
}

.rbt-fluid-header .container-wrapper {
    max-width: 1600px;
}

@keyframes headerSticky {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
}

/*--------------------------
    Navigation Styles 
----------------------------*/

.mainmenu-nav .mainmenu {
    display: flex;
    margin: 0;
    padding: 0;
}

.mainmenu-nav .mainmenu>li>a {
    color: var(--color-body);
    font-size: 16px;
    font-weight: 500;
    padding: 0 17px;
    display: block;
    height: 80px;
    line-height: 80px;
    transition: 0.3s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .mainmenu-nav .mainmenu>li>a {
        padding: 0 10px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .mainmenu-nav .mainmenu>li>a {
        padding: 0 12px;
    }
}

.mainmenu-nav .mainmenu>li>a:hover,
.mainmenu-nav .mainmenu>li>a.active {
    color: var(--color-primary) !important;
}

.mainmenu-nav .mainmenu>li.current>a {
    color: var(--color-primary) !important;
}

.mainmenu-nav .mainmenu li {
    margin-top: 0;
    margin-bottom: 0;
    position: relative;
}

.mainmenu-nav .mainmenu li.has-droupdown .submenu {
    min-width: 260px;
    height: auto;
    position: absolute;
    top: 90%;
    left: 0;
    z-index: 90;
    opacity: 0;
    visibility: hidden;
    text-align: left;
    padding: 12px 0;
    transition: 0.3s;
    border-radius: 0 0 10px 10px;
    background-color: var(--color-blackest);
    box-shadow: var(--shadow-lighter);
}

.mainmenu-nav .mainmenu li.has-droupdown .submenu li a {
    font-weight: 500;
    padding: 5px 20px;
    font-size: 14px;
    display: block;
    color: var(--color-body);
    margin: 0 10px;
    border-radius: 3px;
}

.mainmenu-nav .mainmenu li.has-droupdown .submenu li a:hover {
    color: var(--color-primary) !important;
    background: var(--color-darker);
}

.mainmenu-nav .mainmenu li.has-droupdown .submenu li a.active {
    color: var(--color-primary) !important;
    background: var(--color-darker);
}

.mainmenu-nav .mainmenu li.has-droupdown:hover .submenu {
    opacity: 1;
    visibility: visible;
    top: 100%;
}

.mainmenu-nav .mainmenu li.with-megamenu {
    position: static;
}

.mainmenu-nav .mainmenu li.with-megamenu .rainbow-megamenu {
    position: absolute;
    transition: 0.3s;
    top: 90%;
    width: 100%;
    left: 0;
    right: 0;
    padding: 0 7px;
    visibility: hidden;
    opacity: 0;
}

.mainmenu-nav .mainmenu li.with-megamenu .rainbow-megamenu .wrapper {
    border-radius: 0 0 10px 10px;
    background-color: var(--color-blackest);
    box-shadow: var(--shadow-lighter);
}

.mainmenu-nav .mainmenu li.with-megamenu .rainbow-megamenu .wrapper .mega-menu-item {
    padding: 22px 10px;
    border-right: 1px solid var(--color-extra04);
    height: 100%;
}

.mainmenu-nav .mainmenu li.with-megamenu .rainbow-megamenu .wrapper .mega-menu-item li a {
    font-weight: 500;
    padding: 5px 14px;
    font-size: 15px;
    display: block;
    color: var(--color-body);
    margin: 0 10px;
    border-radius: 4px;
    transition: 0.3s;
    display: flex;
    align-items: center;
}

.mainmenu-nav .mainmenu li.with-megamenu .rainbow-megamenu .wrapper .mega-menu-item li a .rainbow-badge-card {
    margin-left: 10px;
}

.mainmenu-nav .mainmenu li.with-megamenu .rainbow-megamenu .wrapper .mega-menu-item li a:hover,
.mainmenu-nav .mainmenu li.with-megamenu .rainbow-megamenu .wrapper .mega-menu-item li a.active {
    color: var(--color-primary) !important;
    background: var(--color-darker);
}

.mainmenu-nav .mainmenu li.with-megamenu .rainbow-megamenu.with-mega-item-2 {
    width: 600px;
    margin: inherit;
}

.mainmenu-nav .mainmenu li.with-megamenu .single-mega-item:last-child .mega-menu-item {
    border-right: 0 none;
}

.mainmenu-nav .mainmenu li.with-megamenu:hover .rainbow-megamenu {
    opacity: 1;
    visibility: visible;
    top: 100%;
}

/*--------------------------
    Header Top Styles  
-----------------------------*/

.header-top-news,
.header-top-news-2 {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    width: auto;
    z-index: 99;
    transition: 0.4s;
    margin-bottom: -1px;
}

.header-top-news.bg-image1,
.header-top-news-2.bg-image1 {
    /* background-image: url(../images/header-top/top-banner.png); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.header-top-news .inner,
.header-top-news-2 .inner {
    display: flex;
    justify-content: center;
    padding: 8px 0;
    align-items: center;
    position: relative;
}

@media only screen and (max-width: 767px) {

    .header-top-news .inner,
    .header-top-news-2 .inner {
        display: block;
        text-align: center;
        padding: 15px 0;
    }
}

.header-top-news .inner .content .news-text,
.header-top-news-2 .inner .content .news-text {
    display: inline-block;
    margin-left: 15px;
    color: var(--color-body);
    font-size: 14px;
}

@media only screen and (max-width: 575px) {

    .header-top-news .inner .content .news-text,
    .header-top-news-2 .inner .content .news-text {
        margin-left: 0;
        margin-top: 10px;
        display: block;
    }
}

.header-top-news .inner .right-button,
.header-top-news-2 .inner .right-button {
    margin-left: 30px;
}

@media only screen and (max-width: 767px) {

    .header-top-news .inner .right-button,
    .header-top-news-2 .inner .right-button {
        margin-left: 0;
    }
}

.header-top-news .icon-close,
.header-top-news-2 .icon-close {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
}

@media only screen and (max-width: 767px) {

    .header-top-news .icon-close,
    .header-top-news-2 .icon-close {
        right: 10px;
        top: 9px;
        transform: none;
    }
}

.header-top-news .close-button,
.header-top-news-2 .close-button {
    background: transparent;
    color: #fff;
    border: 2px solid var(--color-border);
    width: 35px;
    display: inline-block;
    height: 35px;
    border-radius: 100%;
    padding: 0;
    margin: 0;
    line-height: 30px;
    transition: 0.3s;
}

.header-top-news .close-button:hover,
.header-top-news-2 .close-button:hover {
    background: var(--color-primary);
    border-color: var(--color-primary);
    color: #fff;
}

.header-top-news.deactive,
.header-top-news-2.deactive {
    display: none;
}

.header-top-news-2 .bg-image1 {
    /* background-image: url(../images/header-top/top-banner.png); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    border-radius: 6px;
}

/*----------------------------
    Header Top Bar  
----------------------------*/

.header-top-bar {
    padding: 10px 0;
    border-bottom: 1px solid var(--color-border);
    background-color: var(--color-darker);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-top-bar .header-left {
        text-align: center;
    }
}

@media only screen and (max-width: 767px) {
    .header-top-bar .header-left {
        text-align: center;
    }
}

.header-top-bar .header-left p {
    margin-bottom: 0;
}

.header-top-bar .header-left p a {
    transition: 0.3s;
}

.header-top-bar .header-left p a i {
    position: relative;
    top: 2px;
}

.header-top-bar .header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-top-bar .header-right {
        justify-content: center;
        margin-top: 6px;
    }
}

@media only screen and (max-width: 767px) {
    .header-top-bar .header-right {
        justify-content: center;
        margin-top: 6px;
    }
}

.header-top-bar .header-right .address-content {
    display: flex;
    align-items: center;
    margin: 0 -10px;
}

.header-top-bar .header-right .address-content p {
    margin-bottom: 0;
    padding: 0 10px;
    font-size: 14px;
    position: relative;
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 575px) {
    .header-top-bar .header-right .address-content p {
        padding: 0 8px;
    }
}

.header-top-bar .header-right .address-content p+p::before {
    position: absolute;
    content: "";
    height: 20px;
    width: 2px;
    background: var(--color-border);
    top: 50%;
    transform: translateY(-50%);
    left: -1px;
}

.header-top-bar .header-right .address-content p a {
    transition: 0.3s;
}

.header-top-bar .header-right .address-content p i {
    margin-right: 8px;
    font-size: 16px;
}

.header-top-bar .social-default.icon-naked li {
    margin: 1px;
}

.header-top-bar .social-default.icon-naked li a {
    color: var(--color-body);
    font-size: 15px;
    line-height: 26px;
}

.header-top-bar .social-icon-wrapper {
    position: relative;
    margin-left: 10px;
    padding-left: 10px;
}

@media only screen and (max-width: 575px) {
    .header-top-bar .social-icon-wrapper {
        margin-left: 0;
        padding-left: 0;
        flex-basis: 100%;
    }
}

.header-top-bar .social-icon-wrapper::before {
    position: absolute;
    content: "";
    height: 20px;
    width: 2px;
    background: var(--color-border);
    top: 50%;
    transform: translateY(-50%);
    left: -1px;
}

@media only screen and (max-width: 575px) {
    .header-top-bar .social-icon-wrapper::before {
        display: none;
    }
}

.envato-white {
    display: block;
    margin-left: auto;
    text-align: center;
    margin-right: auto;
}

.envato-black {
    display: none;
}

.header-transparent-with-topbar {
    position: absolute;
    left: 0;
    right: 0;
    width: auto;
    z-index: 99;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-transparent-with-topbar {
        position: relative;
    }
}

@media only screen and (max-width: 767px) {
    .header-transparent-with-topbar {
        position: relative;
    }
}

.header-transparent-with-topbar .header-top-bar {
    background-color: transparent;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-transparent-with-topbar .header-top-bar {
        background-color: var(--color-darker);
    }
}

@media only screen and (max-width: 767px) {
    .header-transparent-with-topbar .header-top-bar {
        background-color: var(--color-darker);
    }
}

.header-transparent-with-topbar .header-default {
    background-color: transparent;
    background-image: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-transparent-with-topbar .header-default {
        background-color: var(--color-darker);
    }
}

@media only screen and (max-width: 767px) {
    .header-transparent-with-topbar .header-default {
        background-color: var(--color-darker);
    }
}

/*------------------------------
    Popup Menu Styles  
--------------------------------*/

@media only screen and (min-width: 992px) {
    .popup-mobile-menu {
        display: none;
    }
}

.popup-mobile-menu {
    z-index: 9999;
    position: fixed;
    content: "";
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s ease-out;
}

.popup-mobile-menu .inner {
    width: 320px;
    z-index: 999;
    position: absolute;
    background-color: var(--color-blackest);
    height: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    opacity: 0;
    left: -150px;
    transition: all 0.5s ease-out;
}

.popup-mobile-menu .inner .header-top {
    display: flex;
    border-bottom: 1px solid var(--color-border);
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
}

.popup-mobile-menu .inner .header-top .logo a img {
    max-height: 45px;
}

.popup-mobile-menu .inner .header-top .close-menu .close-button {
    background: var(--color-darker);
    border: 0 none;
    color: var(--color-white);
    width: 40px;
    height: 40px;
    font-size: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
}

.popup-mobile-menu.active {
    visibility: visible;
    opacity: 1;
}

.popup-mobile-menu.active .inner {
    opacity: 1;
    left: 0;
    overflow-y: auto;
}

.popup-mobile-menu .mainmenu {
    list-style: none;
    padding: 0;
    margin: 0;
    padding: 15px 20px;
}

.popup-mobile-menu .mainmenu li {
    margin: 0;
}

.popup-mobile-menu .mainmenu li a {
    padding: 8px 0;
    display: block;
    font-size: 16px;
    font-weight: 500;
}

.popup-mobile-menu .mainmenu li a.active {
    color: var(--color-primary) !important;
}

.popup-mobile-menu .mainmenu li+li {
    border-top: 1px solid var(--color-border);
}

.popup-mobile-menu .mainmenu .has-droupdown .submenu {
    padding: 0;
    max-width: 100%;
    list-style: none;
    padding-left: 14px;
    display: none;
}

.popup-mobile-menu .mainmenu .has-droupdown .submenu li a {
    font-size: 15px;
}

.popup-mobile-menu .mainmenu .has-droupdown .submenu li a.active {
    color: var(--color-primary) !important;
}

.popup-mobile-menu .mainmenu .has-menu-child-item>a {
    position: relative;
}

.popup-mobile-menu .mainmenu .has-menu-child-item>a::after {
    position: absolute;
    content: "\e9b1";
    font-family: "feather" !important;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.4s;
}

.popup-mobile-menu .mainmenu .has-menu-child-item>a.open::after {
    content: "\e996";
}

.popup-mobile-menu .mainmenu .rainbow-megamenu {
    padding: 0;
    max-width: 100%;
    list-style: none;
    padding-left: 14px;
    display: none;
}

.popup-mobile-menu .mainmenu .mega-menu-item {
    list-style: none;
    padding: 0;
    margin: 0;
    padding-left: 0;
}

.popup-mobile-menu .mainmenu .mega-menu-item li a {
    font-size: 15px;
}

.popup-mobile-menu .mainmenu .mega-menu-item li:last-child {
    border-bottom: 1px solid var(--color-border);
}

.popup-mobile-menu .mainmenu .single-mega-item:last-child .mega-menu-item li:last-child {
    border-bottom-color: transparent;
}

/*----------------------
    One Page Nav 
------------------------*/

@media (prefers-reduced-motion: no-preference) {
    :root {
        scroll-behavior: auto;
    }
}

.mainmenu-nav.onepagenav .mainmenu>li>a {
    position: relative;
    padding: 0;
}

.mainmenu-nav.onepagenav .mainmenu li {
    margin: 0 17px;
}

.mainmenu-nav.onepagenav .mainmenu>li>a::after {
    position: absolute;
    content: "";
    left: 0;
    background: var(--color-primary);
    width: 0;
    height: 2px;
    bottom: 0;
    opacity: 0;
    transition: 0.4s;
}

.mainmenu-nav.onepagenav .mainmenu>li.current>a::after {
    width: 100%;
    opacity: 1;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .header-default.header-center-align .header-right .header-btn {
        margin-left: 0;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .mainmenu-nav.onepagenav .mainmenu li {
        margin: 0 7px;
    }
}

/**************************************
            Elements 
***************************************/

/*====================
    Breadcrump Area 
======================*/

.breadcarumb-style-1 {
    border-bottom: 1px solid var(--color-border);
}

.breadcrumb-inner {
    text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .breadcrumb-inner .title br {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .breadcrumb-inner .title br {
        display: none;
    }
}

.breadcrumb-inner ul.page-list {
    margin-top: 16px;
}

.breadcrumb-inner ul.page-list li {
    display: inline-block;
    color: var(--color-body);
    padding: 0 12px;
    position: relative;
    font-size: 16px;
    margin: 0;
}

.breadcrumb-inner ul.page-list li::after {
    position: absolute;
    content: "";
    top: 50%;
    transform: translateY(-50%);
    background-color: var(--color-body);
    width: 5px;
    height: 5px;
    border-radius: 100%;
    opacity: 0.5;
    right: -5px;
}

.breadcrumb-inner ul.page-list li a {
    color: var(--color-body);
    transition: 0.5s;
}

.breadcrumb-inner ul.page-list li a:hover {
    color: var(--color-primary);
}

.breadcrumb-inner ul.page-list li.rainbow-breadcrumb-item.active {
    color: var(--color-body);
    opacity: 0.6;
}

.breadcrumb-inner ul.page-list li.rainbow-breadcrumb-item.active::after {
    display: none;
}

.section-title .subtitle {
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 18px;
    font-size: 16px;
    border-radius: 4px;
    margin-bottom: 12px;
    display: inline-block;
    padding: 7px 14px;
    background-color: var(--color-blackest);
    box-shadow: var(--shadow-lighter);
}

.section-title .subtitle.radius-rounded {
    border-radius: 100px;
}

@media only screen and (max-width: 767px) {
    .section-title .description br {
        display: none;
    }
}

.section-title p:empty {
    display: none;
}

.contact-form-1 .form-group {
    display: block;
}

.section-title-2 .title {
    color: var(--color-body);
    display: block;
    border-bottom: 1px solid var(--color-border);
    padding-bottom: 19px;
    opacity: 0.6;
}

/* Button Styles  */

.button-group {
    margin: -10px;
}

.button-group a,
.button-group button {
    margin: 10px;
}

a.btn-default,
button.btn-default,
div.btn-default {
    padding: 0 28px;
    border-style: solid;
    border-width: 2px;
    border-color: transparent;
    border-radius: 8px;
    background-color: transparent;
    background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95%/200% 100%;
    transition-duration: 300ms;
    color: var(--color-white);
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    text-decoration: none;
    text-shadow: var(--shadow-light);
    display: inline-block;
    height: 50px;
    line-height: 47px;
    letter-spacing: 0.5px;
    width: auto;
    transition: 0.4s;
}

a.btn-default.btn-icon .icon,
button.btn-default.btn-icon .icon,
div.btn-default.btn-icon .icon {
    position: relative;
    top: 2px;
    padding-left: 4px;
}

a.btn-default.btn-icon .icon i,
button.btn-default.btn-icon .icon i,
div.btn-default.btn-icon .icon i {
    width: 20px;
    height: 20px;
}

a.btn-default:hover,
button.btn-default:hover,
div.btn-default:hover {
    box-shadow: var(--shadow-primary);
    -webkit-filter: brightness(125%);
    filter: brightness(125%);
    color: var(--color-white);
    text-decoration: none;
}

a.btn-default.btn-small,
button.btn-default.btn-small,
div.btn-default.btn-small {
    height: 40px;
    line-height: 36px;
    padding: 0 20px;
    font-size: 14px;
    font-weight: 500;
}

a.btn-default.btn-medium,
button.btn-default.btn-medium,
div.btn-default.btn-medium {
    height: 65px;
    line-height: 61px;
    padding: 0 40px;
}

a.btn-default.btn-large,
button.btn-default.btn-large,
div.btn-default.btn-large {
    height: 60px;
    line-height: 56px;
    padding: 0 37px;
    font-size: 17px;
}

@media only screen and (max-width: 767px) {

    a.btn-default.btn-large,
    button.btn-default.btn-large,
    div.btn-default.btn-large {
        padding: 0 25px;
    }
}

a.btn-default.btn-extra-large,
button.btn-default.btn-extra-large,
div.btn-default.btn-extra-large {
    height: 90px;
    line-height: 90px;
    padding: 0 70px;
    font-size: 20px;
}

@media only screen and (max-width: 767px) {

    a.btn-default.btn-extra-large,
    button.btn-default.btn-extra-large,
    div.btn-default.btn-extra-large {
        padding: 0 44px;
    }
}

a.btn-default.btn-border,
button.btn-default.btn-border,
div.btn-default.btn-border {
    background-image: inherit;
    border-color: var(--color-border);
    border-width: 2px;
    border-style: solid;
}

a.btn-default.btn-border:hover,
button.btn-default.btn-border:hover,
div.btn-default.btn-border:hover {
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
}

a.btn-default.text-underline,
button.btn-default.text-underline,
div.btn-default.text-underline {
    background-image: inherit;
    border-color: var(--color-white);
}

a.btn-default.text-underline span,
button.btn-default.text-underline span,
div.btn-default.text-underline span {
    position: relative;
    display: inline-block;
    line-height: 30px;
}

a.btn-default.text-underline:hover,
button.btn-default.text-underline:hover,
div.btn-default.text-underline:hover {
    box-shadow: none;
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
}

a.btn-default.round,
button.btn-default.round,
div.btn-default.round {
    border-radius: 100px;
}

a.btn-default.square,
button.btn-default.square,
div.btn-default.square {
    border-radius: 0;
}

a.btn-default.rounded-player,
button.btn-default.rounded-player,
div.btn-default.rounded-player {
    border-radius: 100%;
    width: 100px;
    height: 100px;
    padding: 0;
    line-height: 100px;
    font-size: 30px;
    display: inline-block;
}

@media only screen and (max-width: 767px) {

    a.btn-default.rounded-player,
    button.btn-default.rounded-player,
    div.btn-default.rounded-player {
        width: 70px;
        height: 70px;
        line-height: 70px;
    }
}

a.btn-default.rounded-player span i,
button.btn-default.rounded-player span i,
div.btn-default.rounded-player span i {
    position: relative;
    top: 1px;
    left: 2px;
}

a.btn-default.rounded-player.border,
button.btn-default.rounded-player.border,
div.btn-default.rounded-player.border {
    background-image: inherit;
    border-color: var(--color-white) !important;
    border-width: 2px !important;
}

a.btn-default.rounded-player.border:hover,
button.btn-default.rounded-player.border:hover,
div.btn-default.rounded-player.border:hover {
    background-color: var(--color-secondary);
    border-color: var(--color-secondary) !important;
}

a.btn-default.rounded-player.sm-size,
button.btn-default.rounded-player.sm-size,
div.btn-default.rounded-player.sm-size {
    width: 70px;
    height: 70px;
    line-height: 70px;
}

.btn-read-more span {
    position: relative;
    color: var(--color-heading);
    display: inline-block;
    line-height: 35px;
    transition: 0.3s;
}

.btn-read-more span::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    background-color: var(--color-border);
    transition: 0.3s;
    width: 100%;
    bottom: 3px;
}

.btn-read-more span::before {
    position: absolute;
    content: "";
    left: auto;
    bottom: 2px;
    background: var(--color-primary);
    width: 0;
    height: 2px;
    transition: 0.3s;
    right: 0;
}

.btn-read-more:hover span {
    color: var(--color-primary);
}

.btn-read-more:hover span::before {
    width: 100%;
    left: 0;
    right: auto;
}

.rainbow-badge {
    display: inline-block;
    background-color: var(--color-primary);
    padding: 0 16px;
    height: 30px;
    line-height: 30px;
    border-radius: 100px;
    color: #fff;
    font-size: 12px;
    letter-spacing: 0.5px;
}

.rainbow-badge-card {
    background-color: transparent;
    background: transparent;
    padding: 1px 10px;
    border-radius: 100px;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    display: inline-block;
    position: relative;
    z-index: 2;
    letter-spacing: 0.5px;
}

.rainbow-badge-card::before {
    background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95%/200% 100%;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    z-index: -1;
    border-radius: 100px;
    opacity: 0.5;
}

.btn-collaborate-play i {
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 500px;
    display: inline-block;
    background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95%/200% 100%;
}

.btn-collaborate-play span {
    display: inline-block;
}

.btn-collaborate-play {
    height: 30px;
    width: 30px;
    line-height: 30px;
    align-items: center;
    transition: var(--transition);
}

.btn-collaborate-play span {
    margin-left: 15px;
}

.btn-collaborate-play:hover {
    color: var(--color-white);
}

/*--------------------
    Service Styles  
----------------------*/

.service.service__style--1 {
    padding: 50px 35px;
    padding-bottom: 55px;
    transition: transform 0.65s cubic-bezier(0.05, 0.2, 0.1, 1), box-shadow 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
    position: relative;
    z-index: 2;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .service.service__style--1 {
        padding: 30px 28px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .service.service__style--1 {
        padding: 30px 25px;
    }
}

@media only screen and (max-width: 767px) {
    .service.service__style--1 {
        padding: 30px 14px;
    }
}

.service.service__style--1 .icon {
    font-size: 45px;
    font-weight: 400;
    margin-bottom: 23px;
    color: var(--color-primary);
    text-align: center;
    border-radius: 100%;
    display: flex;
    justify-content: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .service.service__style--1 .icon {
        margin-bottom: 11px;
    }
}

.service.service__style--1 .icon i {
    stroke-width: 1 !important;
    font-size: 45px;
}

.service.service__style--1 .icon svg {
    stroke-width: 2px !important;
    width: 45px;
    height: 45px;
}

.service.service__style--1 .content .title {
    margin-bottom: 19px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .service.service__style--1 .content .title {
        margin-bottom: 8px;
    }
}

.service.service__style--1 .content .title a {
    color: inherit;
}

.service.service__style--1 .content .title a:hover {
    color: var(--color-primary);
}

.service.service__style--1:hover {
    transform: translateY(-5px);
}

.service.service__style--1.rbt-border-none {
    border: none;
}

.service.service__style--1.rbt-border {
    border: 1px solid var(--color-border);
}

.service.service__style--1.text-left,
.service.service__style--1.text-start {
    padding: 50px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {

    .service.service__style--1.text-left,
    .service.service__style--1.text-start {
        padding: 40px 30px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {

    .service.service__style--1.text-left,
    .service.service__style--1.text-start {
        padding: 40px 30px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

    .service.service__style--1.text-left,
    .service.service__style--1.text-start {
        padding: 40px 30px;
    }
}

@media only screen and (max-width: 767px) {

    .service.service__style--1.text-left,
    .service.service__style--1.text-start {
        padding: 30px 20px;
    }
}

.service.service__style--1.text-left .icon,
.service.service__style--1.text-start .icon {
    justify-content: left;
}

.service.service__style--1.icon-circle-style {
    background-color: transparent;
    box-shadow: none;
    padding: 0px 35px;
    margin-top: 25px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .service.service__style--1.icon-circle-style {
        padding: 0px 10px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .service.service__style--1.icon-circle-style {
        padding: 0px 10px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .service.service__style--1.icon-circle-style {
        padding: 0px 10px;
    }
}

@media only screen and (max-width: 767px) {
    .service.service__style--1.icon-circle-style {
        padding: 0px 10px;
    }
}

.service.service__style--1.icon-circle-style .icon {
    font-size: 39px;
    color: var(--color-primary);
    display: flex;
    justify-content: center;
    width: 90px;
    height: 90px;
    margin: 0 auto;
    margin-bottom: 23px;
    background-color: var(--color-primary-darker);
    line-height: 90px;
    align-items: center;
}

.service.service__style--1.with-working-process {
    position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .service.service__style--1.with-working-process {
        padding: 20px 0;
    }
}

.service.service__style--1.with-working-process .icon {
    width: 180px;
    height: 180px;
    line-height: 180px;
    font-size: 60px;
    position: relative;
}

.service.service__style--1.with-working-process .line {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 1px;
    width: 100%;
    left: 100%;
    background-color: var(--color-border);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .service.service__style--1.with-working-process .line {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .service.service__style--1.with-working-process .line {
        display: none;
    }
}

.service.service__style--1.with-working-process:hover {
    transform: none;
}

.service.service__style--1.variation-2 {
    padding-bottom: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.service.service__style--1.variation-3 {
    padding-bottom: 50px;
}

.service.service__style--1.service-bg-transparent {
    padding: 0;
}

.service.service__style--1.service-bg-transparent .icon {
    margin-bottom: 12px;
}

.service.service__style--1.service-bg-transparent .content .title {
    margin-bottom: 12px;
}

.service.gallery-style {
    padding: 40px 30px;
    background: var(--color-blackest);
    border-radius: 24px;
    box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.1);
    margin-top: 30px;
}

.service.gallery-style .content .image img {
    border-radius: 5px;
}

.service.gallery-style .content .title {
    margin-bottom: 19px;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px;
}

.service.gallery-style .content .title a {
    color: inherit;
}

.service.gallery-style .content .title a:hover {
    color: var(--color-primary);
}

.service.gallery-style .content p {
    color: var(--color-body);
    margin-bottom: 20px;
}

.service.service__style--2 {
    padding: 50px 35px;
    padding-bottom: 55px;
    transition: transform 0.65s cubic-bezier(0.05, 0.2, 0.1, 1), box-shadow 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
    position: relative;
    z-index: 2;
    border-radius: 10px;
    margin-top: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .service.service__style--2 {
        padding: 10px 28px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .service.service__style--2 {
        padding: 10px 15px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .service.service__style--2 {
        padding: 10px 25px;
    }
}

@media only screen and (max-width: 767px) {
    .service.service__style--2 {
        padding: 0px 18px;
    }
}

.service.service__style--2 .image {
    margin-bottom: 23px;
}

.service.service__style--2 .image img {
    max-height: 220px;
}

.service.service__style--2 .content .title a {
    color: inherit;
}

.service.service__style--2 .content .title a:hover {
    color: var(--color-primary);
}

.service.service__style--2 .content p {
    padding: 0 5%;
}

.service.service__style--2:hover {
    transform: translateY(-5px);
    box-shadow: 0 0 39px rgba(255, 101, 80, 0.1);
}

.service.service-eight {
    padding: 40px 30px;
    background: var(--color-lessdark);
    border-radius: var(--radius);
    box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.1);
}

.service.service-eight .inner {
    align-items: center;
}

.service.service-eight .inner .image {
    margin-right: 30px;
}

.service.service-eight .inner .image img {
    max-height: 100px;
    object-fit: cover;
    width: auto;
    min-width: 100px;
}

.service.service-eight .inner .content .title {
    margin-bottom: 0;
}

.service-wrapper>[class*=col]:nth-child(2) .service.service__style--1 .icon {
    color: var(--color-secondary);
}

.service-wrapper>[class*=col]:nth-child(3) .service.service__style--1 .icon {
    color: var(--color-tertiary);
}

.service-wrapper>[class*=col]:nth-child(4) .service.service__style--1 .icon {
    color: var(--color-pink);
}

.service-wrapper>[class*=col]:nth-child(5) .service.service__style--1 .icon {
    color: var(--color-primary);
}

.service-wrapper>[class*=col]:nth-child(6) .service.service__style--1 .icon {
    color: var(--color-tertiary);
}

.service-wrapper>[class*=col]:nth-child(2) .service.service__style--1.icon-circle-style .icon {
    background-color: var(--color-secondary-darker);
}

.service-wrapper>[class*=col]:nth-child(3) .service.service__style--1.icon-circle-style .icon {
    background-color: var(--color-tertiary-darker);
}

.service-wrapper>[class*=col]:nth-child(4) .service.service__style--1.icon-circle-style .icon {
    background-color: var(--color-pink-darker);
}

.service-wrapper>[class*=col]:nth-child(5) .service.service__style--1.icon-circle-style .icon {
    background-color: var(--color-primary-darker);
}

.service-wrapper>[class*=col]:nth-child(6) .service.service__style--1.icon-circle-style .icon {
    background-color: var(--color-tertiary-darker);
}

.service-wrapper>[class*=col]:last-child .with-working-process .line {
    display: none;
}

/*
    Card Styles  
*/

.card-box.card-style-1 {
    box-shadow: var(--shadow-lighter);
    background-color: var(--color-blackest);
    padding: 20px;
    border-radius: 5px;
    margin-top: 30px;
}

.card-box .inner .image a img {
    border-radius: 5px;
}

.card-box .inner .content {
    padding-top: 20px;
}

.card-box .inner .content .title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 14px;
}

.card-box .inner .content .title a {
    color: inherit;
}

.card-box .inner .content .title a:hover {
    color: var(--color-primary);
}

.card-box .inner .content .description {
    margin-bottom: 0;
}

.card-box .inner .content .btn-default {
    margin-top: 20px;
}

/* Counter Style One  */

.counter-style-1 .count-number {
    font-size: 90px;
}

@media only screen and (max-width: 767px) {
    .counter-style-1 .count-number {
        font-size: 62px;
    }
}

@media only screen and (max-width: 575px) {
    .counter-style-1 .count-number {
        font-size: 45px;
    }
}

.counter-style-1 .title {
    font-size: 20px;
    line-height: 1.3em;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 0;
}

.counter-style-2 {
    margin-top: 30px;
}

.counter-style-2 .count-number {
    font-size: 44px;
    background-color: var(--color-blackest);
    display: inline-block;
    min-width: 150px;
    height: 150px;
    border-radius: 100%;
    text-align: center;
    line-height: 150px;
    margin-bottom: 20px;
    padding: 0 20px;
    font-weight: 500;
    color: var(--color-white);
}

.counter-style-2 .title {
    font-size: 20px;
    line-height: 1.3em;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 10px;
}

.counter-style-2 .description {
    line-height: 1.8;
    padding-right: 16%;
    margin: 0;
}

.counter-style-2.color-variation .count-number {
    background-color: var(--color-primary-darker);
    color: var(--color-primary);
}

.counter-style-2.border-style .count-number {
    background-color: transparent;
    border: 2px solid var(--color-border);
}

.counter-style-2.text-center .description {
    padding: 0 10%;
}

[class*=col]:nth-child(2) .counter-style-2.color-variation .count-number {
    background-color: var(--color-secondary-darker);
    color: var(--color-secondary);
}

[class*=col]:nth-child(3) .counter-style-2.color-variation .count-number {
    background-color: var(--color-light-green-darker);
    color: var(--color-light-green);
}

/*------------------------------
    Counterup Style Four  
-------------------------------*/

.counter-style-4 {
    text-align: left;
    margin-top: 30px;
}

.counter-style-4 .counter-title {
    margin-top: 8px;
    font-weight: 400;
    font-size: 24px;
}

.counter-style-4 .count-number {
    font-size: 35px;
    line-height: 1.2;
    font-weight: 700;
    color: var(--color-primary);
    display: inline-block;
    position: relative;
}

.counter-style-4 .count-number::after {
    content: "+";
}

.counter-style-4 .count-number span {
    display: inline-block;
}

/*-----------------------
    Progress Bar 
-----------------------*/

.single-progress+.single-progress {
    margin-top: 40px;
}

.single-progress {
    position: relative;
    overflow: hidden;
}

.single-progress .title {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 0;
    color: #acacac;
    color: var(--color-body);
    letter-spacing: 0.5px;
    margin-bottom: 18px;
}

.progress {
    height: 6px;
    overflow: visible;
    background-color: var(--color-blackest);
    border-radius: 10px;
}

.progress .progress-bar {
    border-radius: 10px;
    width: 0;
    height: 100%;
    background-color: var(--color-primary);
    transition: width 0.5s ease;
    overflow: visible;
}

.progress .progress-bar.bar-color-2 {
    background: #fb5343;
}

.progress .progress-bar.bar-color-3 {
    background: #E33FA1;
}

.progress .progress-bar.bar-color-4 {
    background: #6549D5;
}

.progress .progress-bar.bar-gradiand {
    background: radial-gradient(circle farthest-corner at 0% 0%, var(--color-pink) 25%, var(--color-secondary) 45%, var(--color-primary) 65%, var(--color-light-green) 85%);
}

.progress .progress-number {
    position: absolute;
    font-size: 14px;
    top: 0;
    line-height: 24px;
    right: 0;
    z-index: 1;
}

.progress-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
}

.progress-info .title {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 0;
    color: var(--color-body);
    letter-spacing: 0.5px;
}

.progress-info span.progress-number {
    font-size: 14px;
    color: var(--color-body);
}

.no-radius .progress {
    border-radius: 0;
}

.no-radius .progress .progress-bar {
    border-radius: 0;
}

.large-size .progress {
    height: 20px;
}

/*-----------------------------------
    Radial Progress 
-----------------------------------*/

.radial-progress-single {
    text-align: center;
}

.radial-progress-single .circle-info {
    padding-top: 20px;
    text-align: center;
}

.radial-progress-single .circle-info .title {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 4px;
}

.radial-progress-single .circle-info .subtitle {
    font-style: italic;
    margin-bottom: 0;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: var(--color-body);
}

.radial-progress {
    position: relative;
}

@media only screen and (max-width: 767px) {
    .radial-progress {
        display: inline-block;
    }
}

.radial-progress .circle-text {
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    transform: translateY(-50%);
}

.radial-progress .circle-text span.count {
    font-size: 28px;
    line-height: 1;
    font-weight: 600;
    color: var(--color-body);
    position: relative;
}

.radial-progress .circle-text span.count::after {
    position: absolute;
    content: "%";
    left: 100%;
    color: var(--color-body);
}

/*--------------------------
    Accordion Styles  
---------------------------*/

.rainbow-accordion-style .card {
    background: var(--color-lessdark);
    border: 0;
    border-radius: var(--radius) !important;
}

.rainbow-accordion-style .card .card-header {
    background-color: transparent;
    border-bottom: 0 none;
    box-shadow: none;
    padding: 20px 30px;
    margin-bottom: 0;
}

.rainbow-accordion-style .card .card-header button {
    color: var(--color-heading);
    font-size: 18px;
    line-height: 27px;
    outline: none;
    text-decoration: none;
    box-shadow: none;
    width: 100%;
    text-align: left;
    padding: 0;
    background: transparent;
    position: relative;
}

.rainbow-accordion-style .card .card-header button::before {
    position: absolute;
    content: "\e996";
    font-family: "feather" !important;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.4s;
}

.rainbow-accordion-style .card .card-header button.collapsed::before {
    content: "\e9b1";
}

.rainbow-accordion-style .card .card-body {
    padding: 16px;
    padding-top: 0;
    font-size: var(--font-size-b2);
    line-height: var(--line-height-b2);
    font-weight: var(--p-regular);
    color: var(--color-body);
    padding: 30px 20px;
    border-top: 1px solid var(--color-border);
}

.rainbow-accordion-style .card+.card {
    margin-top: 20px;
}

.rainbow-accordion-style.rainbow-accordion-02 .card {
    background: transparent;
    border-radius: 0 !important;
}

.rainbow-accordion-style.rainbow-accordion-02 .card .card-header {
    border-bottom: 2px solid var(--color-border);
    padding: 0px 20px 10px 0;
}

.rainbow-accordion-style.rainbow-accordion-02 .card .card-header .accordion-button::after {
    display: none;
}

.rainbow-accordion-style.rainbow-accordion-02 .card .card-body {
    padding: 15px 20px 10px 0;
    border-top: 0;
}

.rainbow-accordion-style.rainbow-accordion-03 .card {
    background: transparent;
    border-radius: 0 !important;
}

.rainbow-accordion-style.rainbow-accordion-03 .card .card-header {
    border: 2px solid var(--color-border);
    padding: 15px 20px;
    border-radius: var(--radius) !important;
}

.rainbow-accordion-style.rainbow-accordion-03 .card .card-body {
    padding: 15px 20px 10px 0;
    border: 0;
}

.rainbow-accordion-style.rainbow-accordion-04 .card {
    border: 2px solid var(--color-border);
    background: transparent;
    border-radius: var(--radius) !important;
}

.rainbow-accordion-style.rainbow-accordion-04 .card .card-header {
    padding: 15px 20px;
    border-radius: var(--radius) !important;
}

.rainbow-accordion-style.rainbow-accordion-04 .card .card-body {
    border-top: 2px solid var(--color-border);
}

.accordion-button::after {
    visibility: hidden;
}

.preview-accordion-area .service .content .liststyle {
    margin-top: 20px;
}

.preview-accordion-area .service .content .liststyle li {
    font-size: 16px;
}

.preview-accordion-area .service .content .liststyle span {
    color: var(--color-white);
    margin-right: 5px;
}

.preview-accordion-area .service p {
    margin-bottom: 20px;
    font-size: 16px;
}

.preview-accordion-area .service.support p {
    margin-bottom: 20px;
}

/*-----------------------
    Social Icons 
-------------------------*/

.social-default {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    list-style: none;
    justify-content: center;
    margin: -5px;
}

.social-default li {
    margin: 5px;
}

.social-default li a {
    width: 48px;
    background-color: var(--color-lessdark);
    height: 48px;
    line-height: 45px;
    text-align: center;
    display: inline-block;
    border-radius: 100%;
    transition: 0.3s;
    color: var(--color-white);
    z-index: 2;
    position: relative;
}

.social-default li a::after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: var(--color-primary);
    transform: scale(0.5);
    opacity: 0;
    transition: 0.3s;
    border-radius: 100%;
}

.social-default li a:hover {
    color: var(--color-white);
}

.social-default li a:hover::after {
    transform: scale(1);
    opacity: 1;
}

.social-default li a i {
    vertical-align: middle;
}

.social-default.transparent-with-border li a {
    line-height: 45px;
    background: transparent;
    border: 1px solid var(--color-border);
}

.social-default.transparent-with-border li a:hover {
    border-color: transparent;
}

.social-default.transparent-with-border li a::after {
    border: 1px solid var(--color-primary);
}

.social-default.with-gradient li a::after {
    background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95%/200% 100%;
}

.social-default.with-bg-primary li a {
    background-color: var(--color-primary);
    border: 2px solid var(--color-primary);
    transition: 0.5s;
    line-height: 44px;
}

.social-default.with-bg-primary li a::after {
    display: none;
}

.social-default.with-bg-primary li a:hover {
    background-color: transparent;
}

.social-default.icon-naked li a {
    background: transparent !important;
    width: 30px;
    height: 30px;
    line-height: 36px;
}

.social-default.icon-naked li a::after {
    display: none;
}

.social-default.icon-naked li a:hover {
    color: var(--color-primary) !important;
}

/*----------------------
    Team Style  
-------------------------*/

.rainbow-box-card,
.team-style-default {
    margin-top: 75px;
}

.rainbow-box-card .inner,
.team-style-default .inner {
    background: linear-gradient(180deg, var(--color-lessdark) 20%, rgba(19, 19, 19, 0.4));
    -webkit-backdrop-filter: blur(4px) opacity(0.95);
    backdrop-filter: blur(4px) opacity(0.95);
    border-radius: var(--radius-big);
}

.rainbow-box-card .inner .thumbnail,
.team-style-default .inner .thumbnail {
    max-width: 150px;
    max-height: 150px;
    border-radius: 100%;
    border: 6px solid var(--color-lessdark);
    top: -75px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    min-width: 150px;
    min-height: 150px;
    overflow: hidden;
}

.rainbow-box-card .inner .thumbnail img,
.team-style-default .inner .thumbnail img {
    border-radius: 100%;
}

.rainbow-box-card .inner .content,
.team-style-default .inner .content {
    text-align: center;
    padding: 20px;
    padding-top: 85px;
    padding-bottom: 35px;
}

.rainbow-box-card .inner .content .title,
.team-style-default .inner .content .title {
    font-size: 34px;
    line-height: 1.4;
    font-weight: 700;
    margin-bottom: 6px;
}

.rainbow-box-card .inner .content .subtitle,
.team-style-default .inner .content .subtitle {
    font-size: 20px;
    line-height: 1.4;
    margin-bottom: 15px;
    display: inline-block;
}

.rainbow-box-card .inner .content .team-form,
.team-style-default .inner .content .team-form {
    display: block;
    font-size: 14px;
    margin-bottom: 12px;
    letter-spacing: 0.6px;
    font-style: italic;
}

.rainbow-box-card .inner .content .team-form img,
.team-style-default .inner .content .team-form img {
    padding-right: 10px;
    max-height: 18px;
}

.rainbow-box-card .inner .content .description,
.team-style-default .inner .content .description {
    margin-bottom: 0;
    padding: 0 5%;
}

.rainbow-box-card .inner .rating img,
.team-style-default .inner .rating img {
    margin-left: -3px;
    margin-bottom: 24px;
    max-height: 35px;
}

.rainbow-box-card .inner .author-envato-image img,
.team-style-default .inner .author-envato-image img {
    max-height: 25px;
    margin-top: 20px;
}

.rainbow-box-card .inner .social-icon li a,
.team-style-default .inner .social-icon li a {
    color: var(--color-body);
}

.rainbow-box-card.border-gradient .thumbnail,
.team-style-default.border-gradient .thumbnail {
    background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 98%/200% 100%;
    padding: 5px;
    border: 0 none;
}

.rainbow-box-card.style-two,
.team-style-default.style-two {
    margin-top: 30px;
}

.rainbow-box-card.style-two .inner,
.team-style-default.style-two .inner {
    padding-top: 35px;
}

.rainbow-box-card.style-two .inner .thumbnail,
.team-style-default.style-two .inner .thumbnail {
    top: 0;
    position: static;
    left: inherit;
    transform: none;
    min-width: 150px;
    min-height: 150px;
    margin: 0 auto;
}

.rainbow-box-card.style-two .inner .content,
.team-style-default.style-two .inner .content {
    padding-top: 10px;
}

/*---------------------
    Team Style Two  
-----------------------*/

.team-style-two .inner .thumbnail img {
    width: 100%;
    border-radius: var(--radius);
}

.team-style-two .inner .content {
    padding-top: 20px;
}

.team-style-two .inner .content .title {
    font-size: 28px;
    line-height: 1.4;
    font-weight: 500;
    margin-bottom: 0;
}

.team-style-two .inner .content .subtitle {
    font-size: 18px;
    line-height: 1.4;
    display: inline-block;
    margin-top: 4px;
    margin-bottom: 0;
    opacity: 0;
    transform: translateX(30px);
    transition: transform 0.45s cubic-bezier(0.16, 0.32, 0.26, 0.92), opacity 0.45s ease-out;
    transition-delay: 0.15s;
}

.team-style-two .inner .content .team-form {
    display: block;
    font-size: 14px;
    letter-spacing: 0.6px;
    font-style: italic;
    margin-top: 11px;
}

.team-style-two .inner .content .team-form img {
    padding-right: 10px;
    max-height: 18px;
}

.team-style-two:hover .inner .content .subtitle {
    opacity: 1;
    transform: none;
}

/*---------------------
    Team Style Three  
-----------------------*/

.team-style-three .inner {
    position: relative;
}

.team-style-three .inner .thumbnail img {
    width: 100%;
    border-radius: var(--radius);
}

.team-style-three .inner .content {
    position: absolute;
    bottom: 0;
    padding: 20px;
    width: 100%;
    transition: all 0.3s;
    opacity: 0;
}

.team-style-three .inner .content .team-info {
    padding: 20px;
    background-color: rgba(58, 58, 58, 0.85);
    border-radius: var(--radius-small);
}

.team-style-three .inner .content .title {
    font-size: 17px;
    line-height: 25px;
    color: var(--color-heading);
    margin-bottom: 4px;
}

.team-style-three .inner .content .subtitle {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 4px;
}

.team-style-three .inner .content .team-form {
    display: block;
    font-style: italic;
    font-size: 14px;
    color: #ADADAD;
    letter-spacing: 0.5px;
}

.team-style-three:hover .inner .content {
    opacity: 1;
}

/*---------------------
    Portfolio Styles  
-----------------------*/

.rainbow-portfolio-area {
    position: relative;
    overflow: hidden;
}

.rainbow-card {
    overflow: hidden;
}

.rainbow-card .inner .thumbnail {
    position: relative;
}

.rainbow-card .inner .thumbnail .card-image {
    overflow: hidden;
}

.rainbow-card .inner .thumbnail .card-image a {
    display: block;
    overflow: hidden;
}

.rainbow-card .inner .thumbnail .card-image img {
    overflow: hidden;
}

.rainbow-card .inner .content {
    padding-top: 20px;
}

.rainbow-card .inner .content .subtitle {
    text-transform: capitalize;
}

.rainbow-card:hover {
    transform: translateY(-4px);
}

.rainbow-card:hover .inner .thumbnail .card-image img {
    transform: scale(1.05);
}

/*------------------
    No Overlay  
--------------------*/

.no-overlay .rainbow-card .inner .thumbnail .rainbow-overlay {
    display: none;
}

/*----------------------
    Box Grid Layout  
------------------------*/

.box-grid-layout .rainbow-card {
    border-radius: var(--radius-small);
    background-color: var(--color-blackest);
}

.box-grid-layout .rainbow-card .inner .thumbnail .card-image a {
    border-radius: var(--radius-small) var(--radius-small) 0 0;
}

.box-grid-layout .rainbow-card .inner .thumbnail .card-image a img {
    border-radius: var(--radius-small) var(--radius-small) 0 0;
}

.box-grid-layout .rainbow-card .inner .content {
    padding: 25px;
}

/*-----------------------------
    Filter Button Style  
-------------------------------*/

.filter-button-default {
    display: flex;
    justify-content: center;
    margin: 0 -5px;
    flex-wrap: wrap;
}

.filter-button-default button {
    border: 0 none;
    padding: 10px 20px;
    text-transform: capitalize;
    background: transparent;
    color: var(--color-body);
    margin: 0 5px;
}

@media only screen and (max-width: 767px) {
    .filter-button-default button {
        padding: 10px 10px;
    }
}

.filter-button-default button.is-checked,
.filter-button-default button:hover {
    background-color: var(--color-primary);
    color: var(--color-white) !important;
}

/*--------------------------------
    Portfolio Read More Icon  
---------------------------------*/

.rainbow-load-more a.btn-default.btn-icon .icon,
.rainbow-load-more button.btn-default.btn-icon .icon {
    top: 0px;
    margin-left: -2px;
}

.rainbow-load-more a.btn-default.btn-icon .icon svg,
.rainbow-load-more button.btn-default.btn-icon .icon svg {
    width: 14px;
    height: 20px;
}

/*----------------------------------
    Portfolio Grid Layout  
----------------------------------*/

.grid-metro2 {
    margin: 0 -15px;
}

.grid-metro2 .portfolio-2 {
    width: 50%;
    padding: 0 15px;
    margin-top: 30px;
}

@media only screen and (max-width: 575px) {
    .grid-metro2 .portfolio-2 {
        width: 100%;
    }
}

.grid-metro2 .resizer {
    width: 50%;
}

@media only screen and (max-width: 575px) {
    .grid-metro2 .resizer {
        width: 100%;
    }
}

.grid-metro3 {
    margin: 0 -15px;
}

.grid-metro3 .portfolio-3 {
    width: 33.33%;
    padding: 0 15px;
    margin-top: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .grid-metro3 .portfolio-3 {
        width: 50%;
    }
}

@media only screen and (max-width: 767px) {
    .grid-metro3 .portfolio-3 {
        width: 50%;
    }
}

@media only screen and (max-width: 575px) {
    .grid-metro3 .portfolio-3 {
        width: 100%;
    }
}

.grid-metro3 .resizer {
    width: 33.33%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .grid-metro3 .resizer {
        width: 50%;
    }
}

@media only screen and (max-width: 767px) {
    .grid-metro3 .resizer {
        width: 50%;
    }
}

@media only screen and (max-width: 575px) {
    .grid-metro3 .resizer {
        width: 100%;
    }
}

.grid-metro4 {
    margin: 0 -15px;
}

.grid-metro4 .portfolio-4 {
    width: 25%;
    padding: 0 15px;
    margin-top: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .grid-metro4 .portfolio-4 {
        width: 50%;
    }
}

@media only screen and (max-width: 767px) {
    .grid-metro4 .portfolio-4 {
        width: 50%;
    }
}

@media only screen and (max-width: 575px) {
    .grid-metro4 .portfolio-4 {
        width: 100%;
    }
}

.grid-metro4 .resizer {
    width: 25%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .grid-metro4 .resizer {
        width: 50%;
    }
}

@media only screen and (max-width: 767px) {
    .grid-metro4 .resizer {
        width: 50%;
    }
}

@media only screen and (max-width: 575px) {
    .grid-metro4 .resizer {
        width: 100%;
    }
}

.grid-metro4.no-gutter {
    margin: 0;
}

.grid-metro4.no-gutter .portfolio-4 {
    padding: 0;
    margin-top: 0;
}

.grid-metro4.no-gutter .rainbow-card {
    border-radius: 0;
    border: 0 none;
}

.no-gutter .rainbow-card .inner .thumbnail a img,
.no-gutter .rainbow-card .inner .thumbnail a {
    border-radius: 0;
}

.no-gutter .rainbow-card:hover {
    transform: none;
}

/*-------------------------
    Testimonial Styles  
--------------------------*/

.card-style-default.testimonial-style-one .inner .thumbnail {
    overflow: visible;
}

.card-style-default.testimonial-style-one .inner .thumbnail::after {
    position: absolute;
    content: "”";
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: var(--color-blackest);
    right: 0;
    top: 0;
    color: #fff;
    z-index: 2;
    text-align: center;
    line-height: 58px;
    font-size: 39px;
}

.card-style-default.testimonial-style-one .inner .content .description {
    margin-bottom: 14px;
}

.card-style-default.testimonial-style-one .inner .content .title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 2px;
    letter-spacing: 0.5px;
}

.card-style-default.testimonial-style-one .inner .content .subtitle {
    font-size: 16px;
    line-height: 1.2;
    margin-bottom: 0;
    letter-spacing: 0.5px;
}

.card-style-default.testimonial-style-one.style-two .inner .thumbnail {
    position: relative;
}

.card-style-default.testimonial-style-one.style-two .inner .description {
    margin-bottom: 19px;
    margin-top: 20px;
}

/*-----------------------------
    Testimonial Style Two  
-------------------------------*/

.testimonial-style-two .content {
    position: relative;
    z-index: 2;
}

.testimonial-style-two .content::after {
    position: absolute;
    content: "“";
    left: -20px;
    color: var(--color-lessdark);
    text-align: center;
    font-size: 320px;
    opacity: 0.7;
    line-height: 50px;
    top: 52px;
    z-index: -1;
}

.testimonial-style-two .content .form {
    display: block;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-bottom: 20px;
}

.testimonial-style-two .content .description {
    line-height: 1.5;
    margin-bottom: 30px;
    font-size: 32px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .testimonial-style-two .content .description {
        margin-bottom: 25px;
        font-size: 24px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial-style-two .content .description {
        font-size: 24px;
    }
}

@media only screen and (max-width: 767px) {
    .testimonial-style-two .content .description {
        font-size: 24px;
    }
}

.testimonial-style-two .content .client-info .title {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 8px;
}

.testimonial-style-two .content .client-info .subtitle {
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 400;
    letter-spacing: 0.5px;
    color: var(--color-body);
    font-style: italic;
}

.testimonial-style-two .thumbnail {
    background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 98%/200% 100%;
    border-radius: 100%;
    padding: 10px;
}

.testimonial-style-two .thumbnail img {
    border-radius: 100%;
}

.slick-initialized .slick-slide {
    margin-bottom: 0;
}

.slick-grid-15 {
    margin: 0 -15px;
}

.slick-grid-15 .slide-single-layout {
    padding: 0 15px;
}

/*----------------------
    Slick Dots  
------------------------*/

.rainbow-slick-dot .slick-dots {
    bottom: -60px;
}

@media only screen and (max-width: 575px) {
    .rainbow-slick-dot .slick-dots {
        bottom: -50px;
    }
}

.rainbow-slick-dot .slick-dots li {
    width: 30px;
    margin: 0;
    opacity: 1;
    position: relative;
}

.rainbow-slick-dot .slick-dots li button::before {
    display: none;
}

.rainbow-slick-dot .slick-dots li button::after {
    background: var(--color-lessdark);
    width: 100%;
    display: block;
    content: "";
    position: absolute;
    left: 0;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-left: 11px;
    background-color: transparent;
    box-shadow: inset 0 0 0 5px var(--color-lessdark);
}

.rainbow-slick-dot .slick-dots li.slick-active {
    opacity: 1;
}

.rainbow-slick-dot .slick-dots li.slick-active button::after {
    -webkit-transform: scale(2);
    transform: scale(2);
    box-shadow: inset 0 0 0 1px var(--color-primary);
    background-color: transparent;
    background-color: transparent !important;
}

.rainbow-slick-dot.dot-light .slick-dots li button::after {
    background: #ffffff;
    box-shadow: inset 0 0 0 5px #ffffff;
}

.rainbow-slick-dot.dot-light .slick-dots li.slick-active {
    opacity: 1;
}

.rainbow-slick-dot.dot-light .slick-dots li.slick-active button::after {
    -webkit-transform: scale(2);
    transform: scale(2);
    box-shadow: inset 0 0 0 1px var(--color-primary);
    background-color: transparent;
    background-color: transparent !important;
}

/*----------------------
    Slick Arrow  
------------------------*/

.rainbow-slick-arrow {
    position: relative;
}

.rainbow-slick-arrow button.slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    transition: 0.5s;
    opacity: 0;
    width: 68px;
    height: 68px;
    border: 2px solid var(--color-border);
    border-radius: 100%;
    color: var(--color-border);
    background-color: transparent;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .rainbow-slick-arrow button.slick-arrow {
        display: none !important;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .rainbow-slick-arrow button.slick-arrow {
        display: none !important;
    }
}

@media only screen and (max-width: 767px) {
    .rainbow-slick-arrow button.slick-arrow {
        display: none !important;
    }
}

.rainbow-slick-arrow button.slick-arrow:hover {
    background: var(--color-primary);
    border-color: var(--color-primary);
}

.rainbow-slick-arrow button.slick-arrow::before {
    line-height: 1;
    opacity: 0.4;
    position: relative;
    top: -2px;
    transition: 0.5s;
    z-index: 2;
}

.rainbow-slick-arrow button.slick-arrow.prev-arrow {
    left: 15px;
}

@media only screen and (max-width: 767px) {
    .rainbow-slick-arrow button.slick-arrow.prev-arrow {
        left: 10px;
    }
}

.rainbow-slick-arrow button.slick-arrow.prev-arrow::before {
    /* content: url(../images/slick-arrow-left-small.png); */
    vertical-align: middle;
}

.rainbow-slick-arrow button.slick-arrow.prev-arrow:hover {
    left: 10px;
}

.rainbow-slick-arrow button.slick-arrow.prev-arrow:hover::before {
    opacity: 1;
}

.rainbow-slick-arrow button.slick-arrow.next-arrow {
    left: auto;
    right: 15px;
}

@media only screen and (max-width: 767px) {
    .rainbow-slick-arrow button.slick-arrow.next-arrow {
        right: 10px;
    }
}

.rainbow-slick-arrow button.slick-arrow.next-arrow::before {
    /* content: url(../images/slick-arrow-right-small.png); */
    vertical-align: middle;
}

.rainbow-slick-arrow button.slick-arrow.next-arrow:hover {
    right: 10px;
}

.rainbow-slick-arrow button.slick-arrow.next-arrow:hover::before {
    opacity: 1;
}

.rainbow-slick-arrow button.slick-arrow i {
    display: none;
}

.rainbow-slick-arrow.slick-arrow-alignwide button.slick-arrow.prev-arrow {
    left: -105px;
}

.rainbow-slick-arrow.slick-arrow-alignwide button.slick-arrow.next-arrow {
    right: -105px;
    left: auto;
}

.rainbow-slick-arrow:hover button.slick-arrow {
    opacity: 1;
}

/* -----------------------
Timeline Area  
--------------------------*/

.timeline-line {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background: rgba(196, 196, 196, 0.22);
}

.timeline-line::before,
.timeline-line::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
}

.timeline-line::before {
    height: 100%;
    bottom: 0;
    background: linear-gradient(180deg, var(var(--color-blackest)), rgba(19, 19, 19, 0));
}

.timeline-line::after {
    height: 200px;
    top: 0;
    background: linear-gradient(180deg, var(var(--color-blackest)), rgba(19, 19, 19, 0));
}

.timeline-dot {
    position: absolute;
    top: 50%;
    left: 0;
}

.timeline-dot::before,
.timeline-dot::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    border-radius: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.timeline-dot::before {
    z-index: 1;
    width: 2.1rem;
    height: 2.1rem;
    border-radius: 50%;
    background: linear-gradient(180deg, var(--color-primary-light), var(--color-primary));
    box-shadow: 0 0 15px rgba(82, 56, 255, 0.5);
}

.timeline-dot::after {
    border: 5px solid var(var(--color-blackest));
    background: radial-gradient(50% 50% at 50% 50%, var(--color-lessdark) 48.96%, var(--color-lessdark) 100%);
    z-index: 0;
    width: 31px;
    height: 31px;
}

.timeline-dot .time-line-circle {
    position: absolute;
    top: 50%;
    border-radius: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(242, 201, 76, 0) 40.62%, rgba(82, 56, 255, 0.5) 100%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    will-change: transform;
    -webkit-animation: radialanimation 2.2s cubic-bezier(0.26, 1.04, 0.54, 1) infinite;
    animation: radialanimation 2.2s cubic-bezier(0.26, 1.04, 0.54, 1) infinite;
}

/*------------------------------
    Single Timeline Styles  
-------------------------------*/

.single-timeline {
    position: relative;
    padding-left: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-timeline {
        padding-left: 54px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .single-timeline {
        padding-left: 50px;
    }
}

@media only screen and (max-width: 767px) {
    .single-timeline {
        padding-left: 30px;
    }
}

.single-timeline .single-content {
    background: linear-gradient(180deg, var(--color-lessdark) 20%, rgba(19, 19, 19, 0.4));
    -webkit-backdrop-filter: blur(4px) opacity(0.95);
    backdrop-filter: blur(4px) opacity(0.95);
    border-radius: var(--radius-big);
}

.single-timeline .single-content .inner {
    padding: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .single-timeline .single-content .inner {
        padding: 30px;
    }
}

@media only screen and (max-width: 767px) {
    .single-timeline .single-content .inner {
        padding: 20px;
    }
}

.single-timeline .single-content .inner .content .date-of-timeline {
    color: var(--color-body);
    display: block;
    margin-bottom: 30px;
    letter-spacing: 0.5px;
    font-style: italic;
    font-size: 14px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .single-timeline .single-content .inner .content .date-of-timeline {
        margin-bottom: 14px;
    }
}

@media only screen and (max-width: 767px) {
    .single-timeline .single-content .inner .content .date-of-timeline {
        margin-bottom: 14px;
    }
}

.single-timeline .single-content .inner .content .title {
    margin-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-timeline .single-content .inner .content .title {
        font-size: 32px;
    }
}

.single-timeline .single-content .inner .content .description {
    margin-top: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .single-timeline .single-content .inner .content .description {
        margin-top: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .single-timeline .single-content .inner .content .description {
        margin-top: 15px;
    }
}

.single-timeline .single-content .thumbnail img {
    border-radius: var(--radius);
}

@-webkit-keyframes radialanimation {
    0% {
        opacity: 1;
        -webkit-transform: translate3d(-50%, -50%, 0) scale(0.1);
        transform: translate3d(-50%, -50%, 0) scale(0.1);
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-50%, -50%, 0) scale(1);
        transform: translate3d(-50%, -50%, 0) scale(1);
    }
}

@keyframes radialanimation {
    0% {
        opacity: 1;
        -webkit-transform: translate3d(-50%, -50%, 0) scale(0.1);
        transform: translate3d(-50%, -50%, 0) scale(0.1);
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-50%, -50%, 0) scale(1);
        transform: translate3d(-50%, -50%, 0) scale(1);
    }
}

/*----------------------------
    Timeline Style Two  
----------------------------*/

.timeline-style-two {
    position: relative;
    padding: 20px 50px 50px 50px;
    border-radius: var(--radius-big);
}

.rainbow-timeline-single {
    margin-top: 30px;
}

.rainbow-timeline-single .rainbow-timeline {
    text-align: center;
}

.rainbow-timeline-single .rainbow-timeline .progress-line {
    position: relative;
    border-radius: 0px;
    height: 6px;
    box-shadow: none;
    margin: 20px 0;
    width: 100%;
}

.rainbow-timeline-single .rainbow-timeline .progress-line .line-inner {
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
    transition: width 0.6s ease;
    background-image: radial-gradient(circle farthest-corner at 0%, var(--color-pink) 25%, var(--color-secondary) 45%, var(--color-primary-light) 65%, var(--color-light-green) 85%);
    background-attachment: fixed;
    background-repeat: repeat-y;
    width: 100%;
    display: flex;
    height: 100%;
}

@media only screen and (max-width: 767px) {
    .rainbow-timeline-single .rainbow-timeline .progress-line .line-inner {
        display: none;
    }
}

.rainbow-timeline-single .rainbow-timeline .progress-dot {
    position: relative;
    height: 30px;
    display: block;
    width: 100%;
    margin-top: -39px;
    margin-left: -15px;
}

.rainbow-timeline-single .rainbow-timeline .progress-dot .dot-level {
    transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    background: linear-gradient(to right, var(--color-primary), var(--color-secondary), var(--color-primary-light));
    background-attachment: fixed;
    background-repeat: repeat-y;
    position: absolute;
    width: 28px;
    height: 28px;
    display: block;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
}

.rainbow-timeline-single .rainbow-timeline .progress-dot .dot-level .dot-inner {
    width: 12px;
    height: 12px;
    border-radius: 50px;
    position: absolute;
    top: 8px;
    left: 8px;
    background-color: var(--color-darkest);
}

.rainbow-timeline-single:first-child .rainbow-timeline .progress-line {
    width: 50%;
    left: 50%;
}

.rainbow-timeline-single:last-child .rainbow-timeline .progress-line {
    width: 50%;
}

.rainbow-timeline-single .title {
    margin-bottom: 33px;
    font-size: 20px;
}

.rainbow-timeline-single .description {
    padding: 0 10%;
    margin-top: 15px;
    margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .rainbow-timeline-single .description {
        padding: 0 10px;
    }
}

@media only screen and (max-width: 767px) {
    .rainbow-timeline-single .description {
        padding: 0 10px;
    }
}

.rainbow-timeline-single.no-gradient .rainbow-timeline .progress-line .line-inner {
    background-image: inherit;
    background: var(--color-primary);
}

.rainbow-timeline-single.no-gradient .rainbow-timeline .progress-dot .dot-level {
    background-image: inherit;
    background: var(--color-primary);
}

.rainbow-timeline-single.dark-line .rainbow-timeline .progress-line .line-inner {
    background-image: inherit;
    background: var(--color-lessdark);
}

.rainbow-timeline-single.dark-line .rainbow-timeline .progress-dot .dot-level {
    background-image: inherit;
    background: var(--color-lessdark);
}

.rainbow-timeline-single.dark-line .rainbow-timeline .progress-dot .dot-level .dot-inner {
    background-color: var(--color-primary);
}

/*--------------------------------
    Call To Action Style  
--------------------------------*/

.clltoaction-style-default {
    padding: 250px 0;
}

.clltoaction-style-default .title {
    font-size: 52px;
    font-weight: 600;
    position: relative;
    margin-bottom: 30px;
    padding-bottom: 0;
    line-height: 1.4;
    color: var(--color-white);
}

@media only screen and (max-width: 767px) {
    .clltoaction-style-default .title {
        font-size: 40px;
    }
}

.clltoaction-style-default .subtitle {
    color: #fff;
    font-size: 24px;
    line-height: 1.4;
    margin: 0;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.clltoaction-style-default .content p {
    color: var(--color-body);
}

.clltoaction-style-default .call-to-btn {
    margin-top: 50px;
}

.clltoaction-style-default .content.text-left .title::before {
    left: 0;
    transform: none;
}

.clltoaction-style-default .content.text-left .subtitle {
    padding: 0 16% 0 0;
}

.clltoaction-style-default.style-2 {
    padding: 130px 0;
}

.clltoaction-style-default.style-3 {
    padding: 150px 0;
}

.clltoaction-style-default.style-3 .content-wrapper {
    background-color: var(--color-lessdark);
    border-radius: var(--radius);
    padding: 50px 40px;
}

.clltoaction-style-default.style-3 .content .title {
    font-size: 32px;
    margin-bottom: 18px;
    padding-bottom: 10px;
    color: var(--color-white);
}

.clltoaction-style-default.style-3 .content .subtitle {
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: 0.5px;
}

.clltoaction-style-default.style-3 .call-to-btn {
    margin-top: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .clltoaction-style-default.style-3 .call-to-btn {
        margin-top: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .clltoaction-style-default.style-3 .call-to-btn {
        margin-top: 20px;
    }
}

.clltoaction-style-default.style-4 {
    padding: 0;
}

.clltoaction-style-default.style-4 .content-wrapper {
    background-color: var(--color-lessdark);
    border-radius: var(--radius);
    padding: 70px 50px;
}

.clltoaction-style-default.style-4 .content .title {
    font-size: 47px;
    margin-bottom: 33px;
    padding-bottom: 16px;
    line-height: 1.4;
}

.clltoaction-style-default.style-4 .content .subtitle {
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: 0.5px;
}

.clltoaction-style-default.style-4 .call-to-btn {
    margin-top: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .clltoaction-style-default.style-4 .call-to-btn {
        margin-top: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .clltoaction-style-default.style-4 .call-to-btn {
        margin-top: 20px;
    }
}

.clltoaction-style-default.style-5 {
    padding: 0;
}

.clltoaction-style-default.style-5 .content-wrapper {
    background-color: var(--color-blackest);
    border-radius: var(--radius);
    padding: 70px 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .clltoaction-style-default.style-5 .content-wrapper {
        padding: 40px 20px;
    }
}

@media only screen and (max-width: 767px) {
    .clltoaction-style-default.style-5 .content-wrapper {
        padding: 40px 20px;
    }
}

.clltoaction-style-default.style-5 .content .title {
    font-size: 47px;
    margin-bottom: 20px;
    padding-bottom: 0;
    line-height: 1.4;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .clltoaction-style-default.style-5 .content .title {
        font-size: 38px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .clltoaction-style-default.style-5 .content .title {
        font-size: 34px;
    }
}

@media only screen and (max-width: 767px) {
    .clltoaction-style-default.style-5 .content .title {
        font-size: 34px;
    }
}

@media only screen and (max-width: 575px) {
    .clltoaction-style-default.style-5 .content .title {
        font-size: 26px;
    }
}

.clltoaction-style-default.style-5 .content .title::before {
    display: none;
}

.clltoaction-style-default.style-5 .content .subtitle {
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: 0.5px;
}

.clltoaction-style-default.style-5 .call-to-btn {
    margin-top: 30px;
}

.clltoaction-style-default.style-6 {
    padding: 80px 0;
}

.clltoaction-style-default.style-6 .content .title {
    font-size: 36px;
    margin-bottom: 0;
    padding-bottom: 0;
    line-height: 1.4;
}

.clltoaction-style-default.style-6 .content .title::before {
    display: none;
}

.clltoaction-style-default.style-6 .call-to-btn {
    margin-top: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .clltoaction-style-default.style-6 .call-to-btn {
        margin-top: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .clltoaction-style-default.style-6 .call-to-btn {
        margin-top: 20px;
    }
}

.clltoaction-style-default.style-7 {
    padding: 0;
}

.clltoaction-style-default.style-7 .content-wrapper {
    padding: 40px 0;
    border-bottom: 1px solid var(--color-border);
}

.clltoaction-style-default.style-7 .content .subtitle {
    font-size: 16px;
    color: var(--color-body);
    max-width: 450px;
    line-height: 30px;
    margin-bottom: 0;
    margin-top: 13px;
    padding: 0;
}

.clltoaction-style-default.style-7 .call-to-btn {
    margin-top: 0;
    text-align: right;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .clltoaction-style-default.style-7 .call-to-btn {
        margin-top: 20px;
        text-align: left;
    }
}

@media only screen and (max-width: 767px) {
    .clltoaction-style-default.style-7 .call-to-btn {
        margin-top: 20px;
        text-align: left;
    }
}

.rainbow-call-to-action.style-8 {
    padding: 100px 0;
    background: var(--color-blackest);
}

.rainbow-call-to-action.style-8 .content-wrapper {
    padding: 40px 0;
    border-bottom: 1px solid var(--color-border);
}

.rainbow-call-to-action.style-8 .content .subtitle {
    font-size: 20px;
    color: var(--color-body);
    line-height: 1.5;
    margin-bottom: 0;
}

.rainbow-call-to-action.style-8 .call-to-btn {
    margin-top: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .rainbow-call-to-action.style-8 .call-to-btn {
        margin-top: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .rainbow-call-to-action.style-8 .call-to-btn {
        margin-top: 20px;
    }
}

.theme-shape {
    position: relative;
    z-index: 2;
    overflow: hidden;
}

.theme-shape::before {
    position: absolute;
    left: -250px;
    top: 250px;
    right: auto;
    bottom: auto;
    z-index: -1;
    width: 500px;
    height: 500px;
    border-radius: 1000px;
    background-image: linear-gradient(45deg, var(--color-primary), var(--color-light-green));
    opacity: 0.2;
    filter: blur(100px);
    content: "";
}

.theme-shape::after {
    position: absolute;
    z-index: -1;
    width: 500px;
    height: 500px;
    border-radius: 1000px;
    opacity: 0.2;
    filter: blur(100px);
    content: "";
    left: auto;
    top: -250px;
    right: -250px;
    bottom: auto;
    background-image: linear-gradient(45deg, var(--color-secondary), var(--color-tertiary));
}

.bg-image {
    background-repeat: no-repeat;
    background-position: center center;
}

.bg-image.bg-image2 {
    /* background-image: url(../images/call-to-action/bg-image-1.jpg); */
}

.bg-image.bg-image3 {
    /* background-image: url(../images/call-to-action/bg-image-2.jpg); */
}

.data-black-overlay {
    position: relative;
}

.data-black-overlay::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
    background-color: #000000;
    opacity: 0.7;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .call-to-action-alignwide .template-slider-wrapper {
        padding: 0 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .call-to-action-alignwide .template-slider-wrapper {
        padding-right: 30px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .call-to-action-alignwide .sub-title {
        padding-top: 25px;
    }
}

.call-to-action-alignwide p {
    padding-bottom: 0;
}

.call-to-action-alignwide .thumbnail {
    margin-right: -142px;
    margin-top: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .call-to-action-alignwide .thumbnail {
        margin-right: -15px;
    }
}

@media only screen and (max-width: 767px) {
    .call-to-action-alignwide .thumbnail {
        margin-right: -15px;
    }
}

.call-to-action-variation-6 {
    max-width: 600px;
}

/*---------------------
    Tab Styles  
-----------------------*/

.rainbow-default-tab {
    position: relative;
}

.rainbow-default-tab .tab-button {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
    border-bottom: none;
}

.rainbow-default-tab .tab-button .tabs__tab {
    margin: 0;
}

.rainbow-default-tab .tab-button .tabs__tab .nav-link {
    color: var(--color-white);
    padding: 24px 44px;
    margin-bottom: -2px;
    outline: none;
    border: 0 none;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .rainbow-default-tab .tab-button .tabs__tab .nav-link {
        padding: 24px 24px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .rainbow-default-tab .tab-button .tabs__tab .nav-link {
        padding: 24px 24px;
    }
}

@media only screen and (max-width: 767px) {
    .rainbow-default-tab .tab-button .tabs__tab .nav-link {
        padding: 24px 20px;
    }
}

.rainbow-default-tab .tab-button .tabs__tab .nav-link.active {
    background: var(--color-lessdark);
    border-color: transparent;
}

.rainbow-default-tab .tab-button .rainbow-tab-button button {
    padding: 0;
    margin: 0;
    background: transparent;
    border: 0 none;
    color: var(--color-white);
}

.rainbow-default-tab .rainbow-tab-content {
    background: var(--color-lessdark);
    padding: 40px;
}

@media only screen and (max-width: 767px) {
    .rainbow-default-tab .rainbow-tab-content {
        padding: 30px;
    }
}

.rainbow-default-tab .rainbow-tab-content p:last-child {
    margin-bottom: 0;
}

.rainbow-default-tab.style-two .tab-button {
    margin-bottom: 30px;
}

.rainbow-default-tab.style-two .tab-button .tabs__tab button {
    border-radius: var(--radius-small);
}

.rainbow-default-tab.style-two .rainbow-tab-content {
    border-radius: var(--radius-small);
}

.rainbow-default-tab::before {
    background-color: var(--color-primary);
    background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95%/200% 100%;
    width: 100%;
    height: 70%;
    content: "";
    left: 0;
    bottom: 0;
    position: absolute;
    z-index: -1;
    border-radius: 10px;
    opacity: 0.5;
    backdrop-filter: blur(20px);
}

.rainbow-no-padding {
    padding: 0 !important;
}

.rainbow-pricing {
    margin-top: 40px;
}

.rainbow-pricing .pricing-table-inner {
    padding: 40px;
    position: relative;
    z-index: 2;
    border: 2px solid var(--color-border);
    border-radius: 5px;
    transition: all 0.6s cubic-bezier(0.33, 0.84, 0.31, 0.98);
    transform-style: preserve-3d;
}

.rainbow-pricing .pricing-table-inner::before {
    z-index: -1;
    display: inline-block;
    content: "";
    transition: all 0.6s cubic-bezier(0.33, 0.84, 0.31, 0.98);
    opacity: 0;
    border-radius: 5px;
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    background-color: var(--color-lessdark);
}

.rainbow-pricing .pricing-table-inner .pricing-header {
    margin-bottom: 30px;
    padding-bottom: 30px;
    text-align: center;
    border-bottom: 2px solid var(--color-border);
}

.rainbow-pricing .pricing-table-inner .pricing-header .title {
    margin-bottom: 20px;
}

.rainbow-pricing .pricing-table-inner .pricing-header .price-wrapper {
    display: flex;
    justify-content: center;
}

.rainbow-pricing .pricing-table-inner .pricing-header .pricing span {
    display: block;
}

.rainbow-pricing .pricing-table-inner .pricing-header .pricing span.currency {
    font-size: 28px;
    position: relative;
    top: -3px;
    left: -2px;
    opacity: 0.3;
}

.rainbow-pricing .pricing-table-inner .pricing-header .pricing span.price {
    font-size: 77px;
    color: var(--color-white);
    line-height: 1;
    font-weight: 700;
    margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
    .rainbow-pricing .pricing-table-inner .pricing-header .pricing span.price {
        font-size: 54px;
    }
}

.rainbow-pricing .pricing-table-inner .pricing-header .pricing span.subtitle {
    font-size: 14px;
    color: var(--color-body);
}

.rainbow-pricing .pricing-table-inner .pricing-body {
    text-align: center;
    margin-bottom: 30px;
}

.rainbow-pricing .pricing-table-inner .pricing-body .list-style--1 {
    list-style: none;
    padding: 0;
    margin: 0;
}

.rainbow-pricing .pricing-table-inner .pricing-footer {
    text-align: center;
}

.rainbow-pricing.style-2 .pricing-table-inner {
    background-color: var(--color-blackest);
    border-color: transparent !important;
}

.rainbow-pricing.style-4 {
    margin-left: -1px;
    margin-right: -1px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .rainbow-pricing.style-4 {
        margin-left: 15px;
        margin-right: 15px;
    }
}

@media only screen and (max-width: 767px) {
    .rainbow-pricing.style-4 {
        margin-left: 15px;
        margin-right: 15px;
    }
}

.rainbow-pricing.active .pricing-table-inner {
    border-color: transparent;
}

.rainbow-pricing.active .pricing-table-inner::before {
    transform: scaleY(1.12);
    opacity: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .rainbow-pricing.active .pricing-table-inner::before {
        transform: scaleY(1);
    }
}

@media only screen and (max-width: 767px) {
    .rainbow-pricing.active .pricing-table-inner::before {
        transform: scaleY(1);
    }
}

.rainbow-pricing.active .pricing-table-inner .pricing-header {
    border-bottom: 2px solid var(--color-border);
}

.rainbow-pricing.active .pricing-table-inner .pricing-header .title {
    color: var(--color-white);
}

.rainbow-pricing.active .pricing-table-inner .pricing-header .pricing span {
    display: block;
}

.rainbow-pricing.active .pricing-table-inner .pricing-header .pricing span.currency {
    color: var(--color-white);
    opacity: 1;
}

.rainbow-pricing.active .pricing-table-inner .pricing-header .pricing span.price {
    color: var(--color-white);
}

.rainbow-pricing.active .pricing-table-inner .pricing-header .pricing span.subtitle {
    color: var(--color-white);
}

.rainbow-pricing.active .pricing-table-inner .pricing-body ul.list-style--1 li {
    color: var(--color-white);
}

.rainbow-pricing.active .pricing-table-inner .pricing-body ul.list-style--1 li svg {
    color: var(--color-white);
}

.rainbow-pricing.active .pricing-table-inner .pricing-footer {
    text-align: center;
}

.rainbow-pricing.style-5 .pricing-table-inner {
    background-color: var(--color-blackest);
    border-color: transparent;
    padding: 0;
    padding-bottom: 40px;
    border: 0 none;
}

.rainbow-pricing.style-5 .pricing-table-inner::before {
    display: none;
}

.rainbow-pricing.style-5 .pricing-table-inner .pricing-header {
    margin-bottom: 30px;
    padding-bottom: 0;
    border-bottom: 0 none !important;
}

.rainbow-pricing.style-5 .pricing-table-inner .pricing-header .title {
    padding: 30px 40px;
    margin-bottom: 0;
    font-size: 19px;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.rainbow-pricing.style-5 .pricing-table-inner .pricing-header .price-wrapper {
    margin-bottom: 5px;
}

.rainbow-pricing.style-5 .pricing-table-inner .pricing-header .pricing span.currency {
    font-size: 40px;
    position: relative;
    top: 0px;
    left: 0;
    opacity: 1;
    color: var(--color-white);
    font-weight: 700;
    line-height: 7px;
    display: flex;
    align-items: center;
}

.rainbow-pricing.style-5 .pricing-table-inner .pricing-header .pricing span.price {
    font-size: 50px;
    color: var(--color-white);
    line-height: 1;
    font-weight: 700;
    margin-bottom: 0;
}

.rainbow-pricing.style-5 .pricing-table-inner .pricing-header .pricing span.subtitle {
    color: var(--color-white);
}

.rainbow-pricing.style-5 .pricing-table-inner .pricing {
    background-color: var(--color-lessdark);
    padding: 30px;
}

/*--------------------------
    split Area Styles  
----------------------------*/

.rainbow-splite-style .thumbnail img {
    border-radius: 0;
    width: 100%;
}

.rainbow-splite-style .thumbnail.image-left-content img {
    border-radius: 0 10px 10px 0;
}

.rainbow-splite-style .split-inner {
    padding: 30px 115px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .rainbow-splite-style .split-inner {
        padding: 40px 80px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .rainbow-splite-style .split-inner {
        padding: 40px 30px;
    }
}

@media only screen and (max-width: 767px) {
    .rainbow-splite-style .split-inner {
        padding: 40px 30px;
    }
}

.rainbow-splite-style .split-inner .title {
    font-size: 36px;
    line-height: 50px;
    color: var(--color-heading);
    font-weight: var(--p-bold);
}

@media only screen and (max-width: 767px) {
    .rainbow-splite-style .split-inner .title {
        font-size: 24px;
        line-height: 30px;
    }
}

.rainbow-splite-style .split-inner .split-list {
    list-style: none;
    padding-left: 0;
    margin: 0;
}

.rainbow-splite-style .split-inner .split-list li {
    color: var(--color-dark-alt);
}

/*----------------------------
    Video Popup Styles  
------------------------------*/

.video-popup,
.rainbow-gallery {
    position: relative;
    display: block;
}

.video-popup .thumbnail img,
.rainbow-gallery .thumbnail img {
    width: 100%;
}

.video-popup .video-icon,
.rainbow-gallery .video-icon {
    position: absolute;
    content: "";
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin-top: 10px;
    transition: 0.4s;
}

.rainbow-gallery .video-icon {
    opacity: 0;
    transition: 0.4s;
    transform: translate(-50%, -50%) scale(0.5);
}

.rainbow-gallery:hover .video-icon {
    opacity: 1;
    margin-top: 0;
    transform: translate(-50%, -50%) scale(1);
}

.video-gallery-content {
    border-radius: 10px;
    padding-top: 300px;
    padding-bottom: 60px;
    overflow: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .video-gallery-content {
        padding-bottom: 40px;
        padding-top: 150px;
    }
}

@media only screen and (max-width: 767px) {
    .video-gallery-content {
        padding-bottom: 40px;
        padding-top: 150px;
    }
}

.video-gallery-content .overlay-content {
    padding: 0 60px;
}

@media only screen and (max-width: 767px) {
    .video-gallery-content .overlay-content {
        padding: 0 20px;
    }
}

.video-gallery-content .overlay-content .title {
    margin-top: 15px;
    margin-bottom: 0;
    font-size: 30px;
    letter-spacing: 0.5px;
    line-height: 1.4;
    color: var(--color-white);
}

@media only screen and (max-width: 767px) {
    .video-gallery-content .overlay-content .title {
        font-size: 20px;
    }
}

/*------------------------------
    Gallery Styles  
--------------------------------*/

.rbt-temaplte-gallery-wrapper .thumbnail img {
    width: 100%;
    border-radius: 10px;
}

.template-slider-wrapper .sub-title {
    color: var(--color-primary);
}

.template-slider-wrapper .title {
    font-weight: var(--p-black);
}

.template-slider-wrapper .description {
    max-width: 900px;
}

/*==========================
    Contact Address 
============================*/

.contact-form-1 .form-group input {
    height: 70px;
    padding: 0 20px;
}

.contact-form-1 .form-group textarea {
    padding-top: 20px;
    padding-left: 20px;
}

.contact-address-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

/*-------------------
    Address Area  
--------------------*/

.rainbow-address {
    margin-top: 30px;
    padding: 40px;
    border-radius: 10px;
    padding-top: 40px;
    background: var(--color-blackest);
    padding-bottom: 50px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
    transition: 0.3s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .rainbow-address {
        padding: 30px 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .rainbow-address {
        padding: 30px 20px;
    }
}

@media only screen and (max-width: 767px) {
    .rainbow-address {
        padding: 30px 20px;
    }
}

.rainbow-address .icon {
    color: var(--color-primary);
    border-radius: 100%;
    font-size: 40px;
    display: inline-block;
    margin-bottom: 17px;
    line-height: 6px;
}

.rainbow-address .inner h4.title {
    font-size: 18px;
    font-weight: 700;
}

.rainbow-address .inner p {
    font-size: 18px;
    margin-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .rainbow-address .inner p {
        font-size: 16px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .rainbow-address .inner p {
        font-size: 16px;
    }
}

@media only screen and (max-width: 767px) {
    .rainbow-address .inner p {
        font-size: 16px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .rainbow-address .inner p br {
        display: none;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .rainbow-address .inner p br {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .rainbow-address .inner p br {
        display: none;
    }
}

.rainbow-address .inner p a {
    color: var(--color-body);
}

.rainbow-address .inner p a:hover {
    color: var(--color-primary);
}

.rainbow-address:hover {
    transform: translateY(-5px);
}

.google-map-style-1 {
    width: 100%;
    height: 500px;
}

.brand-carousel-activation {
    padding: 0 50px;
}

@media only screen and (max-width: 767px) {
    .brand-carousel-activation {
        padding: 0 10px;
    }
}

.brand-carousel-activation li {
    margin-top: 0;
    margin-bottom: 0;
}

.brand-carousel-activation li a {
    display: block;
}

.brand-carousel-activation li a img {
    opacity: 0.8;
    transition: 0.4s;
}

.brand-carousel-activation li:hover a img {
    opacity: 1;
    filter: contrast(0.5);
}

.brand-carousel-activation.rainbow-slick-arrow button.slick-arrow {
    width: 50px;
    height: 50px;
}

.brand-carousel-activation.rainbow-slick-arrow button.slick-arrow i {
    display: block;
    font-size: 24px;
    color: #fff;
}

.brand-carousel-activation.rainbow-slick-arrow button.slick-arrow::before {
    display: none;
}

.brand-style-1 {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.brand-style-1 li {
    flex-basis: 25%;
    border-width: 1px 1px 1px 1px;
    border-style: solid;
    margin: 0px 0px -2px -2px;
    border-color: var(--color-border);
    padding: 40px 0 40px 0;
    text-align: center;
}

@media only screen and (max-width: 575px) {
    .brand-style-1 li {
        flex-basis: 33.33%;
    }
}

@media only screen and (max-width: 479px) {
    .brand-style-1 li {
        flex-basis: 50%;
    }
}

.brand-style-1 li a {
    display: block;
}

.brand-style-1 li a img {
    opacity: 0.8;
    transition: 0.4s;
}

.brand-style-1 li:hover a img {
    opacity: 1;
    filter: contrast(0.5);
}

.brand-style-2 {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.brand-style-2 li {
    flex-basis: 16.666%;
    text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .brand-style-2 li {
        flex-basis: 25%;
    }
}

@media only screen and (max-width: 767px) {
    .brand-style-2 li {
        flex-basis: 25%;
    }
}

@media only screen and (max-width: 575px) {
    .brand-style-2 li {
        flex-basis: 33.33%;
    }
}

@media only screen and (max-width: 479px) {
    .brand-style-2 li {
        flex-basis: 50%;
    }
}

.brand-style-2 li a {
    display: block;
}

.brand-style-2 li a img {
    opacity: 0.8;
    transition: 0.3s;
}

.brand-style-2 li:hover a img {
    opacity: 1;
}

.brand-style-2.variation-2 li {
    flex-basis: 25%;
    text-align: center;
}

@media only screen and (max-width: 767px) {
    .brand-style-2.variation-2 li {
        flex-basis: 33.33%;
    }
}

@media only screen and (max-width: 479px) {
    .brand-style-2.variation-2 li {
        flex-basis: 50%;
    }
}

/*---------------------------
    Error Styles Area  
-----------------------------*/

.error-inner {
    text-align: center;
}

.error-inner h1 {
    font-size: 200px;
    line-height: 1;
    color: var(--color-primary);
    font-weight: var(--p-bold);
}

@media only screen and (max-width: 767px) {
    .error-inner h1 {
        font-size: 100px;
        line-height: 1;
    }
}

.error-inner .title {
    line-height: 45px;
    font-weight: var(--p-bold);
}

.error-inner .description {
    font-size: var(--font-size-b2);
    line-height: 28px;
    color: var(--color-body);
    font-weight: var(--p-regular);
    margin-bottom: 30px;
}

.error-inner .blog-search {
    display: inline-block;
}

.error-inner .blog-search input {
    min-width: 500px;
}

.error-inner .view-more-button {
    margin-top: 30px;
}

.advance-tab-button-1 .tab-button-list {
    list-style: none;
    padding: 0;
    margin: -10px 0;
}

.advance-tab-button-1 .tab-button-list li {
    margin: 10px 0;
}

.advance-tab-button-1 .tab-button-list .tab-button {
    cursor: pointer;
    padding: 47px 27px;
    border-radius: 10px;
    position: relative;
    z-index: 2;
    background-color: transparent;
    border: 0 none;
    margin: 0;
}

.advance-tab-button-1 .tab-button-list .tab-button::before {
    position: absolute;
    content: "";
    background: var(--color-blackest);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition: 0.3s;
    opacity: 0;
    transform: scale(0.9);
    z-index: -1;
    border-radius: 7px;
}

.advance-tab-button-1 .tab-button-list .tab-button:hover::before {
    opacity: 1;
    transform: scale(1);
}

.advance-tab-button-1 .tab-button-list .tab-button::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    border-left: 20px solid var(--color-blackest);
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    margin-top: -20px;
    opacity: 0;
    transition: 0.3s;
    transform: scale(0.9);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .advance-tab-button-1 .tab-button-list .tab-button::after {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .advance-tab-button-1 .tab-button-list .tab-button::after {
        display: none;
    }
}

.advance-tab-button-1 .tab-button-list .tab-button .title {
    color: var(--color-body);
    font-size: 20px;
    margin-bottom: 10px;
    transition: 0.3s;
}

.advance-tab-button-1 .tab-button-list .tab-button .description {
    margin-bottom: 0;
    transition: 0.3s;
}

.advance-tab-button-1 .tab-button-list .tab-button.tab-with-icon {
    padding: 25px;
    border: 1px solid var(--color-white-off) !important;
}

.advance-tab-button-1 .tab-button-list .tab-button.tab-with-icon .tab {
    display: flex;
    height: 100%;
}

@media only screen and (max-width: 575px) {
    .advance-tab-button-1 .tab-button-list .tab-button.tab-with-icon .tab {
        height: auto;
        text-align: left;
        display: block;
    }
}

.advance-tab-button-1 .tab-button-list .tab-button.tab-with-icon .tab .icon {
    display: flex;
    width: 60px;
    border-radius: 5px;
    background: var(--color-primary-darker);
    color: var(--color-primary);
    text-align: center;
    font-size: 20px;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    min-width: 60px;
}

@media only screen and (max-width: 575px) {
    .advance-tab-button-1 .tab-button-list .tab-button.tab-with-icon .tab .icon {
        width: 60px;
        height: 60px;
        min-width: 60px;
        margin-bottom: 10px;
    }
}

.advance-tab-button-1 .tab-button-list .tab-button.tab-with-icon .tab .content {
    padding-right: 24px;
    padding-left: 24px;
}

@media only screen and (max-width: 575px) {
    .advance-tab-button-1 .tab-button-list .tab-button.tab-with-icon .tab .content {
        padding: 0;
    }
}

.advance-tab-button-1 .tab-button-list .tab-button.tab-with-icon .tab .content .description {
    font-size: var(--font-size-b3);
}

.advance-tab-button-1 .tab-button-list .tab-button.bg-color-white-off::before {
    background: var(--color-white-off);
}

.advance-tab-button-1 .tab-button-list .tab-button.bg-color-white-off::after {
    border-left: 20px solid var(--color-white-off);
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
}

.advance-tab-button-1 .tab-button-list .tab-button.tab-with-icon.active {
    border: 1px solid transparent !important;
}

.advance-tab-button-1 .tab-button-list .tab-button.active .title {
    color: var(--color-white);
}

.advance-tab-button-1 .tab-button-list .tab-button.active::before {
    opacity: 1;
    transform: scale(1);
}

.advance-tab-button-1 .tab-button-list .tab-button.active::after {
    opacity: 1;
    transform: scale(1);
}

.nav-tabs {
    border-bottom: 0;
}

.advance-tab-content-1 .thumbnail img {
    border-radius: 10px;
}

.advance-tab-button-1.right-align .tab-button-list .tab-button::after {
    transform: scale(0.9) rotate(-180deg);
    left: auto;
    right: 100%;
}

.advance-tab-button-1.right-align .tab-button-list .tab-button::after {
    transform: scale(1) rotate(-180deg);
}

.advance-tab-button-1.right-top .tab-button-list .tab-button::after {
    left: 50%;
    transform: scale(0.9) translateX(-50%) rotate(-92deg);
    bottom: 94%;
    top: auto;
}

.advance-tab-button-1.right-top .tab-button-list .tab-button::after {
    opacity: 0;
    transform: scale(1) translateX(-50%) rotate(-92deg);
}

.advance-tab-button-1.right-top .tab-button-list .tab-button.active::after {
    opacity: 1;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active,
.nav-tabs .nav-link:focus {
    color: #495057;
    background: transparent;
    border: 0 none;
    box-shadow: none;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: transparent;
}

.advance-tab-button-1 .tab-button-list .tab-button:focus {
    outline: none;
    border: 0 none;
    box-shadow: none;
}

.service-thumbnail-offset {
    margin-right: -50px;
    margin-bottom: -50px;
    border-radius: 10px 0 10px 0;
}

.service-thumbnail-offset img {
    border-radius: 10px 0 10px 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .service-thumbnail-offset {
        margin-right: -30px;
        margin-bottom: -40px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .service-thumbnail-offset {
        margin-right: -30px;
        margin-bottom: -40px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .service-thumbnail-offset {
        margin-right: -30px;
        margin-bottom: -40px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .advance-tab-four .tab-content .thumbnail {
        margin-top: 30px;
    }
}

/*----------------------------
    Advance Pricing Table  
------------------------------*/

.advance-pricing {
    background: var(--color-blackest);
    border-radius: 10px;
}

.advance-pricing .pricing-left {
    height: 100%;
    background: var(--color-lessdark);
    padding: 60px 50px;
    border-radius: 10px 0 0 10px;
}

@media only screen and (max-width: 767px) {
    .advance-pricing .pricing-left {
        padding: 30px 20px;
    }
}

.advance-pricing .pricing-left .main-title {
    margin-bottom: 10px;
}

.advance-pricing .pricing-left .price-wrapper .price-amount {
    font-size: 60px;
    text-align: center;
    display: block;
    color: var(--color-white);
    font-weight: 700;
}

.advance-pricing .pricing-left .price-wrapper .price-amount sup {
    font-size: 16px;
    top: -34px;
}

.advance-pricing .pricing-left .btn-default {
    width: 100%;
    margin: 10px 0;
}

.advance-pricing .pricing-left .rating {
    text-align: center;
    margin-top: 5px;
}

.advance-pricing .pricing-left .rating a {
    font-weight: 700;
    color: #f1b304;
}

.advance-pricing .pricing-left .rating a svg {
    fill: #f1b304;
}

.advance-pricing .pricing-left .subtitle {
    display: block;
    color: var(--color-body);
    text-align: center;
}

.advance-pricing .pricing-right {
    padding: 60px 30px;
}

.advance-pricing .pricing-right .price-title {
    font-size: 20px;
    margin-bottom: 10px;
}

.advance-pricing .pricing-right .price-title li {
    font-size: 16px;
    margin: 8px 0;
}

.rainbow-comment-form .section-title span.subtitle {
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 18px;
    font-size: 16px;
    border-radius: 4px;
    margin-bottom: 12px;
}

.rainbow-comment-form .section-title .title {
    font-size: 50px;
    font-weight: 600;
    line-height: 62px;
    margin-bottom: 20px;
    color: var(--color-white);
}

iframe {
    border-radius: 10px;
}

.rnform-group {
    margin-bottom: 20px;
}

.rnform-group input {
    border: 2px solid var(--color-border);
    border-radius: 4px;
    height: 50px;
    font-size: 16px;
}

.rainbow-comment-form .inner .rnform-group textarea {
    min-height: 193px;
    border: 2px solid var(--color-border);
    border-radius: 4px;
    resize: none;
    padding: 15px;
    font-size: 16px;
}

/*--------------------------
    Swicher Styles  
----------------------------*/

.active-dark-mode .my_switcher ul li .setColor.dark {
    display: none;
}

.active-light-mode .my_switcher ul li .setColor.light {
    display: none;
}

.active-light-mode .my_switcher ul li a.dark img {
    display: block;
}

.my_switcher ul li a.dark img {
    display: none;
    filter: brightness(100);
}

.my_switcher {
    border: 0 none;
    padding: 0;
    margin-left: 10px;
    width: 40px;
    background: var(--color-secondary);
    display: flex;
    justify-content: center;
    height: 40px;
    align-items: center;
    border-radius: 100%;
}

@media only screen and (max-width: 767px) {
    .my_switcher {
        margin-left: 5px;
    }
}

.my_switcher ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 100%;
}

.my_switcher ul li {
    margin-top: 0;
    margin-bottom: 0;
}

.my_switcher ul li a {
    display: block;
    position: relative;
    z-index: 2;
    background: var(--color-secondary);
    width: 40px;
    height: 40px;
    border-radius: 100%;
}

.my_switcher ul li a img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.my_switcher ul li a.active {
    display: none;
}

/*------------------------
    Back to Top  
--------------------------*/

.rainbow-back-top {
    position: fixed;
    bottom: 30px;
    right: 30px;
    cursor: pointer;
    z-index: 999;
    width: 50px;
    height: 50px;
    line-height: 46px;
    border-radius: 50%;
    text-align: center;
    z-index: 999 !important;
    background-color: #0f0f11;
    background-color: var(--color-blackest);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
}

.rainbow-back-top i {
    color: #fff;
    transition: 0.3s;
    font-size: 20px;
}

.rainbow-back-top:hover i {
    color: var(--color-primary) !important;
}

/*--------------------------
    About Styles  
---------------------------*/

.about-style-4 .title {
    font-weight: 400;
    margin-bottom: 35px;
}

.about-style-4 .title strong {
    color: var(--color-primary);
}

.about-style-4 .feature-list {
    padding: 0;
    margin: 0;
    list-style: none;
}

.about-style-4 .feature-list li {
    display: flex;
}

.about-style-4 .feature-list li .icon {
    background: var(--color-primary);
    width: 30px;
    height: 30px;
    border-radius: 100%;
    color: var(--color-white);
    text-align: center;
    min-width: 30px;
}

.about-style-4 .feature-list li .icon i {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.about-style-4 .feature-list li .title-wrapper {
    margin-left: 13px;
}

.about-style-4 .feature-list li .title-wrapper .title {
    font-size: 18px;
    margin: 0;
}

.about-style-4 .feature-list li .title-wrapper .text {
    font-size: 16px;
    margin-bottom: 20px;
    max-width: 80%;
    margin-top: 13px;
}

.about-style-5 {
    position: relative;
    z-index: 2;
}

.about-style-5 .about-wrapper {
    margin-top: -150px;
    background: var(--color-blackest);
    border-radius: 10px;
    padding: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-style-5 .about-wrapper {
        margin-top: 80px;
        padding: 15px 15px 30px 15px;
    }
}

@media only screen and (max-width: 767px) {
    .about-style-5 .about-wrapper {
        margin-top: 80px;
        padding: 15px 15px 30px 15px;
    }
}

.about-style-5 .about-wrapper .thumbnail img {
    border-radius: 10px;
}

.about-style-5 .about-wrapper .content .inner {
    padding: 0 40px;
}

@media only screen and (max-width: 767px) {
    .about-style-5 .about-wrapper .content .inner {
        padding: 0;
    }
}

.about-style-5 .about-wrapper .content .inner .title {
    font-size: 30px;
    margin-bottom: 13px;
}

.about-style-5 .about-wrapper .content .inner p {
    margin-bottom: 20px;
    width: 80%;
}

.contact-address {
    padding: 0;
    margin: 0;
    list-style: none;
}

.contact-address li {
    font-size: 16px;
}

.contact-address li i {
    color: var(--color-primary);
    margin-right: 10px;
}

.about-style-6 .rbt-progress-style-1,
.about-style-6 .wrapper {
    position: relative;
    z-index: 2;
    max-width: 850px;
    margin: 0 auto;
    border-radius: 10px;
}

.about-style-6 .wrapper {
    padding: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-style-6 .wrapper {
        padding: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .about-style-6 .wrapper {
        padding: 20px;
    }
}

.about-style-6 .wrapper::after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    background: var(--color-lessdark);
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.6;
    border-radius: 10px;
}

.about-style-6 .thumbnail img {
    max-height: 200px;
}

.about-style-6 .subtitle {
    font-size: 18px;
    font-weight: 500;
}

.about-style-6 .maintitle {
    font-size: 30px;
    line-height: 1.3;
    margin-top: 17px;
    margin-bottom: 25px;
}

/*---------------------------
    Newsletter Styles  
----------------------------*/

.border-top-bottom {
    border-top: 1px solid var(--color-border);
    border-bottom: 1px solid var(--color-border);
}

.newsletter-area .border-top-bottom {
    border-top: 1px solid var(--color-border);
    border-bottom: 1px solid var(--color-border);
}

.rainbow-newsletter-default .newsletter-wrapper {
    padding: 40px 0;
}

.rainbow-newsletter-default .newsletter-section-title .title {
    color: var(--color-body);
    margin-bottom: 7px;
}

.rainbow-newsletter-default .newsletter-section-title .description {
    color: var(--color-body);
    margin-bottom: 0;
}

.rainbow-newsletter-default .rainbow-newsletter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0 -10px;
}

@media only screen and (max-width: 575px) {
    .rainbow-newsletter-default .rainbow-newsletter {
        display: block;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .rainbow-newsletter-default .rainbow-newsletter {
        justify-content: flex-start;
    }
}

@media only screen and (max-width: 767px) {
    .rainbow-newsletter-default .rainbow-newsletter {
        justify-content: flex-start;
    }
}

.rainbow-newsletter-default .rainbow-newsletter .form-group {
    margin-bottom: 0;
    margin: 0 10px;
}

.rainbow-newsletter-default .rainbow-newsletter .form-group input {
    height: 55px;
    padding: 0 24px;
    min-width: 500px;
    background: var(--color-blackest);
    border: 0 none;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .rainbow-newsletter-default .rainbow-newsletter .form-group input {
        min-width: 400px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .rainbow-newsletter-default .rainbow-newsletter .form-group input {
        min-width: 400px;
    }
}

@media only screen and (max-width: 767px) {
    .rainbow-newsletter-default .rainbow-newsletter .form-group input {
        min-width: 350px;
    }
}

@media only screen and (max-width: 575px) {
    .rainbow-newsletter-default .rainbow-newsletter .form-group input {
        min-width: 100%;
        margin-bottom: 20px;
    }
}

.rainbow-newsletter-default .rainbow-newsletter .form-group .btn-default {
    height: 55px;
}

/*------------------------
    Featue Styles  
--------------------------*/

.rbt-feature-main {
    padding-bottom: 0;
    border-radius: 10px;
}

@media only screen and (max-width: 767px) {
    .rbt-feature-main {
        padding: 30px;
    }
}

.rbt-feature-main .feature-left-top {
    max-width: 500px;
}

.rbt-feature-main .feature-left-bottom {
    max-width: 500px;
}

.rbt-feature-main .feature-left-bottom .button-group {
    margin-bottom: 50px;
}

/**************************************
            Blog 
***************************************/

/*----------------------
    Blog Styles  
-----------------------*/

.rainbow-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: var(--color-blackest);
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: var(--radius);
    overflow: hidden;
    transition: all 0.5s;
}

.rainbow-card .inner .thumbnail {
    overflow: hidden;
}

.rainbow-card .inner .thumbnail a {
    display: block;
    overflow: hidden;
}

.rainbow-card .inner .thumbnail a img {
    width: 100%;
    transition: 0.5s;
    overflow: hidden;
}

.rainbow-card .inner .content {
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
    padding-top: 15px;
}

@media only screen and (max-width: 767px) {
    .rainbow-card .inner .content {
        padding: 30px 20px;
    }
}

.rainbow-card .inner .content .descriptiion {
    margin-bottom: 0;
    margin-bottom: 13px;
}

.rainbow-card .inner .content .title {
    font-size: 20px;
    line-height: 1.5;
    transition: all 0.5s;
    margin-bottom: 0;
    margin-top: 15px;
    flex-basis: 100%;
}

.rainbow-card.card-list-view {
    height: 100%;
}

.rainbow-card.card-list-view .inner {
    display: flex;
    height: 100%;
}

@media only screen and (max-width: 575px) {
    .rainbow-card.card-list-view .inner {
        display: block;
    }
}

.rainbow-card.card-list-view .inner .thumbnail {
    flex-basis: 46%;
}

.rainbow-card.card-list-view .inner .thumbnail a {
    display: block;
    height: 100%;
}

.rainbow-card.card-list-view .inner .thumbnail a img {
    width: auto;
    object-fit: cover;
    height: 100%;
}

.rainbow-card.card-list-view .inner .content {
    flex-basis: 54%;
}

.rainbow-card.card-list-view .inner .content .title {
    margin-bottom: 10px;
}

.rainbow-card.card-list-view .inner .content .descriptiion {
    margin-bottom: 13px;
    margin-top: 12px;
}

.rainbow-card.content-transparent {
    position: relative;
    border: 0 none;
}

.rainbow-card.content-transparent .content {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.6) 50%, rgba(0, 0, 0, 0.9) 100%);
    padding-top: 60px;
}

@media only screen and (max-width: 767px) {
    .rainbow-card.content-transparent .content {
        padding-top: 20px;
    }
}

.rainbow-card.content-transparent .content .title {
    margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
    .rainbow-card.content-transparent .content .title {
        font-size: 17px;
    }
}

.rainbow-card.content-transparent .content .title a {
    color: var(--color-white);
}

.rainbow-card.content-transparent .content .title:hover a {
    color: var(--color-primary);
}

.rainbow-card.content-transparent .content .rainbow-meta-list li {
    color: #acaeb1;
}

.rainbow-card.content-transparent .content .rainbow-meta-list li a {
    color: #acaeb1;
}

.rainbow-card.content-transparent.post-large .content .title {
    font-size: 32px;
}

@media only screen and (max-width: 767px) {
    .rainbow-card.content-transparent.post-large .content .title {
        font-size: 19px;
    }
}

.rainbow-card:hover .inner .thumbnail a img {
    transform: scale(1.1);
}

.rainbow-meta-list {
    font-size: 1.4rem;
    line-height: 2.2rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.rainbow-meta-list li {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--color-gray);
    font-size: 14px;
}

.rainbow-meta-list li a {
    color: var(--color-gray);
    transition: 0.5s;
}

.rainbow-meta-list li.separator {
    margin: 0 0.8rem;
}

.blog-search {
    position: relative;
}

.blog-search input {
    height: 60px;
    padding: 0 20px;
    color: var(--color-body);
    padding-right: 80px;
}

.blog-search .search-button {
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
    border: 0 none;
    background: var(--color-primary-light);
    height: 47px;
    padding: 0 20px;
    border-radius: 3px;
    text-align: center;
    font-size: 20px;
}

.blog-search .search-button i {
    color: var(--color-white);
    font-weight: 400;
}

.rbt-single-widget {
    border-radius: 4px;
    padding: 20px 20px;
    border: 1px solid var(--color-border);
    padding-top: 0;
}

.rbt-single-widget:first-child {
    margin-top: 0 !important;
}

.rbt-single-widget input,
.rbt-single-widget select {
    border: var(--border-width) solid var(--color-border);
}

.rbt-single-widget.widget_search {
    padding: 0;
    border: 0 none;
}

.rbt-single-widget.widget_search .title {
    margin: 0;
}

.rbt-single-widget p {
    font-size: 16px;
    line-height: 28px;
    color: #717173;
}

.rbt-single-widget .title {
    line-height: 26px;
    position: relative;
    padding-bottom: 6px;
    font-size: 18px;
    font-weight: 500;
    color: var(--color-white);
    background-color: var(--color-blackest);
    margin: -1px -22px 0;
    padding: 14px 24px;
    border-radius: 4px;
}

.rbt-single-widget .title a {
    color: var(--color-primary);
}

.rbt-single-widget .title a.rsswidget img {
    height: 13px;
    margin-right: 3px;
}

.rbt-single-widget .title img {
    vertical-align: 0px;
}

.rbt-single-widget ul {
    padding-left: 0;
    margin-bottom: -10px;
    list-style: none;
    padding-right: 0;
}

.rbt-single-widget ul ul {
    margin-bottom: 0;
}

.rbt-single-widget ul li {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 28px;
    color: #717173;
}

.rbt-single-widget ul li a {
    transition: all 0.3s ease 0s;
    text-decoration: none;
    color: var(--color-heading);
    display: initial;
}

.rbt-single-widget ul li a:hover {
    color: var(--color-primary);
}

.rbt-single-widget.widget_categories ul li a {
    display: flex;
    justify-content: space-between;
}

.rbt-single-widget.widget_recent_comments ul li,
.rbt-single-widget.widget_recent_entries ul li,
.rbt-single-widget.widget_archive ul li,
.rbt-single-widget.widget_categories ul li,
.rbt-single-widget.widget_meta ul li,
.rbt-single-widget.widget_pages ul li,
.rbt-single-widget.widget_nav_menu ul li {
    position: relative;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 0;
    margin-bottom: 0;
    color: var(--color-body);
}

.rbt-single-widget.widget_recent_comments ul li a,
.rbt-single-widget.widget_recent_entries ul li a,
.rbt-single-widget.widget_archive ul li a,
.rbt-single-widget.widget_categories ul li a,
.rbt-single-widget.widget_meta ul li a,
.rbt-single-widget.widget_pages ul li a,
.rbt-single-widget.widget_nav_menu ul li a {
    color: var(--color-body);
}

.rbt-single-widget.widget_recent_comments ul li a:hover,
.rbt-single-widget.widget_recent_entries ul li a:hover,
.rbt-single-widget.widget_archive ul li a:hover,
.rbt-single-widget.widget_categories ul li a:hover,
.rbt-single-widget.widget_meta ul li a:hover,
.rbt-single-widget.widget_pages ul li a:hover,
.rbt-single-widget.widget_nav_menu ul li a:hover {
    color: var(--color-primary);
}

.rbt-single-widget.widget_recent_comments ul li span,
.rbt-single-widget.widget_recent_entries ul li span,
.rbt-single-widget.widget_archive ul li span,
.rbt-single-widget.widget_categories ul li span,
.rbt-single-widget.widget_meta ul li span,
.rbt-single-widget.widget_pages ul li span,
.rbt-single-widget.widget_nav_menu ul li span {
    color: var(--color-body);
}

.rbt-single-widget.widget_recent_comments ul li:hover a span,
.rbt-single-widget.widget_recent_entries ul li:hover a span,
.rbt-single-widget.widget_archive ul li:hover a span,
.rbt-single-widget.widget_categories ul li:hover a span,
.rbt-single-widget.widget_meta ul li:hover a span,
.rbt-single-widget.widget_pages ul li:hover a span,
.rbt-single-widget.widget_nav_menu ul li:hover a span {
    color: var(--color-primary);
}

.rbt-single-widget.widget_recent_comments ul li+li,
.rbt-single-widget.widget_recent_entries ul li+li,
.rbt-single-widget.widget_archive ul li+li,
.rbt-single-widget.widget_categories ul li+li,
.rbt-single-widget.widget_meta ul li+li,
.rbt-single-widget.widget_pages ul li+li,
.rbt-single-widget.widget_nav_menu ul li+li {
    border-top: 1px solid var(--color-border);
}

.rbt-single-widget.widget_recent_comments>ul>li,
.rbt-single-widget.widget_recent_entries>ul>li,
.rbt-single-widget.widget_archive>ul>li,
.rbt-single-widget.widget_categories>ul>li,
.rbt-single-widget.widget_meta>ul>li,
.rbt-single-widget.widget_pages>ul>li {
    margin-top: 0;
    margin-bottom: 0;
}

.rbt-single-widget.widget_recent_comments>ul>li:first-child,
.rbt-single-widget.widget_recent_entries>ul>li:first-child,
.rbt-single-widget.widget_archive>ul>li:first-child,
.rbt-single-widget.widget_categories>ul>li:first-child,
.rbt-single-widget.widget_meta>ul>li:first-child,
.rbt-single-widget.widget_pages>ul>li:first-child {
    margin-top: 0;
}

.rbt-single-widget.widget_recent_comments>ul>li:last-child,
.rbt-single-widget.widget_recent_entries>ul>li:last-child,
.rbt-single-widget.widget_archive>ul>li:last-child,
.rbt-single-widget.widget_categories>ul>li:last-child,
.rbt-single-widget.widget_meta>ul>li:last-child,
.rbt-single-widget.widget_pages>ul>li:last-child {
    margin-bottom: 10px;
}

.rbt-single-widget.widget_recent_entries .inner a {
    color: var(--color-white);
    margin-bottom: 4px;
}

.rbt-single-widget.widget_recent_entries .inner span {
    color: var(--color-body);
    font-size: 14px;
    letter-spacing: 0.5px;
}

.alignwide {
    margin-left: -220px;
    margin-right: -220px;
    max-width: 1400px;
    width: auto;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .alignwide {
        margin-left: 0;
        margin-right: 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .alignwide {
        margin-left: 0;
        margin-right: 0;
    }
}

@media only screen and (max-width: 767px) {
    .alignwide {
        margin-left: 0;
        margin-right: 0;
    }
}

.alignfull {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    max-width: 100vw;
    width: 100vw;
}

.tagcloud,
.wp-block-tag-cloud {
    margin: -3px;
    padding-left: 0;
}

.tagcloud a,
.wp-block-tag-cloud a {
    background: transparent;
    display: inline-block;
    height: 40px;
    color: var(--color-body);
    font-size: 16px !important;
    line-height: 35px;
    text-decoration: none;
    margin: 3px;
    border: 2px solid var(--color-border);
    padding: 0 20px;
    border-radius: 3px;
}

.tagcloud a:hover,
.wp-block-tag-cloud a:hover {
    background: var(--color-primary);
    color: #ffffff;
    border-color: var(--color-primary);
}

/*------------------------------
    Blog Details 
----------------------------------*/

.post-page-banner .rainbow-meta-list {
    justify-content: center;
}

.post-page-banner .rainbow-meta-list li {
    color: var(--color-body);
    font-size: 20px;
    margin: 0 15px;
    margin-top: 38px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .post-page-banner .rainbow-meta-list li {
        font-size: 16px;
        margin-top: 8px;
    }
}

@media only screen and (max-width: 767px) {
    .post-page-banner .rainbow-meta-list li {
        font-size: 16px;
        margin-top: 8px;
    }
}

.post-page-banner .rainbow-meta-list li svg {
    margin-right: 10px;
}

.post-page-banner .rainbow-meta-list li a {
    color: var(--color-body);
    transition: 0.3s;
}

.post-page-banner .rainbow-meta-list li a:hover {
    color: var(--color-primary);
}

.post-page-banner .category-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    justify-content: center;
    margin: -10px;
    padding: 0;
    margin-top: 14px;
}

.post-page-banner .category-list li {
    margin: 10px;
    position: relative;
}

.post-page-banner .category-list li::after {
    position: absolute;
    content: ",";
    right: -9px;
}

.post-page-banner .category-list li:last-child::after {
    display: none;
}

.blog-details-content .category-meta {
    display: flex;
    padding: 42px 0;
    border-top: 1px solid var(--color-border);
    border-bottom: 1px solid var(--color-border);
    flex-wrap: wrap;
}

.blog-details-content .category-meta span.text {
    color: var(--color-white);
    font-size: 22px;
    display: inline-block;
    margin: 10px 0;
    margin-top: 0;
}

/**************************************
    Footer Styles
***************************************/

/*-----------------------
    Footer Style Here  
------------------------*/

.logo a img {
    max-height: 55px;
}

.footer-style-1 {
    background-color: var(--color-darkest);
    border-top: 1px solid var(--color-border);
    background-image: -webkit-gradient(linear, left bottom, left top, from(var(--color-black)), to(var(--color-blacker)));
    background-image: linear-gradient(0deg, var(--color-black), var(--color-blacker));
}

.footer-style-1 .footer-top {
    padding: 120px 0;
}

.footer-style-1 .footer-top .rainbow-footer-widget .text-big {
    font-size: 21px;
    margin-top: 30px;
    padding-right: 13%;
    line-height: 37px;
    font-weight: 500;
}

.footer-style-1 .footer-top .rainbow-footer-widget .title {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 10px;
}

.footer-style-1 .footer-top .rainbow-footer-widget .subtitle {
    font-weight: 400;
    color: var(--color-body);
    font-size: 16px;
    padding-right: 31%;
    line-height: 28px;
    margin-bottom: 32px;
}

.footer-style-1 .footer-top .rainbow-footer-widget .inner {
    margin-top: 13px;
}

.footer-style-1 .footer-top .rainbow-footer-widget .footer-link {
    list-style: none;
    padding: 0;
}

.footer-style-1 .footer-top .rainbow-footer-widget .footer-link li {
    margin-top: 5px;
    margin-bottom: 5px;
}

.footer-style-1 .footer-top .rainbow-footer-widget .footer-link li a {
    font-size: 14px;
    display: block;
    color: var(--color-body);
    line-height: 25px;
}

.newsletter-form input {
    flex-grow: 1;
    width: auto;
    margin-right: 10px;
    height: 50px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.footer-style-default {
    border-top: 1px solid var(--color-border);
    background-color: var(--color-darkest);
    background-image: -webkit-gradient(linear, left bottom, left top, from(var(--color-black)), to(var(--color-blacker)));
    background-image: linear-gradient(0deg, var(--color-black), var(--color-blacker));
}

.footer-style-default .footer-top {
    padding: 100px 0;
    padding-bottom: 70px;
}

.footer-style-default .footer-top .rainbow-footer-widget .text-big {
    font-size: 21px;
    margin-top: 30px;
    padding-right: 13%;
    line-height: 1.7;
    font-weight: 500;
    color: var(--color-body);
    margin-bottom: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-style-default .footer-top .rainbow-footer-widget .text-big {
        font-size: 20px;
        padding-right: 0;
    }
}

@media only screen and (max-width: 767px) {
    .footer-style-default .footer-top .rainbow-footer-widget .text-big {
        font-size: 20px;
        padding-right: 0;
    }
}

.footer-style-default .footer-top .rainbow-footer-widget .title {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 10px;
}

.footer-style-default .footer-top .rainbow-footer-widget .subtitle {
    font-weight: 400;
    color: var(--color-body);
    font-size: 16px;
    padding-right: 31%;
    line-height: 28px;
    margin-bottom: 32px;
}

@media only screen and (max-width: 767px) {
    .footer-style-default .footer-top .rainbow-footer-widget .subtitle {
        padding-right: 0;
    }
}

.footer-style-default .footer-top .rainbow-footer-widget .inner {
    margin-top: 13px;
}

.footer-style-default .footer-top .rainbow-footer-widget .footer-link {
    list-style: none;
    padding: 0;
}

.footer-style-default .footer-top .rainbow-footer-widget .footer-link li {
    margin-top: 5px;
    margin-bottom: 5px;
}

.footer-style-default .footer-top .rainbow-footer-widget .footer-link li a {
    font-size: 14px;
    display: inline-block;
    color: var(--color-body);
    line-height: 25px;
    transition: 0.3s;
    text-transform: capitalize;
}

.footer-style-default .footer-top .rainbow-footer-widget .footer-link li a:hover {
    color: var(--color-primary);
}

.footer-style-default .footer-top .rainbow-footer-widget .footer-link li a:hover::after {
    width: 100%;
    left: 0;
    right: auto;
}

.footer-style-default.variation-two {
    border-top: 0;
}

.footer-style-default.no-border {
    border-top: 0;
}

/*--------------------------
    Copyright Area  
---------------------------*/

.copyright-style-one {
    background: var(--color-darkest);
    padding: 20px 0;
    border-top: 1px solid var(--color-border);
}

.copyright-style-one .copyright-text {
    margin-bottom: 0;
    font-size: 14px;
}

.copyright-style-one .copyright-text a {
    color: var(--color-body);
}

.copyright-style-one .ft-menu {
    padding: 0;
    margin: 0 -10px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

@media only screen and (max-width: 767px) {
    .copyright-style-one .ft-menu {
        justify-content: center;
    }
}

.copyright-style-one .ft-menu li {
    margin: 0;
    padding: 0 10px;
    position: relative;
}

.copyright-style-one .ft-menu li+li::after {
    position: absolute;
    content: "";
    height: 14px;
    width: 1px;
    background: var(--color-border);
    left: -1px;
    top: 50%;
    transform: translateY(-50%);
}

.copyright-style-one .ft-menu li a {
    font-size: 14px;
}

.copyright-style-one.variation-two {
    background: var(--color-blackest);
}

.copyright-style-one.no-border {
    border-top: 0;
}

/**************************************
            Template
***************************************/

/*------------------------
    Banner Styles  
-------------------------*/

.slider-dot.rainbow-slick-dot .slick-dots {
    bottom: 60px;
}

.height-850 {
    height: 850px;
    display: flex;
    align-items: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .height-850 {
        height: auto;
        padding: 150px 0;
    }
}

@media only screen and (max-width: 767px) {
    .height-850 {
        height: auto;
        padding: 100px 0;
    }
}

.height-650 {
    height: 650px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
}

@media only screen and (max-width: 767px) {
    .height-650 {
        height: auto;
        padding: 100px 0;
    }
}

.height-750 {
    height: 750px;
    display: flex;
    align-items: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .height-750 {
        height: auto;
        padding: 150px 0;
    }
}

@media only screen and (max-width: 767px) {
    .height-750 {
        height: auto;
        padding: 100px 0;
    }
}

.height-950 {
    height: 730px;
    display: flex;
    align-items: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .height-950 {
        height: auto;
        padding: 150px 0;
    }
}

@media only screen and (max-width: 767px) {
    .height-950 {
        height: auto;
        padding: 60px 0;
    }
}

.display-one {
    font-size: 75px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .display-one {
        font-size: 70px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .display-one {
        font-size: 65px;
    }
}

@media only screen and (max-width: 767px) {
    .display-one {
        font-size: 50px;
    }
}

@media only screen and (max-width: 575px) {
    .display-one {
        font-size: 30px;
    }
}

@media only screen and (max-width: 575px) {
    .display-one br {
        display: none;
    }
}

.display-two {
    font-size: 85px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .display-two {
        font-size: 70px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .display-two {
        font-size: 65px;
    }
}

@media only screen and (max-width: 767px) {
    .display-two {
        font-size: 65px;
    }
}

@media only screen and (max-width: 575px) {
    .display-two {
        font-size: 46px;
    }
}

@media only screen and (max-width: 767px) {
    .collaborate {
        padding-bottom: 30px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .collaborate .display-two {
        font-size: 40px;
    }
}

@media only screen and (max-width: 767px) {
    .collaborate .display-two {
        font-size: 40px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .collaborate .description {
        font-size: 14px;
    }
}

@media only screen and (max-width: 767px) {
    .collaborate .description {
        font-size: 14px;
    }
}

.bg-overlay {
    position: relative;
}

.bg-overlay::before {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 1;
    top: 0;
    left: 0;
    background-color: transparent;
    background-image: linear-gradient(90deg, #060606 0%, #06060600 90%);
    content: "";
}

.bg-overlay-solid {
    position: relative;
    z-index: 1;
}

.bg-overlay-solid::before {
    position: absolute;
    height: 100%;
    width: 40%;
    opacity: 1;
    top: 0;
    left: 0;
    content: "";
    background: var(--color-dark);
    z-index: -1;
}

.bg-overlay-solid::after {
    position: absolute;
    width: 500px;
    height: 500px;
    border-radius: 1000px;
    opacity: 0.5;
    filter: blur(100px);
    left: auto;
    top: -250px;
    left: -250px;
    bottom: auto;
    background-image: linear-gradient(45deg, var(--color-secondary), var(--color-tertiary));
    content: "";
    z-index: -1;
}

.frame-image {
    padding: 20px;
    position: relative;
    right: 0;
    border-radius: 10px;
    transition: var(--transition);
    height: 100%;
    background-color: var(--color-blackest);
}

.frame-image img {
    width: 100%;
}

.frame-image:hover {
    transform: translateY(-5px);
}

.slider-area .inner a.btn-default.btn-border,
.slider-area .inner button.btn-default.btn-border {
    border-color: var(--color-border);
    color: var(--color-white);
}

/*-----------------------
    Slider Style One  
-------------------------*/

.slider-style-1 .inner .subtitle {
    font-family: var(--font-primary);
    font-size: 18px;
    letter-spacing: 0.5px;
    display: block;
    margin-bottom: 18px;
    color: var(--color-body);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-style-1 .inner .subtitle {
        font-size: 16px;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 767px) {
    .slider-style-1 .inner .subtitle {
        font-size: 16px;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 575px) {
    .slider-style-1 .inner .subtitle {
        font-size: 14px;
    }
}

.slider-style-1 .inner .title {
    font-family: var(--font-primary);
    margin-bottom: 25px;
    display: inline-block;
}

.slider-style-1 .inner .title span {
    display: inline-block;
}

.slider-style-1 .inner .title span label {
    margin-bottom: 0;
}

.slider-style-1 .inner .description {
    font-family: var(--font-primary);
    padding: 0 22%;
    font-size: 25px;
    line-height: 1.7;
    margin-bottom: 40px;
    color: var(--color-body);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-style-1 .inner .description {
        padding: 0 14%;
        font-size: 22px;
        margin-bottom: 35px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-style-1 .inner .description {
        padding: 0 1%;
        font-size: 20px;
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 767px) {
    .slider-style-1 .inner .description {
        padding: 0 1%;
        font-size: 20px;
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 575px) {
    .slider-style-1 .inner .description {
        font-size: 18px;
        margin-bottom: 25px;
    }
}

.slider-style-1 .inner.text-left .description {
    padding: 0;
}

.slider-style-1 .inner .list-icon li {
    color: var(--color-body);
}

.slider-style-1 .inner .list-icon li .icon {
    background-color: var(--color-blackest) !important;
}

.slider-style-1 .inner .header-caption span {
    color: var(--color-white);
    font-size: 85px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-style-1 .inner .header-caption span {
        font-size: 70px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-style-1 .inner .header-caption span {
        font-size: 65px;
    }
}

@media only screen and (max-width: 767px) {
    .slider-style-1 .inner .header-caption span {
        font-size: 65px;
    }
}

@media only screen and (max-width: 575px) {
    .slider-style-1 .inner .header-caption span {
        font-size: 35px;
    }
}

.slider-style-1.variation-default .inner {
    /* padding-top: 80px; */
}

.slider-style-1.variation-2 {
    position: relative;
}

.slider-style-1.variation-2 .thumbnail img {
    transform: scale(1.1);
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .slider-style-1.variation-3 .inner .display-two {
        font-size: 75px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-style-1.variation-3 .inner .display-two {
        font-size: 58px;
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 767px) {
    .slider-style-1.variation-3 .inner .display-two {
        font-size: 47px;
    }
}

@media only screen and (max-width: 575px) {
    .slider-style-1.variation-3 .inner .display-two {
        font-size: 36px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .slider-style-1.variation-3 .inner .description {
        padding: 0px 10%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-style-1.variation-3 .inner .description {
        font-size: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .slider-style-1.variation-3 .inner .description {
        font-size: 16px;
    }
}

.slider-style-2 {
    padding-top: 130px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-style-2 {
        padding-top: 150px;
    }
}

@media only screen and (max-width: 767px) {
    .slider-style-2 {
        padding-top: 150px;
    }
}

.slider-style-2 .inner .subtitle {
    font-weight: 700;
    letter-spacing: 0.5px;
    line-height: 18px;
    font-size: 14px;
    border-radius: 4px;
    margin-bottom: 12px;
    display: inline-block;
    padding: 7px 14px;
    background-color: var(--color-blackest);
    box-shadow: var(--shadow-lighter);
}

.slider-style-2 .inner .title {
    font-family: var(--font-primary);
    margin-bottom: 25px;
    display: block;
    color: var(--color-white);
}

@media only screen and (max-width: 767px) {
    .slider-style-2 .inner .title.display-one {
        font-size: 40px;
    }
}

.slider-style-2 .inner .list-icon li {
    color: var(--color-body);
}

.slider-style-2.variation-2 {
    padding-top: 79px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-style-2.variation-2 {
        padding-top: 170px;
    }
}

@media only screen and (max-width: 767px) {
    .slider-style-2.variation-2 {
        padding-top: 170px;
    }
}

.slider-style-2.variation-2 .inner .title {
    font-weight: 500;
    font-size: 75px;
    margin-top: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-style-2.variation-2 .inner .title {
        font-size: 54px;
    }
}

@media only screen and (max-width: 767px) {
    .slider-style-2.variation-2 .inner .title {
        font-size: 54px;
    }
}

@media only screen and (max-width: 575px) {
    .slider-style-2.variation-2 .inner .title {
        font-size: 40px;
    }
}

.slider-style-2.variation-2 .inner .tag-title {
    color: var(--color-secondary);
    margin: 0;
    letter-spacing: 1.5px;
    padding-left: 129px;
    position: relative;
    margin-top: 50px;
}

.slider-style-2.variation-2 .inner .tag-title::before {
    position: absolute;
    content: "";
    left: 0;
    width: 100px;
    height: 2px;
    background: var(--color-secondary);
    top: 50%;
    transform: translateY(-50%);
}

.slider-style-2.variation-3 {
    padding-top: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-style-2.variation-3 {
        padding-top: 180px;
    }
}

@media only screen and (max-width: 767px) {
    .slider-style-2.variation-3 {
        padding-top: 180px;
    }
}

.slider-style-2.variation-3 .inner .title {
    font-family: var(--font-primary);
    margin-bottom: 25px;
    display: block;
    font-size: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-style-2.variation-3 .inner .title {
        font-size: 60px;
    }
}

@media only screen and (max-width: 767px) {
    .slider-style-2.variation-3 .inner .title {
        font-size: 50px;
    }

    .slider-style-2.variation-3 .inner .title br {
        display: none;
    }
}

.slider-style-2.variation-3 .inner .description {
    font-size: 20px;
    line-height: 37px;
    margin-bottom: 0;
    color: var(--color-body);
}

@media only screen and (max-width: 767px) {
    .slider-style-2.variation-3 .inner .description {
        font-size: 18px;
        line-height: 30px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-style-3 {
        padding: 100px 0;
    }
}

@media only screen and (max-width: 767px) {
    .slider-style-3 {
        padding: 100px 0;
    }
}

.slider-style-3 .inner .title {
    font-family: var(--font-primary);
    margin-bottom: 25px;
    display: block;
    font-size: 130px;
    line-height: 1.1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-style-3 .inner .title {
        font-size: 100px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-style-3 .inner .title {
        font-size: 80px;
    }
}

@media only screen and (max-width: 767px) {
    .slider-style-3 .inner .title {
        margin-bottom: 20px;
        font-size: 50px;
    }
}

.slider-style-3 .inner .description {
    font-size: 22px;
    line-height: 43px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-style-3 .inner .description {
        font-size: 20px;
        line-height: 38px;
    }
}

@media only screen and (max-width: 767px) {
    .slider-style-3 .inner .description {
        font-size: 18px;
        line-height: 30px;
    }
}

.slider-style-3 .thumbnail {
    position: relative;
    z-index: 2;
}

.slider-style-3 .thumbnail::after {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: calc(100% - 130px);
    content: "";
    left: 50%;
    bottom: 0;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background: var(--color-blackest);
    box-shadow: var(--shadow-1);
    border-radius: 6px;
}

.slider-style-4 .inner {
    padding-top: 80px;
}

@media only screen and (max-width: 767px) {
    .slider-style-4 .inner {
        padding-top: 50px;
    }
}

.slider-style-4 .inner>* {
    animation-name: fadeOutUp;
    animation-fill-mode: both;
    animation-duration: 1s;
}

.slider-style-4 .inner .title {
    font-family: var(--font-primary);
    margin-bottom: 25px;
    display: block;
    font-size: 130px;
    line-height: 1.1;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    color: var(--color-white);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-style-4 .inner .title {
        font-size: 100px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-style-4 .inner .title {
        font-size: 100px;
    }
}

@media only screen and (max-width: 767px) {
    .slider-style-4 .inner .title {
        font-size: 60px;
    }
}

@media only screen and (max-width: 575px) {
    .slider-style-4 .inner .title {
        font-size: 55px;
    }
}

.slider-style-4 .inner .description {
    font-size: 22px;
    line-height: 43px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    color: var(--color-body);
}

@media only screen and (max-width: 767px) {
    .slider-style-4 .inner .description {
        font-size: 20px;
        line-height: 35px;
    }
}

@media only screen and (max-width: 767px) {
    .slider-style-4 .inner .description br {
        display: none;
    }
}

.slider-style-4 .inner .button-group {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.slider-style-4.variation-2 .inner .title {
    font-size: 70px;
}

@media only screen and (max-width: 767px) {
    .slider-style-4.variation-2 .inner .title {
        font-size: 42px;
    }
}

.slider-style-4.variation-2 .bg-overlay::before {
    background: #000000;
    content: "";
    opacity: 0.5;
    background-image: none;
}

.slider-style-4.variation-3 .inner .title {
    font-size: 70px;
}

.slider-style-4.variation-3 .bg-overlay::before {
    background: #000000;
    content: "";
    opacity: 0.5;
    background-image: none;
}

.slider-style-5 .inner .subtitle {
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.5px;
    position: relative;
    padding-right: 20px;
    display: inline-block;
    color: var(--color-white);
}

.slider-style-5 .inner .subtitle::after {
    position: absolute;
    content: "";
    width: 120px;
    height: 2px;
    background: var(--color-pink);
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
}

.slider-style-5 .inner .title {
    font-family: var(--font-primary);
    margin-bottom: 25px;
    display: block;
    font-size: 64px;
    line-height: 1.4;
    color: var(--color-white);
}

@media only screen and (max-width: 767px) {
    .slider-style-5 .inner .title {
        font-size: 40px;
    }
}

@media only screen and (max-width: 575px) {
    .slider-style-5 .inner .title {
        font-size: 32px;
    }
}

@media only screen and (max-width: 767px) {
    .slider-style-5 .inner .title br {
        display: none;
    }
}

.slider-style-6 {
    background-color: var(--color-blackest);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-style-6 {
        overflow: hidden;
        padding: 100px 0;
        padding-top: 170px;
    }
}

.slider-style-6.shape-right {
    position: relative;
    z-index: 2;
    overflow: hidden;
}

.slider-style-6.shape-right .shape-image {
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0.5;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-style-6.shape-right .shape-image {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .slider-style-6.shape-right .shape-image {
        display: none;
    }
}

.slider-style-6.shape-left {
    position: relative;
}

.slider-style-6.shape-left .inner {
    position: relative;
    z-index: 2;
}

.slider-style-6.shape-left .shape-image {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.5;
    transform: rotate(180deg);
    height: 100%;
    bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-style-6.shape-left .shape-image {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .slider-style-6.shape-left .shape-image {
        display: none;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-style-6.inheader-not-transparent {
        padding-top: 100px;
    }
}

.slider-style-6 .inner .title {
    font-family: var(--font-primary);
    margin-bottom: 25px;
    display: block;
    font-size: 80px;
    line-height: 1.1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-style-6 .inner .title {
        font-size: 62px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-style-6 .inner .title {
        font-size: 60px;
    }
}

@media only screen and (max-width: 767px) {
    .slider-style-6 .inner .title {
        font-size: 45px;
    }
}

@media only screen and (max-width: 767px) {
    .slider-style-6 .inner .title br {
        display: none;
    }
}

.slider-style-6 .inner .description {
    font-size: 22px;
    line-height: 43px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-style-6 .inner .description {
        font-size: 20px;
        line-height: 38px;
    }
}

@media only screen and (max-width: 767px) {
    .slider-style-6 .inner .description {
        font-size: 18px;
        line-height: 30px;
    }
}

.slider-style-6 .inner a.btn-default.btn-border {
    border-color: var(--color-border);
    color: var(--color-white);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-perosonal-portfolio.height-750 {
        padding-top: 180px;
    }
}

@media only screen and (max-width: 767px) {
    .slider-perosonal-portfolio.height-750 {
        padding-top: 180px;
    }
}

.slider-perosonal-portfolio .inner {
    margin-top: -100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-perosonal-portfolio .inner {
        margin-top: 0;
    }
}

@media only screen and (max-width: 767px) {
    .slider-perosonal-portfolio .inner {
        margin-top: 0;
    }
}

.slider-perosonal-portfolio .inner .title {
    font-size: 100px;
    color: var(--color-white);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-perosonal-portfolio .inner .title {
        font-size: 58px;
    }
}

@media only screen and (max-width: 767px) {
    .slider-perosonal-portfolio .inner .title {
        font-size: 46px;
    }
}

.slider-perosonal-portfolio .inner a.btn-default.btn-border,
.slider-perosonal-portfolio .inner button.btn-default.btn-border {
    border-color: var(--color-border);
    color: var(--color-white);
}

.slider-style-7 .inner .title {
    font-size: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider-style-7 .inner .title {
        font-size: 60px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-style-7 .inner .title {
        font-size: 50px;
    }
}

@media only screen and (max-width: 767px) {
    .slider-style-7 .inner .title {
        font-size: 32px;
    }

    .slider-style-7 .inner .title br {
        display: none;
    }
}

.slider-style-8 {
    justify-content: center;
}

.slider-style-8 .inner .title {
    font-size: 100px;
    line-height: 1.1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-style-8 .inner .title {
        font-size: 60px;
    }
}

@media only screen and (max-width: 767px) {
    .slider-style-8 .inner .title {
        font-size: 46px;
    }
}

.slider-style-8 .inner .description {
    font-size: 22px;
    line-height: 43px;
}

@media only screen and (max-width: 767px) {
    .slider-style-8 .inner .description {
        font-size: 20px;
        line-height: 38px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-style-8 .inner .description br {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .slider-style-8 .inner .description br {
        display: none;
    }
}

.slider-style-4 .slick-active .inner>* {
    animation-name: fadeInUp;
}

.slider-style-4 .slick-active .inner .title {
    animation-delay: 0.5s;
}

.slider-style-4 .slick-active .inner .description {
    animation-delay: 1s;
}

.slider-style-4 .slick-active .inner .button-group {
    animation-delay: 1.2s;
}

.slider-bg-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.slider-bg-image.bg-image-1 {
    /* background-image: url(../images/bg/bg-image-1.jpg); */
}

.slider-bg-image.bg-image2 {
    /* background-image: url(../images/call-to-action/bg-image-1.jpg); */
}

.slider-bg-image.bg-image3 {
    /* background-image: url(../images/slider-bg/bg-image-3.jpg); */
}

/* .slider-bg-image.bg-image4 {
    background-image: url(../images/bg-video/bg-image-4.jpg);
}

.slider-bg-image.bg-image5 {
    background-image: url(../images/slider-bg/bg-image-4.jpg);
}

.slider-bg-image.bg-image5 {
    background-image: url(../images/slider-bg/bg-image-5.jpg);
}

.slider-bg-image.bg-image6 {
    background-image: url(../images/slider-bg/bg-image-6.jpg);
}

.slider-bg-image.bg-image10 {
    background-image: url(../images/bg/bg-image-10.jpg);
}

.slider-bg-image.bg-image11 {
    background-image: url(../images/bg/bg-image-11.jpg);
}

.slider-bg-image.bg-image13 {
    background-image: url(../images/slider-bg/bg-image-13.jpg);
}

.slider-bg-image.bg-image14 {
    background-image: url(../images/slider-bg/bg-image-14.jpg);
}

.slider-bg-image.bg-image15 {
    background-image: url(../images/slider-bg/bg-image-15.jpg);
}

.slider-bg-image.bg-image16 {
    background-image: url(../images/slider-bg/bg-image-16.jpg);
} */

/* ----------------------
    Slider Video Bg 
------------------------*/

.slider-video-bg {
    position: relative;
    z-index: 2;
    padding-top: 230px;
    padding-bottom: 284px;
    overflow: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider-video-bg {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}

@media only screen and (max-width: 767px) {
    .slider-video-bg {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}

.slider-video-bg .video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.slider-video-bg .inner {
    position: relative;
    z-index: 2;
}

/*-----------------------------
    Portfolio details  
--------------------------------*/

.rainbow-portfolio-details .content-left .title {
    font-size: 38px;
    margin-bottom: 33px;
}

@media only screen and (max-width: 767px) {
    .rainbow-portfolio-details .content-left .title {
        font-size: 25px;
        margin-bottom: 20px;
    }
}

.rainbow-portfolio-details .content-left .single-list-wrapper {
    margin: -5px;
}

.rainbow-portfolio-details .content-left .single-list-wrapper .single-list {
    margin: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.rainbow-portfolio-details .content-left .single-list-wrapper .single-list+.single-list {
    border-top: 1px solid var(--color-border);
}

.rainbow-portfolio-details .content-left .single-list-wrapper .single-list label {
    font-size: 20px;
    color: #fff;
    display: inline-block;
    padding-right: 16px;
    margin: 0;
}

.rainbow-portfolio-details .content-left .single-list-wrapper .single-list span {
    font-size: 18px;
}

.rainbow-portfolio-details .content-right .subtitle {
    font-size: 25px;
    letter-spacing: 0.5px;
    font-weight: 500;
}

.rainbow-portfolio-details .content-right .description p {
    margin-bottom: 10px;
}

.rainbow-portfolio-details .alignwide {
    margin-left: -100px;
    margin-right: -100px;
    max-width: 1400px;
    width: auto;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .rainbow-portfolio-details .alignwide {
        margin-left: 0;
        margin-right: 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .rainbow-portfolio-details .alignwide {
        margin-left: 0;
        margin-right: 0;
    }
}

@media only screen and (max-width: 767px) {
    .rainbow-portfolio-details .alignwide {
        margin-left: 0;
        margin-right: 0;
    }
}

/*--------------------------------
    Single Demo  
-------------------------------*/

.single-demo {
    text-align: center;
    background: var(--color-lessdark);
    border-radius: 10px;
    padding: 20px;
    transition: 0.3s;
    position: relative;
}

.single-demo a {
    display: block;
}

.single-demo .thumbnail {
    position: relative;
}

.single-demo .thumbnail img {
    border-radius: 10px;
    width: 100%;
}

.single-demo .thumbnail .image-light {
    display: none;
}

.single-demo .thumbnail .label-badge {
    position: absolute;
    right: 20px;
    border-radius: 5px;
    background-color: transparent;
    background: #fff700;
    padding: 0 12px;
    color: #000;
    font-size: 14px;
    height: 29px;
    line-height: 29px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 500;
    display: inline-block;
    width: 60px;
    bottom: 20px;
}

.single-demo .title {
    margin: 0;
    margin-top: 20px;
    font-size: 20px;
    letter-spacing: 0.5px;
    position: relative;
    display: inline-block;
}

.single-demo:hover {
    transform: translateY(-5px);
}

.react-image img {
    max-height: 150px;
}

/*-------------------------
    Darkmode Styles  
---------------------------*/

.rainbow-back-top i {
    transition: 0.3s;
}

.rainbow-back-top:hover i {
    color: var(--color-primary) !important;
}

body.active-light-mode {
    background-color: var(--color-white);
    color: #65676b;
}

body.active-light-mode h1,
body.active-light-mode h2,
body.active-light-mode h3,
body.active-light-mode h4,
body.active-light-mode h5,
body.active-light-mode h6,
body.active-light-mode .h1,
body.active-light-mode .h2,
body.active-light-mode .h3,
body.active-light-mode .h4,
body.active-light-mode .h5,
body.active-light-mode .h6,
body.active-light-mode .social-default li a,
body.active-light-mode .rbt-single-widget .title,
body.active-light-mode .rbt-single-widget.widget_recent_entries .inner a,
body.active-light-mode .tagcloud a,
body.active-light-mode .wp-block-tag-cloud a,
body.active-light-mode .rainbow-portfolio-details .content-left .single-list-wrapper .single-list label,
body.active-light-mode .blog-details-content .category-meta span.text,
body.active-light-mode .demo-slider-area.slider-style-1 .inner .title,
body.active-light-mode .rainbow-back-top svg,
body.active-light-mode .popup-mobile-menu .inner .header-top .close-menu .close-button,
body.active-light-mode .footer-style-default .footer-top .rainbow-footer-widget .title,
body.active-light-mode .rainbow-call-to-action.style-8 .title,
body.active-light-mode .rainbow-newsletter-default .newsletter-section-title .title,
body.active-light-mode .rainbow-default-tab .tab-button .tabs__tab .nav-link,
body.active-light-mode .rainbow-comment-form .section-title .title {
    color: #181818;
}

body.active-light-mode .slider-style-1.variation-3 .inner .title span,
body.active-light-mode .slider-style-1.variation-3 .inner .title {
    color: #181818 !important;
}

body.active-light-mode .rainbow-default-tab .tab-button .tabs__tab .nav-link.active,
body.active-light-mode .slider-style-3 .thumbnail::after {
    background: #f5f8fa;
}

body.active-light-mode .slider-style-8 .inner a.btn-default.btn-border,
body.active-light-mode .slider-style-8 .inner button.btn-default.btn-border {
    border-color: #d8d8d8;
    color: #65676b;
}

body.active-light-mode .rainbow-slick-arrow button.slick-arrow {
    border: 2px solid #d8d8d8;
    color: #65676b;
}

body.active-light-mode .rainbow-slick-arrow.testimonial-activation button.slick-arrow.prev-arrow::before,
body.active-light-mode .rainbow-slick-arrow.testimonial-activation button.slick-arrow.next-arrow::before {
    filter: brightness(0);
}

body.active-light-mode .rainbow-slick-arrow button.slick-arrow:hover {
    border-color: transparent !important;
}

body.active-light-mode .rainbow-slick-arrow.testimonial-activation button.slick-arrow.prev-arrow:hover::before,
body.active-light-mode .rainbow-slick-arrow.testimonial-activation button.slick-arrow.next-arrow:hover::before {
    filter: brightness(1);
}

body.active-light-mode .slider-style-8 .inner a.btn-default.btn-border:hover,
body.active-light-mode .slider-style-8 .inner button.btn-default.btn-border:hover {
    color: #fff;
    border-color: transparent;
}

body.active-light-mode .slider-style-1.banner-company .inner .list-icon li .icon {
    background-color: #f5f8fa !important;
}

body.active-light-mode .single-demo .thumbnail .image-light {
    display: block;
}

body.active-light-mode .single-demo .thumbnail .image-dark {
    display: none;
}

body.active-light-mode .mainmenu-nav .mainmenu li.has-droupdown .submenu li a.active {
    background: #d8d8d8;
}

body.active-light-mode .mainmenu-nav .mainmenu li.with-megamenu .rainbow-megamenu .wrapper .mega-menu-item li a:hover,
body.active-light-mode .mainmenu-nav .mainmenu li.with-megamenu .rainbow-megamenu .wrapper .mega-menu-item li a.active {
    background: #d8d8d8;
}

body.active-light-mode p,
body.active-light-mode input[type=text],
body.active-light-mode input[type=password],
body.active-light-mode input[type=email],
body.active-light-mode input[type=number],
body.active-light-mode input[type=tel],
body.active-light-mode textarea,
body.active-light-mode .breadcrumb-inner ul.page-list li a,
body.active-light-mode .rainbow-address .inner p a,
body.active-light-mode .error-inner .description,
body.active-light-mode .rbt-single-widget.widget_recent_comments ul li a,
body.active-light-mode .rbt-single-widget.widget_recent_entries ul li a,
body.active-light-mode .rbt-single-widget.widget_archive ul li a,
body.active-light-mode .rbt-single-widget.widget_categories ul li a,
body.active-light-mode .rbt-single-widget.widget_meta ul li a,
body.active-light-mode .rbt-single-widget.widget_pages ul li a,
body.active-light-mode .rbt-single-widget.widget_nav_menu ul li a,
body.active-light-mode .rbt-single-widget.widget_recent_entries .inner span,
body.active-light-mode .rbt-single-widget.widget_recent_comments ul li span,
body.active-light-mode .rbt-single-widget.widget_recent_entries ul li span,
body.active-light-mode .rbt-single-widget.widget_archive ul li span,
body.active-light-mode .rbt-single-widget.widget_categories ul li span,
body.active-light-mode .rbt-single-widget.widget_meta ul li span,
body.active-light-mode .rbt-single-widget.widget_pages ul li span,
body.active-light-mode .rbt-single-widget.widget_nav_menu ul li span,
body.active-light-mode .post-page-banner .rainbow-meta-list li a,
body.active-light-mode .post-page-banner .rainbow-meta-list li,
body.active-light-mode .breadcrumb-inner ul.page-list li.rainbow-breadcrumb-item.active,
body.active-light-mode .slider-style-1.bg-transparent .inner .subtitle,
body.active-light-mode .slider-style-1.bg-transparent .inner .description,
body.active-light-mode .popup-mobile-menu .mainmenu li a,
body.active-light-mode .footer-style-default .footer-top .rainbow-footer-widget .footer-link li a,
body.active-light-mode .footer-style-default .footer-top .rainbow-footer-widget .subtitle,
body.active-light-mode .rainbow-call-to-action.style-8 .content .subtitle,
body.active-light-mode .rainbow-newsletter-default .newsletter-section-title .description,
body.active-light-mode .slider-style-5 .inner .subtitle,
body.active-light-mode .slider-style-5 .inner .title,
body.active-light-mode .single-progress .title,
body.active-light-mode .radial-progress .circle-text span.count,
body.active-light-mode .radial-progress .circle-text span.count::after,
body.active-light-mode .radial-progress-single .circle-info .subtitle,
body.active-light-mode .copyright-style-one .copyright-text a {
    color: #65676b;
}

body.active-light-mode .header-transparent.sticky .mainmenu-nav .mainmenu>li>a,
body.active-light-mode .clltoaction-style-default.style-7 .content .subtitle,
body.active-light-mode .copyright-style-one .ft-menu li a,
body.active-light-mode .footer-style-default .footer-top .rainbow-footer-widget .text-big {
    color: #65676b;
}

body.active-light-mode .form-group input,
body.active-light-mode .form-group textarea,
body.active-light-mode .rainbow-accordion-style.rainbow-accordion-02 .card .card-header,
body.active-light-mode .mainmenu-nav .mainmenu li.with-megamenu .rainbow-megamenu .wrapper .mega-menu-item,
body.active-light-mode .breadcarumb-style-1,
body.active-light-mode .rainbow-accordion-style.rainbow-accordion-03 .card .card-header,
body.active-light-mode .rainbow-accordion-style.rainbow-accordion-04 .card,
body.active-light-mode .rainbow-pricing .pricing-table-inner,
body.active-light-mode input[type=text],
body.active-light-mode input[type=password],
body.active-light-mode input[type=email],
body.active-light-mode input[type=number],
body.active-light-mode input[type=tel],
body.active-light-mode textarea,
body.active-light-mode .rbt-single-widget,
body.active-light-mode .rbt-single-widget.widget_recent_comments ul li+li,
body.active-light-mode .rbt-single-widget.widget_recent_entries ul li+li,
body.active-light-mode .rbt-single-widget.widget_archive ul li+li,
body.active-light-mode .rbt-single-widget.widget_categories ul li+li,
body.active-light-mode .rbt-single-widget.widget_meta ul li+li,
body.active-light-mode .rbt-single-widget.widget_pages ul li+li,
body.active-light-mode .rbt-single-widget.widget_nav_menu ul li+li,
body.active-light-mode .tagcloud a,
body.active-light-mode .wp-block-tag-cloud a,
body.active-light-mode .section-title-2 .title,
body.active-light-mode .popup-mobile-menu .mainmenu li+li,
body.active-light-mode .clltoaction-style-default.style-7 .content-wrapper,
body.active-light-mode .copyright-style-one,
body.active-light-mode .footer-style-default .newsletter-form .form-group input,
body.active-light-mode .newsletter-area .border-top-bottom,
body.active-light-mode .rainbow-comment-form .inner .rnform-group textarea,
body.active-light-mode .rainbow-portfolio-details .content-left .single-list-wrapper .single-list+.single-list {
    border-color: #00000024;
}

body.active-light-mode .service.service__style--1.with-working-process .line {
    background-color: #00000024;
    width: 83%;
}

body.active-light-mode .mainmenu-nav .mainmenu li.has-droupdown .submenu li a:hover {
    background: #d8d8d8;
}

body.active-light-mode .mainmenu-nav .mainmenu li.with-megamenu .rainbow-megamenu .wrapper .mega-menu-item li a:hover {
    background: #d8d8d8;
    color: var(--color-primary);
}

body.active-light-mode .btn-read-more span::after {
    background: #00000024;
}

body.active-light-mode .breadcrumb-inner ul.page-list li a:hover,
body.active-light-mode .rainbow-address .inner p a:hover,
body.active-light-mode .post-page-banner .rainbow-meta-list li a:hover,
body.active-light-mode .header-top-bar .header-left p a:hover {
    color: var(--color-primary);
}

body.active-light-mode .social-default li a:hover,
body.active-light-mode .tagcloud a:hover,
body.active-light-mode .wp-block-tag-cloud a:hover,
body.active-light-mode .header-top-news .btn-read-more span {
    color: var(--color-white);
}

body.active-light-mode .tagcloud a:hover,
body.active-light-mode .wp-block-tag-cloud a:hover {
    border-color: var(--color-primary);
}

body.active-light-mode a.btn-default.text-underline,
body.active-light-mode button.btn-default.text-underline {
    border-color: #181818;
    color: #181818;
}

body.active-light-mode .single-demo .thumbnail .image-active-light-mode {
    display: block;
}

body.active-light-mode .single-demo .thumbnail .image-dark {
    display: none;
}

body.active-light-mode .footer-style-default .social-default li a,
body.active-light-mode .copyright-style-one .social-default.color-lessdark li a,
body.active-light-mode .rainbow-newsletter-default .rainbow-newsletter .form-group input {
    background: #fff;
}

body.active-light-mode .copyright-style-one .ft-menu li+li::after {
    background: #c5c1c1;
}

body.active-light-mode .copyright-style-one .ft-menu li a:hover,
body.active-light-mode .footer-style-default .footer-top .rainbow-footer-widget .footer-link li a:hover {
    color: var(--color-primary);
}

body.active-light-mode .header-transparent-with-topbar .header-top-bar,
body.active-light-mode .header-transparent-with-topbar .header-default {
    background: transparent;
}

body.active-light-mode [class*=col]:nth-child(2) .counter-style-2.color-variation .count-number {
    background-color: #f9f5f5;
}

body.active-light-mode [class*=col]:nth-child(3) .counter-style-2.color-variation .count-number {
    background-color: #eef2f3;
}

body.active-light-mode .service-wrapper>[class*=col]:nth-child(4) .service.service__style--1.icon-circle-style .icon {
    background-color: #eb008b24;
}

body.active-light-mode .logo a img.logo-light {
    display: none;
}

body.active-light-mode .rainbow-back-top i {
    color: #333;
}

body.active-light-mode .service-wrapper>[class*=col]:nth-child(5) .service.service__style--1.icon-circle-style .icon {
    background-color: #009aff29;
}

body.active-light-mode .service-wrapper>[class*=col]:nth-child(6) .service.service__style--1.icon-circle-style .icon {
    background-color: #3000ff29;
}

body.active-light-mode .bg-lessdark-gradient {
    background: #f5f8fa;
}

body.active-light-mode .header-top-news-2 .btn-read-more span {
    color: var(--color-white);
}

body.active-light-mode .preview-accordion-area .service .content .liststyle span {
    color: #333;
}

body.active-light-mode .envato-white {
    display: none;
}

body.active-light-mode .envato-black {
    display: block;
    margin-left: auto;
    text-align: center;
    margin-right: auto;
}

body.active-light-mode .service.service__style--1.icon-circle-style .icon {
    background-color: #059dff0f;
}

body.active-light-mode .service-wrapper>[class*=col]:nth-child(2) .service.service__style--1.icon-circle-style .icon {
    background-color: #fb53431a;
}

body.active-light-mode .service-wrapper>[class*=col]:nth-child(3) .service.service__style--1.icon-circle-style .icon {
    background-color: #6549d51f;
}

body.active-light-mode .timeline-dot::after {
    background: radial-gradient(50% 50% at 50% 50%, #f5f8fa 48.96%, #f5f8fa 100%);
}

body.active-light-mode .header-top-bar {
    background-color: var(--color-white);
}

body.active-light-mode .single-timeline .single-content,
body.active-light-mode .rainbow-card,
body.active-light-mode .clltoaction-style-default.style-5 .content-wrapper,
body.active-light-mode .header-default,
body.active-light-mode .mainmenu-nav .mainmenu li.has-droupdown .submenu,
body.active-light-mode .service.gallery-style,
body.active-light-mode .rainbow-box-card .inner,
body.active-light-mode .team-style-default .inner,
body.active-light-mode .rainbow-accordion-style .card,
body.active-light-mode .section-title .subtitle,
body.active-light-mode .card-box.card-style-1,
body.active-light-mode .list-icon li .icon,
body.active-light-mode .counter-style-2 .count-number,
body.active-light-mode .rainbow-pricing.style-2 .pricing-table-inner,
body.active-light-mode .rainbow-default-tab .rainbow-tab-content,
body.active-light-mode .rainbow-default-tab .tab-button .react-tabs__tab.react-tabs__tab--selected,
body.active-light-mode .advance-tab-button-1 .tab-button-list .tab-button::before,
body.active-light-mode .advance-pricing .pricing-left,
body.active-light-mode .rainbow-pricing.style-5 .pricing-table-inner,
body.active-light-mode .about-style-5 .about-wrapper,
body.active-light-mode .rainbow-sub-badge,
body.active-light-mode .about-style-6 .wrapper::after,
body.active-light-mode .box-grid-layout .rainbow-card,
body.active-light-mode .mainmenu-nav .mainmenu li.with-megamenu .rainbow-megamenu .wrapper,
body.active-light-mode .social-default li a,
body.active-light-mode .clltoaction-style-default.style-4 .content-wrapper,
body.active-light-mode .rainbow-address,
body.active-light-mode .rbt-single-widget .title,
body.active-light-mode .frame-image,
body.active-light-mode .rainbow-back-top,
body.active-light-mode .popup-mobile-menu .inner,
body.active-light-mode .rainbow-call-to-action.style-8,
body.active-light-mode .bg-overlay-solid::before {
    background: #f5f8fa;
}

body.active-light-mode .rainbow-header.sticky {
    background: #f5f8fa !important;
}

body.active-light-mode .bg-color-blackest {
    background: #f5f8fa !important;
}

body.active-light-mode .footer-style-default,
body.active-light-mode .copyright-style-one {
    background: #f5f8fa;
}

body.active-light-mode .slider-style-2 .list-icon li .icon {
    background: var(--color-blackest);
}

body.active-light-mode .rainbow-pricing .pricing-table-inner::before,
body.active-light-mode .rainbow-timeline-single.dark-line .rainbow-timeline .progress-dot .dot-level,
body.active-light-mode .rainbow-timeline-single.dark-line .rainbow-timeline .progress-line .line-inner,
body.active-light-mode .card-style-default.testimonial-style-one .inner .thumbnail::after,
body.active-light-mode .advance-pricing,
body.active-light-mode .rainbow-pricing.style-5 .pricing-table-inner .pricing,
body.active-light-mode .popup-mobile-menu .inner .header-top .close-menu .close-button,
body.active-light-mode .hamberger-button {
    background: #d8d8d8;
}

body.active-light-mode .single-demo {
    background: #e9f8fe;
}

body.active-light-mode .rainbow-accordion-style .card .card-body,
body.active-light-mode .brand-style-1 li {
    border-color: #d8d8d8;
}

body.active-light-mode hr {
    border-top: 1px solid #00000024;
}

body.active-light-mode .rainbow-card {
    border-color: #f5f8fa;
}

body.active-light-mode .rainbow-pricing.active .pricing-table-inner .pricing-header,
body.active-light-mode .rainbow-pricing .pricing-table-inner .pricing-header {
    border-color: #0000000a;
}

body.active-light-mode .advance-tab-button-1 .tab-button-list .tab-button::after {
    border-left: 20px solid #f5f8fa;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
}

body.active-light-mode a.btn-default.btn-border,
body.active-light-mode button.btn-default.btn-border {
    border-color: #00000024;
    color: #181818;
}

body.active-light-mode a.btn-default.btn-border:hover,
body.active-light-mode button.btn-default.btn-border:hover {
    border-color: var(--color-secondary);
    color: #fff;
}

body.active-light-mode .rainbow-box-card .inner .thumbnail,
body.active-light-mode .team-style-default .inner .thumbnail {
    border: 6px solid var(--color-white);
}

body.active-light-mode .filter-button-default button,
body.active-light-mode .testimonial-style-two .content .client-info .subtitle,
body.active-light-mode .single-timeline .single-content .inner .content .date-of-timeline,
body.active-light-mode .service.gallery-style .content p,
body.active-light-mode .rainbow-box-card .inner .social-icon li a,
body.active-light-mode .team-style-default .inner .social-icon li a,
body.active-light-mode .rainbow-accordion-style .card .card-body,
body.active-light-mode .mainmenu-nav .mainmenu>li>a,
body.active-light-mode .mainmenu-nav .mainmenu li.has-droupdown .submenu li a,
body.active-light-mode .list-icon li,
body.active-light-mode ul li,
body.active-light-mode .rainbow-pricing.active .pricing-table-inner .pricing-body ul.list-style--1 li,
body.active-light-mode .rainbow-pricing.active .pricing-table-inner .pricing-body ul.list-style--1 li svg,
body.active-light-mode .advance-tab-button-1 .tab-button-list .tab-button .title,
body.active-light-mode .advance-pricing .pricing-left .subtitle,
body.active-light-mode .header-top-bar .header-left p a,
body.active-light-mode .header-top-bar .header-right .address-content p a,
body.active-light-mode .header-top-bar .social-default.icon-naked li a,
body.active-light-mode .progress-info .title,
body.active-light-mode .progress-info span.progress-number,
body.active-light-mode .mainmenu-nav .mainmenu li.with-megamenu .rainbow-megamenu .wrapper .mega-menu-item li a,
body.active-light-mode .clltoaction-style-default.style-4 .content .subtitle {
    color: #65676b;
}

body.active-light-mode .card-box.card-style-1 .inner .content .description {
    color: #65676b !important;
}

body.active-light-mode .clltoaction-style-default.style-5 .content .subtitle,
body.active-light-mode .rainbow-accordion-style .card .card-header button,
body.active-light-mode .counter-style-2 .count-number,
body.active-light-mode .rainbow-pricing .pricing-table-inner .pricing-header .pricing span.price,
body.active-light-mode .rainbow-pricing.active .pricing-table-inner .pricing-header .title,
body.active-light-mode .rainbow-pricing.active .pricing-table-inner .pricing-header .pricing span.currency,
body.active-light-mode .rainbow-pricing.active .pricing-table-inner .pricing-header .pricing span.subtitle,
body.active-light-mode .rainbow-pricing .pricing-table-inner .pricing-header .pricing span.subtitle,
body.active-light-mode .rainbow-default-tab .tab-button .rainbow-tab-button button,
body.active-light-mode .rainbow-pricing .pricing-table-inner .pricing-header .pricing span.currency,
body.active-light-mode .card-style-default.testimonial-style-one .inner .thumbnail::after,
body.active-light-mode .clltoaction-style-default.style-6 .content .title,
body.active-light-mode .rainbow-splite-style .split-inner .title,
body.active-light-mode .advance-tab-button-1 .tab-button-list .react-tabs__tab--selected .tab-button .title,
body.active-light-mode .advance-pricing .pricing-left .price-wrapper .price-amount,
body.active-light-mode .btn-read-more span,
body.active-light-mode .section-title-2 .title,
body.active-light-mode .box-grid-layout .rainbow-card .inner .content .title,
body.active-light-mode .clltoaction-style-default.style-5 .content .title,
body.active-light-mode .clltoaction-style-default.style-4 .content .title,
body.active-light-mode .hamberger-button {
    color: #181818;
}

body.active-light-mode .about-style-6 .maintitle {
    color: #181818 !important;
}

body.active-light-mode .slider-style-1 .inner .title {
    color: var(--color-white);
}

body.active-light-mode .testimonial-style-two .content::after {
    color: #f5f8fa;
}

body.active-light-mode .header-transparent .mainmenu-nav .mainmenu>li>a {
    color: var(--color-body);
}

body.active-light-mode .header-top-bar .header-right .address-content p a:hover {
    color: var(--color-primary);
}

body.active-light-mode .rainbow-pricing .pricing-table-inner .pricing-header .pricing span.currency {
    opacity: 0.8;
}

body.active-light-mode .mainmenu-nav .mainmenu li.has-droupdown .submenu li a:hover,
body.active-light-mode .btn-read-more:hover span {
    color: var(--color-primary);
}

body.active-light-mode .CircularProgressbar .CircularProgressbar-text {
    fill: #181818 !important;
}

body.active-light-mode .logo-active-light-mode {
    display: none;
}

body.active-light-mode .logo-dark {
    display: block;
}

body.active-light-mode .slider-area.bg-transparent .inner a.btn-default.btn-border,
body.active-light-mode .slider-area.bg-transparent .inner button.btn-default.btn-border {
    border-color: #00000024;
    color: #181818;
}

body.active-light-mode .slider-area.bg-transparent .inner a.btn-default.btn-border:hover,
body.active-light-mode .slider-area.bg-transparent .inner button.btn-default.btn-border:hover {
    color: var(--color-white);
}

/* Advance Tab Style Four  */

.advance-tab-four {
    position: relative;
    padding: 48px;
    grid-column-gap: 48px;
    grid-row-gap: 48px;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    border: 1px solid var(--color-border);
    border-radius: 12px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .advance-tab-four {
        padding: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .advance-tab-four {
        padding: 20px;
    }
}

.advance-tab-four .advance-tab-top .description {
    font-size: var(--font-size-b3);
}

.rn-info-box {
    margin-top: 20px;
    background-color: var(--color-extra06);
    padding: 40px;
    border: 1px solid var(--color-border);
    border-radius: 12px;
}

.rn-info-box .box-inner .content {
    margin-top: 10px;
}

.service-thumbnail-offset-2 {
    margin-right: -60px;
    margin-bottom: -60px;
    border-radius: 10px 0 10px 0;
}

.inner.Collaborate-inner {
    padding: 0 50px 0 30px;
}

@media only screen and (max-width: 767px) {
    .inner.Collaborate-inner {
        padding: 30px 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .inner.Collaborate-inner .description {
        font-size: 14px;
    }
}

.social-icons {
    display: flex;
    gap: 20px;
}

.social-icons i {
    font-size: 20px;
}





.carousel-container {
    position: relative;
}

.carousel-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 24px;
    color: #333;
    cursor: pointer;
    z-index: 2;
}

.prev {
    left: 30px;
}

.next {
    right: 30px;
}

.container>.row>.foot_logo>img .Company-logo {
    width: 300px !important;
}


.footer-in-payment-method {
    width: 350px !important;
    margin-bottom: 5px;
    display: block;
    padding: 20px;
    border-radius: 10px !important;
    border: 1px solid;
    border-color: #e35cbd80 #e35cbd80 #817ce78f #817ce78f;
    border-image-slice: 1;
}


.payment-method {
    position: relative;
}


@media (max-width: 430px) {
    .foot_logo {
        display: flex;
        justify-content: center;
    }

    .footer-in-payment-method {
        width: 250px;
        /* margin-bottom: 30px; */
    }

    .overlay-text {
        top: 0;
        font-size: 17px;
        width: 120px;
    }


}


.overlay-text {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    height: 30px;
    font-size: 13px;
    font-weight: 800;
    width: 75px;
    position: absolute;
    top: -15px;
    /* background-color: purple; */
    /* Adjust as needed to position text above the border */
    left: 50%;
    transform: translateX(-50%);
    background-color: #181818;
    /* Background color for text */
    /* padding: 0 5px; */
    /* Add padding to the text */
    z-index: 9999;
    color: white;
    /* Add border to the text */
}


.image-container {
    position: relative;
    display: inline-block;
}



.footer-contact-number:hover,
.footer-in-Pages:hover {
    background: linear-gradient(-90deg, blue, rgb(247, 29, 163));
    color: transparent;
    -webkit-background-clip: text;
    transition: all 150ms ease-in-out;
}

.landing-logo img {
    margin: auto;
    width: 150px;
    height: 14vh;
    padding-top: 10px;
}


@media (max-width:768px) {
    .landing-logo img {
        margin: auto;
        width: 200px;
        height: 13vh;
    }
}

.required-star::after {
    content: '*';
    color: red;
    /* or any color you prefer */
    margin-left: 1px;
    /* adjust as needed */
}

.card-input label {
    font-weight: 500;
}